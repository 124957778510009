import React from "react";
import "../original/assets/css/oneui.css";
import "../original/assets/css/style.css";
import "../original/assets/css/bootstrap.min.css";
import * as config from "../constants/resource";
import { authHeader } from "../_helpers";
import { CopyrightRaw } from "../Menu/footer";
import i18next from "i18next";
import EvEmitter from "../_helpers/EvEmitter";
import { Convert } from "./GoogleTranslate";
import Script from "../_helpers/Script";

let start = 0;

class Public extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeout: false,
      run: false,
      steps: window.dataOnboarding,
      You_need_to_connect_a_calendar_to_get_started: i18next.t(
        "Pages.menu.You_need_to_connect_a_calendar_to_get_started"
      ),
      Connect_Calendar: i18next.t("Pages.sideMenu.Connect_Calendar"),
      mode: localStorage.orientation === "m" ? "other" : "yearly",
      currentView: "Month",
      open: false,
    };
    this.changeCurrentView = this.changeCurrentView.bind(this);
    this.clickGetData = this.clickGetData.bind(this);
  }

  componentDidMount() {
    if (window.dataOnboarding !== []) {
      this.setState({ run: true });
    }
    this.getPublicCalendarData();
  }

  translating = async (dataText) => {
    if (start < dataText.length) {
      Convert(dataText[start].content).then((data) => {
        dataText[start]["content"] = data;
        if (start + 1 !== dataText.length) {
          start = start + 1;
          this.translating(dataText);
        } else {
          start = 0;
          var x = document.getElementById("tutorial-loader");
          x.style.display = "none";
          this.setState({ steps: dataText, run: true });
        }
      });
    }
  };

  replyTutorial = async () => {
    this.setState({ run: false });
    let user_data = JSON.parse(localStorage.getItem("user_data")) || [];

    const requestOptions2 = {
      method: "GET",
      headers: authHeader(),
    };
    return fetch(
      `${config.URL}/items/onboarding?filter[status][eq]=published&[order][neq]=0&sort=order`,
      requestOptions2
    )
      .then(function (response) {
        return response.json();
      })
      .then((dataOnboard) => {
        if (dataOnboard.data.length > 0) {
          window.firstOnboard = 0;
          dataOnboard.data[0].disableBeacon = true;
          dataOnboard.data.map((inside, index) => {
            if (inside.target === ".day-today") {
              if (localStorage.getItem("orientation") === "h") {
                dataOnboard.data[index].target = ".horizontal-today";
              } else if (localStorage.getItem("orientation") === "v") {
                dataOnboard.data[index].target = ".vertical-today";
                dataOnboard.data[index].disableScrolling = true;
              }
            }
            return null;
          });
          if (user_data.lang === "en") {
            this.setState({ steps: dataOnboard.data, run: true });
          } else {
            this.translating(dataOnboard.data);
          }
        }
      });
  };

  getPublicCalendarData = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const public_calendar_key = urlParams.get("public_calendar_key");

    if (public_calendar_key) {
      const options = {
        method: "GET",
      };

      await fetch(
        `${config.URL}/custom/public_calendar?public_calendar_key=${public_calendar_key}`,
        options
      )
        .then((res) => res.json())
        .then(async (res) => {
          if (res.data) {
            var title = res.data.title;
            var options = res.data.options;
            var calendars = res.data.calendars;
            var options = res.data.options;
            var eventList = [];

            localStorage.setItem("title", title);
            localStorage.setItem("options", JSON.stringify(options));
            localStorage.setItem("options", JSON.stringify(options));
            localStorage.setItem("calendars", JSON.stringify(calendars));
            localStorage.setItem("today_color", options.today_color);
            localStorage.setItem("work_day_color", options.work_day_color);
            localStorage.setItem("off_day_color", options.off_day_color);
            localStorage.setItem("year", options.year);
            localStorage.setItem(
              "weekday_header_color",
              options.weekday_header_color
            );
            localStorage.setItem(
              "week_no_header_color",
              options.week_no_header_color
            );
            localStorage.setItem(
              "month_header_color",
              options.month_header_color
            );
            localStorage.setItem("wn", options.wn);
            localStorage.setItem("monday", options.monday);
            localStorage.setItem("tuesday", options.tuesday);
            localStorage.setItem("wednesday", options.wednesday);
            localStorage.setItem("thursday", options.thursday);
            localStorage.setItem("friday", options.friday);
            localStorage.setItem("saturday", options.saturday);
            localStorage.setItem("sunday", options.sunday);
            localStorage.setItem("font_size_mnd", options.font_size_mnd);
            localStorage.setItem("start_month", options.start_month);
            localStorage.setItem("ht", options.ht);
            localStorage.setItem("i18nextLng", "en");
            localStorage.setItem("abd_flag", options.abd_flag);
            localStorage.setItem("bw", options.bw);
            localStorage.setItem("soade", options.soade);
            localStorage.setItem("ooo", options.ooo);
            localStorage.setItem("orientation", options.orientation);
            localStorage.setItem("start_day", options.start_day);
            localStorage.setItem("hdn", options.hdn);
            localStorage.setItem("header_size", options.header_size);

            // var options = res.data.options;
            var cachedEvents = { 2022: {} };

            window.cachedEvents = cachedEvents;
            window.calColors = [
              "#16a765",
              "#16a765",
              "#fa573c",
              "#92e1c0",
              "#9fe1e7",
            ];
            window.createHorizontalTableDisplay();
            window.horizontalView();
          }
        });
    }
  };

  refreshPage = () => {
    EvEmitter.emit("OtherRefresh", { value: false });
    window.refreshCalendarPage();
  };

  changeCurrentView = (props) => {
    this.setState({ currentView: props });
    EvEmitter.emit("changeCurrentView", { value: props });
  };

  clickGetData = () => {
    window.location.reload();
  };

  render() {
    const copyToClipboard = () => {
      navigator.clipboard.writeText(window.location.href).then(
        function () {
          alert("copied successfully!");
        },
        function (err) {
          alert("Failed to copy");
        }
      );
    };

    return (
      <div>
        <div className="yg-title" id="planner-title-public">
          <span className="page-heading" id="year-planner-title">
            {localStorage.title}
          </span>
          &nbsp;
        </div>
        <br />
        <button onClick={copyToClipboard}> Copy Current URL </button>
        <p>
          <b>
            *. wait until prompt "<i>success</i>" appear{" "}
          </b>
        </p>
        <div className="year-spinner" id="year-and-spinner">
          <button id="buttonForPublic" onClick={() => this.clickGetData()}>
            Get New Data
          </button>
        </div>

        <div></div>
        <div
          className="yg-horizontal-public"
          id="horizontalDisplay"
          style={{ display: "block" }}
        ></div>
        <CopyrightRaw />
        <Script
          type="text/javascript"
          src={
            "assets/" +
            process.env.REACT_APP_JS_DIR +
            "/yearplanner-for-public.js?v=" +
            process.env.REACT_APP_GIT_HASH
          }
          async={false}
        />
      </div>
    );
  }
}
export { Public };
