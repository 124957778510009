/*
 * changes made :
 * import change - from material-ui to mui
 */
import React from "react";
import { save_log } from '../../constants/saveLog';
import { loadStripe } from "@stripe/stripe-js";
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea, Typography, Grid } from '@mui/material';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
});

export function CheckoutOptions(props) {
  const classes = useStyles();
  save_log(6);
  const stripePromise = loadStripe(props.publish_key);
  // const stripePromise = loadStripe('pk_test_DLZeFvP0xGYByDste1JtHlvj00vBvZi5x6');

  const StripeClickPay = async (event,type) => {
    // When the customer clicks on the button, redirect them to Checkout.
    let data = JSON.parse(localStorage.getItem('yearglance')).data || [];
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        // Replace with the ID of your price
        // {price: 'price_1H1mArA4dQ2nVKtJWdiOTkM3', quantity: 1}
        {price: event, quantity: 1}
      ],
      mode: type,
      successUrl: process.env.REACT_APP_BASE_URL,
      cancelUrl: process.env.REACT_APP_BASE_URL,
      customerEmail: data.user.email
    });
    console.log(error);
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
  };

  return (
    <Grid container spacing={3}>
        {props.packageData.data.map((payData,index)=>{
          return(
            <Grid item xs={6} key={index}>
              <Card className={classes.root}>
                <CardActionArea onClick={()=>StripeClickPay(payData.stripe_price_id,payData.length===0?'payment':'subscription')}>
                  <CardContent>
                    <Grid container spacing={0}>
                      <Grid item xs={4}>
                        <img src="../assets/img/StripePayment.png" style={{ height: 80 }} alt="StripePayment"/>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography gutterBottom variant="h4">
                          {payData.name} / ${payData.price}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography variant="h5" color="textSecondary">
                      <div dangerouslySetInnerHTML={{ __html: payData.description }} />
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )
        })}
    </Grid>
  );
}

export default CheckoutOptions;