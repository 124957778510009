import { userConstants } from "../_constants";
import { userService } from "../_services";
import { alertActions } from "./";
import { loadingActions } from "./";
import { history } from "../_helpers";
import * as config from "../constants/resource";
import { save_log } from "../constants/saveLog";
import moment from "moment";
export const userActions = {
  login,
  loginAndPay,
  logout,
  register,
  registerWithVoucher,
  registerAndPay,
  registerGoogle,
  confirmVerification,
  forgotPassword,
  resetPassword,
  generatePassword,
  getAll,
  delete: _delete,
  autoLogin,
  loginGoogle,
  googleLoginAndPay,
  resendToken,
  checkUserActive,
  refreshToken,
  broadcast,
};

let currentURL = window.location.href;
let isRunningLocally = false;

if(currentURL.includes("localhost")) {
  isRunningLocally = true;
}

function login(email, password) {
  return (dispatch) => {
    dispatch(request({ email }));

    userService.login(email, password).then(
      (user) => {
        userService.loginCollection(user).then(
          (result) => {
            if (
              result.data.length === 0 &&
              user.data.user.status === "active"
            ) {
              userService.changePermission("enabled").then(
                (userData) => {
                  userService.HelpingCreateAccount(user).then(
                    (result) => {
                      userService.changeLastLogin().then(
                        (result) => {
                          dispatch(loadingActions.clearLoading());
                          dispatch(success(user));
                          save_log(1);
                          localStorage.setItem("acc_time", moment());
                          dispatch(alertActions.success("Login successful"));
                          var profile_filled = JSON.parse(
                            window.localStorage.user_data
                          ).profile_filled;
                          if (profile_filled === true) {
                            history.push("/");
                          } else {
                            history.push("/profile");
                          }
                        },
                        (error) => {
                          dispatch(loadingActions.clearLoading());
                          dispatch(failure(error.toString()));
                          dispatch(alertActions.error(error.toString()));
                        }
                      );
                    },
                    (error) => {
                      dispatch(loadingActions.clearLoading());
                      dispatch(failure(error.toString()));
                      dispatch(alertActions.error(error.toString()));
                    }
                  );
                },
                (error) => {
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.error(error.toString()));
                  dispatch(loadingActions.clearLoading());
                }
              );
            } else {
              if (result.data[0].subscription_expiry) {
                userService.checkExpiredLeft(result).then(
                  (result) => {
                    userService.changeLastLogin().then(
                      (result) => {
                        dispatch(loadingActions.clearLoading());
                        dispatch(success(user));
                        save_log(1);
                        localStorage.setItem("acc_time", moment());
                        dispatch(alertActions.success("Login successful"));
                        var profile_filled = JSON.parse(
                          window.localStorage.user_data
                        ).profile_filled;
                        if (profile_filled === true) {
                          history.push("/");
                        } else {
                          history.push("/profile");
                        }
                      },
                      (error) => {
                        dispatch(loadingActions.clearLoading());
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                      }
                    );
                  },
                  (error) => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    dispatch(loadingActions.clearLoading());
                  }
                );
              } else {
                userService.CollectionData(result.data[0]).then(
                  (next) => {
                    userService.changeLastLogin().then(
                      (resultData) => {
                        dispatch(loadingActions.clearLoading());
                        dispatch(success(""));
                        dispatch(alertActions.success("Login successful"));
                        var profile_filled = JSON.parse(
                          window.localStorage.user_data
                        ).profile_filled;
                        if (profile_filled === true) {
                          history.push("/");
                        } else {
                          history.push("/profile");
                        }
                      },
                      (error) => {
                        dispatch(loadingActions.clearLoading());
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                      }
                    );
                  },
                  (error) => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    dispatch(loadingActions.clearLoading());
                  }
                );
              }
            }
          },
          (error) => {
            dispatch(loadingActions.clearLoading());
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
          }
        );
      },
      (error) => {
        dispatch(loadingActions.clearLoading());
        dispatch(failure(error.toString()));
        // dispatch(alertActions.error(error.toString()));
        dispatch(alertActions.error('Invalid username or password'));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function loginAndPay(email, password, id, idUrl, paymentMode) {
  return (dispatch) => {
    dispatch(request({ email }));

    userService.login(email, password).then(
      (user) => {
        dispatch(alertActions.success("Welcome to Year Glance! One Step Left"));
        window.setTimeout(() => {
          StripeClickPay(
            email,
            id,
            paymentMode,
            `${config.shortURL}/autologin?token=${user.data.token}`,
            `${config.shortURL}/login?id=${idUrl}`
          );
        }, 1000);
      },
      (error) => {
        dispatch(loadingActions.clearLoading());
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function googleLoginAndPay(email, token, id, idUrl, paymentMode) {
  return (dispatch) => {
    dispatch(request({ email }));
    dispatch(alertActions.success("Welcome to Year Glance! One Step Left"));
    window.setTimeout(() => {
      StripeClickPay(
        email,
        id,
        paymentMode,
        `${config.shortURL}/autologin?token=${token}`,
        `${config.shortURL}/login?id=${idUrl}`
      );
    }, 1000);
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function register(user) {
  return (dispatch) => {
    dispatch(request(user));
    userService.registerWithVoucher(user).then(
      (userData) => {
        userService.getToken(user.email, user.password).then(
          (userData) => {
            userService.changePermission("enabled").then(
              (userData) => {
                userService.setupUserSetting(user).then(
                  (userData) => {
                    userService.changePermission("disabled").then(
                      (userData) => {
                        userService.login(user.email, user.password).then(
                          (userData) => {
                            userService.loginCollection(userData).then(
                              (userData) => {
                                userService
                                  .CollectionData(userData.data[0])
                                  .then((next) => {
                                    userService.changeLastLogin().then(
                                      (userData) => {
                                        dispatch(success(userData));
                                        localStorage.setItem(
                                          "acc_time",
                                          moment()
                                        );
                                        dispatch(
                                          alertActions.success(
                                            "Login Successful"
                                          )
                                        );
                                        dispatch(loadingActions.clearLoading());
                                        // history.push('/');
                                        var profile_filled = JSON.parse(
                                          window.localStorage.user_data
                                        ).profile_filled;
                                        if (profile_filled === true) {
                                          history.push("/");
                                        } else {
                                          history.push("/profile");
                                        }
                                      },
                                      (error) => {
                                        dispatch(failure(error.toString()));
                                        dispatch(
                                          alertActions.error(error.toString())
                                        );
                                        dispatch(loadingActions.clearLoading());
                                      }
                                    );
                                  });
                              },
                              (error) => {
                                dispatch(failure(error.toString()));
                                dispatch(alertActions.error(error.toString()));
                                dispatch(loadingActions.clearLoading());
                              }
                            );
                          },
                          (error) => {
                            dispatch(failure(error.toString()));
                            dispatch(alertActions.error(error.toString()));
                            dispatch(loadingActions.clearLoading());
                          }
                        );
                      },
                      (error) => {
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                        dispatch(loadingActions.clearLoading());
                      }
                    );
                  },
                  (error) => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    dispatch(loadingActions.clearLoading());
                  }
                );
              },
              (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                dispatch(loadingActions.clearLoading());
              }
            );
          },
          (error) => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
            dispatch(loadingActions.clearLoading());
          }
        );
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
        dispatch(loadingActions.clearLoading());
      }
    );
  };
  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function registerWithVoucher(user, voucher) {
  return (dispatch) => {
    dispatch(request(user));
    userService.registerWithVoucher(user).then(
      (userData) => {
        userService.getToken(user.email, user.password).then(
          (userData) => {
            userService.changePermission("enabled").then(
              (userData) => {
                userService.registerCollectionWithVoucher(voucher, user).then(
                  (userData) => {
                    userService.changePermission("disabled").then(
                      (userData) => {
                        userService.login(user.email, user.password).then(
                          (userData) => {
                            userService.loginCollection(userData).then(
                              (userData) => {
                                userService
                                  .CollectionData(userData.data[0])
                                  .then((next) => {
                                    userService.changeLastLogin().then(
                                      (userData) => {
                                        dispatch(success(userData));
                                        localStorage.setItem(
                                          "acc_time",
                                          moment()
                                        );
                                        dispatch(
                                          alertActions.success(
                                            "Login Successful"
                                          )
                                        );
                                        dispatch(loadingActions.clearLoading());
                                        // history.push('/');
                                        var profile_filled = JSON.parse(
                                          window.localStorage.user_data
                                        ).profile_filled;
                                        if (profile_filled === true) {
                                          history.push("/");
                                        } else {
                                          history.push("/profile");
                                        }
                                      },
                                      (error) => {
                                        dispatch(failure(error.toString()));
                                        dispatch(
                                          alertActions.error(error.toString())
                                        );
                                        dispatch(loadingActions.clearLoading());
                                      }
                                    );
                                  });
                              },
                              (error) => {
                                dispatch(failure(error.toString()));
                                dispatch(alertActions.error(error.toString()));
                                dispatch(loadingActions.clearLoading());
                              }
                            );
                          },
                          (error) => {
                            dispatch(failure(error.toString()));
                            dispatch(alertActions.error(error.toString()));
                            dispatch(loadingActions.clearLoading());
                          }
                        );
                      },
                      (error) => {
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                        dispatch(loadingActions.clearLoading());
                      }
                    );
                  },
                  (error) => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    dispatch(loadingActions.clearLoading());
                  }
                );
              },
              (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                dispatch(loadingActions.clearLoading());
              }
            );
          },
          (error) => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
            dispatch(loadingActions.clearLoading());
          }
        );
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
        dispatch(loadingActions.clearLoading());
      }
    );
  };
  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

const StripeClickPay = async (
  email,
  event,
  paymentMode,
  successUrl,
  cancelUrl
) => {
  try {
    const successUrlEncoded = encodeURIComponent(successUrl);
    const cancelUrlEncoded = encodeURIComponent(cancelUrl);

    let baseUrl = '';

    if(isRunningLocally) {
      baseUrl = 'http://localhost:8080';
    }

    const requestOptions = {
      method: 'POST',
      redirect: 'follow'
    };

    await fetch(`${baseUrl}/plugin/checkout.php?idPrice=${event}&email=${email}&paymentMode=${paymentMode}&successUrl=${successUrlEncoded}&cancelUrl=${cancelUrlEncoded}`, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.checkout_url) {
          window.location.href = data.checkout_url;
        }
    });
  } catch (error) {
    console.log(error);
  }
};

function registerAndPay(user, id, idUrl, paymentMode) {
  return (dispatch) => {
    dispatch(request(user));
    userService.checkActive(user).then(
      (userData) => {
        if (userData === "not-exist") {
          dispatch(
            alertActions.success("Welcome to Year Glance! One Step Left")
          );
          window.setTimeout(() => {
            StripeClickPay(
              user.email,
              id,
              paymentMode,
              `${config.URL}/custom/accounts/register?returnUrl=${config.shortURL}/autologin&first=${user.first_name}&last=${user.last_name}&email=${user.email}&type=${idUrl}&indicator=${user.password}`,
              `${config.shortURL}/register?id=${idUrl}`
            );
          }, 1000);
        } else {
          dispatch(
            alertActions.error(
              `${user.email} already been sign up, please choose another email`
            )
          );
          dispatch(loadingActions.clearLoading());
        }
      },
      (error) => {
        dispatch(
          alertActions.error(
            `${user.email} already been sign up, please choose another email`
          )
        );
        dispatch(loadingActions.clearLoading());
      }
    );
  };
  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
}

function autoLogin(token) {
  return (dispatch) => {
    dispatch(request(token));
    // userService.loginToken(token);
    userService.loginToken(token).then((userData) => {
      userService.loginCollection(userData).then((userData) => {
        userService.CollectionData(userData.data[0]).then((next) => {
          userService.changeLastLogin().then((userData) => {
            dispatch(success(userData));
            save_log(13);
            localStorage.setItem("acc_time", moment());
            dispatch(alertActions.success("Welcome to Year Glance"));
            dispatch(loadingActions.clearLoading());

            history.push(`/`);
          });
        });
      });
    });
  };
  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
}

function loginGoogle(result) {
  return (dispatch) => {
    dispatch(request(result));

    userService.loginGoogle(result).then(
      (resultData) => {
        if (resultData.data[0].subscription_expiry) {
          userService.checkExpiredLeft(resultData).then(
            (result) => {
              userService.changeLastLogin().then(
                (resultData) => {
                  dispatch(loadingActions.clearLoading());
                  dispatch(success(""));
                  save_log(13);
                  localStorage.setItem("acc_time", moment());
                  dispatch(alertActions.success("Login successful"));

                  // history.push('/');
                  var profile_filled = JSON.parse(
                    window.localStorage.user_data
                  ).profile_filled;
                  if (profile_filled === true) {
                    history.push("/");
                  } else {
                    history.push("/profile");
                  }
                },
                (error) => {
                  dispatch(loadingActions.clearLoading());
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.error(error.toString()));
                }
              );
            },
            (error) => {
              dispatch(failure(error.toString()));
              dispatch(alertActions.error(error.toString()));
              dispatch(loadingActions.clearLoading());
            }
          );
        } else {
          userService.CollectionData(resultData.data[0]).then(
            (next) => {
              userService.changeLastLogin().then(
                (resultData) => {
                  dispatch(loadingActions.clearLoading());
                  dispatch(success(""));
                  save_log(13);
                  localStorage.setItem("acc_time", moment());
                  dispatch(alertActions.success("Login successful"));
                  var profile_filled = JSON.parse(
                    window.localStorage.user_data
                  ).profile_filled;
                  if (profile_filled === true) {
                    history.push("/");
                  } else {
                    history.push("/profile");
                  }
                  // history.push('/');
                },
                (error) => {
                  dispatch(loadingActions.clearLoading());
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.error(error.toString()));
                }
              );
            },
            (error) => {
              dispatch(failure(error.toString()));
              dispatch(alertActions.error(error.toString()));
              dispatch(loadingActions.clearLoading());
            }
          );
        }
      },
      (error) => {
        dispatch(loadingActions.clearLoading());
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(id) {
    return { type: userConstants.LOGIN_REQUEST, id };
  }
  function success(id) {
    return { type: userConstants.LOGIN_SUCCESS, id };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function registerGoogle(email) {
  return (dispatch) => {
    var password = generatePassword(6);

    dispatch(request(email));

    userService.registerGoogle(email, password).then(
      (userData) => {
        userService.getToken(email, password).then(
          (userData) => {
            userService.changePermission("enabled").then(
              (userData) => {
                userService.registerGoogleCollection().then(
                  (userData) => {
                    userService.changePermission("disabled").then(
                      (userData) => {
                        userService.login(email, password).then(
                          (userData) => {
                            userService.loginCollection(userData).then(
                              (userData) => {
                                userService
                                  .CollectionData(userData.data[0])
                                  .then((next) => {
                                    userService.changeLastLogin().then(
                                      (userData) => {
                                        dispatch(success(userData));
                                        localStorage.setItem(
                                          "acc_time",
                                          moment()
                                        );
                                        dispatch(
                                          alertActions.success(
                                            "Login Successful"
                                          )
                                        );
                                        dispatch(loadingActions.clearLoading());

                                        var profile_filled = JSON.parse(
                                          window.localStorage.user_data
                                        ).profile_filled;
                                        if (profile_filled === true) {
                                          history.push("/");
                                        } else {
                                          history.push("/profile");
                                        }
                                        // history.push('/');
                                      },
                                      (error) => {
                                        dispatch(failure(error.toString()));
                                        dispatch(
                                          alertActions.error(error.toString())
                                        );
                                        dispatch(loadingActions.clearLoading());
                                      }
                                    );
                                  });
                              },
                              (error) => {
                                dispatch(failure(error.toString()));
                                dispatch(alertActions.error(error.toString()));
                                dispatch(loadingActions.clearLoading());
                              }
                            );
                          },
                          (error) => {
                            dispatch(failure(error.toString()));
                            dispatch(alertActions.error(error.toString()));
                            dispatch(loadingActions.clearLoading());
                          }
                        );
                      },
                      (error) => {
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                        dispatch(loadingActions.clearLoading());
                      }
                    );
                  },
                  (error) => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    dispatch(loadingActions.clearLoading());
                  }
                );
              },
              (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                dispatch(loadingActions.clearLoading());
              }
            );
          },
          (error) => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
            dispatch(loadingActions.clearLoading());
          }
        );
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
        dispatch(loadingActions.clearLoading());
      }
    );
  };
  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function confirmVerification(user) {
  return (dispatch) => {
    dispatch(request(user));

    userService.confirmVerification(user).then(
      (userdata) => {
        userService.changePermission("enabled").then(
          (userdata) => {
            userService.getToken(user.email, user.password).then(
              (userdata) => {
                userService.registerCollection(user).then(
                  (userdata) => {
                    userService.changePermission("disabled").then(
                      (userdata) => {
                        dispatch(success(userdata));
                        dispatch(
                          alertActions.success("Registered successfully.")
                        );
                        dispatch(loadingActions.clearLoading());

                        window.setTimeout(() => {
                          history.push("/login");
                        }, 3000);
                      },
                      (error) => {
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                        dispatch(loadingActions.clearLoading());
                      }
                    );
                  },
                  (error) => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    dispatch(loadingActions.clearLoading());
                  }
                );
              },
              (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
                dispatch(loadingActions.clearLoading());
              }
            );
          },
          (error) => {
            dispatch(failure(error.toString()));
            dispatch(alertActions.error(error.toString()));
            dispatch(loadingActions.clearLoading());
          }
        );
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
        dispatch(loadingActions.clearLoading());
      }
    );
  };
  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function forgotPassword(user) {
  return (dispatch) => {
    dispatch(request(user));
    userService.forgotPassword(user).then(
      (user) => {
        dispatch(success());
        history.push("/login");
        dispatch(
          alertActions.success(
            "Please check your email and click the link to reset your password!"
          )
        );
        dispatch(loadingActions.clearLoading());
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
        dispatch(loadingActions.clearLoading());
      }
    );
  };
  function request(user) {
    return { type: userConstants.FORGOTPASSWORD_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.FORGOTPASSWORD_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.FORGOTPASSWORD_FAILURE, error };
  }
}

function resetPassword(user) {
  return (dispatch) => {
    dispatch(request(user));

    userService.resetPassword(user).then(
      (user) => {
        dispatch(success());
        dispatch(
          alertActions.success("Reset password successful, try to login again!")
        );
        dispatch(loadingActions.clearLoading());

        // window.setTimeout(() => {
        history.push("/login");
        // }, 2000)
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
        dispatch(loadingActions.clearLoading());
      }
    );
  };

  function request(user) {
    return { type: userConstants.RESETPASSWORD_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.RESETPASSWORD_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.RESETPASSWORD_FAILURE, error };
  }
}

function resendToken(user) {
  return (dispatch) => {
    dispatch(request(user));

    userService.resendToken(user).then(
      (user) => {
        dispatch(success());
        dispatch(
          alertActions.success(
            "Resend token successful, please check your email!"
          )
        );
        dispatch(loadingActions.clearLoading());

        window.setTimeout(() => {
          history.push("/login");
        }, 2000);
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
        dispatch(loadingActions.clearLoading());
      }
    );
  };

  function request(user) {
    return { type: userConstants.RESETPASSWORD_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.RESETPASSWORD_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.RESETPASSWORD_FAILURE, error };
  }
}

function checkUserActive(user) {
  return (dispatch) => {
    dispatch(request(user));

    userService.checkActive(user).then(
      (userdata) => {
        if (userdata === "draft") {
          userService.checkTokenExpired(user).then(
            (userdata) => {
              if (userdata === "token") {
                dispatch(loadingActions.clearLoading());
              } else {
                userService.resendToken(user).then(
                  (userdata) => {
                    dispatch(
                      alertActions.error(
                        "Sorry, your token is expired. We re-send your authentication email. Please check your email. "
                      )
                    );
                    window.setTimeout(() => {
                      history.push("/login");
                    }, 8000);
                  },
                  (error) => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    window.setTimeout(() => {
                      history.push("/login");
                    }, 2000);
                  }
                );
              }
            },
            (error) => {}
          );
        } else {
          dispatch(
            alertActions.error(
              "User not found. Please register your account first"
            )
          );
          window.setTimeout(() => {
            history.push("/register");
          }, 3000);
        }
      },
      (error) => {
        dispatch(alertActions.success("You have verified your account"));
        window.setTimeout(() => {
          history.push("/login");
        }, 2000);
      }
    );
  };
  function request(user) {
    return { type: userConstants.RESETPASSWORD_REQUEST, user };
  }

  function failure(error) {
    return { type: userConstants.RESETPASSWORD_FAILURE, error };
  }
}

function refreshToken(token) {
  return (dispatch) => {
    dispatch(request(token));

    userService.refreshToken(token).then(
      (userdata) => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REFRESHTOKEN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REFRESHTOKEN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REFRESHTOKEN_FAILURE, error };
  }
}

function broadcast(user, option) {
  return (dispatch) => {
    dispatch(request(user));
    if (option === "one") {
      userService.broadcast_one(user).then(
        (user) => {
          dispatch(success());
          dispatch(alertActions.success("Successfully Broadcast Email"));
          dispatch(loadingActions.clearLoading());
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
          dispatch(loadingActions.clearLoading());
        }
      );
    } else if (option === "all") {
      userService.broadcast_all(user).then(
        (user) => {
          dispatch(success());
          // history.push('/login');
          dispatch(alertActions.success("Successfully Broadcast Email"));
          dispatch(loadingActions.clearLoading());
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
          dispatch(loadingActions.clearLoading());
        }
      );
    }
  };
  function request(user) {
    return { type: userConstants.BROADCAST_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.BROADCAST_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.BROADCAST_FAILURE, error };
  }
}

function generatePassword(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function getAll() {
  return (dispatch) => {
    dispatch(request());

    userService.getAll().then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return (dispatch) => {
    dispatch(request(id));

    userService.delete(id).then(
      (user) => dispatch(success(id)),
      (error) => dispatch(failure(id, error.toString()))
    );
  };

  function request(id) {
    return { type: userConstants.DELETE_REQUEST, id };
  }
  function success(id) {
    return { type: userConstants.DELETE_SUCCESS, id };
  }
  function failure(id, error) {
    return { type: userConstants.DELETE_FAILURE, id, error };
  }
}
