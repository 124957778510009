export function defaultValue(userdata) {
  const { microsoftSignIn, plutioSignIn, statusSignIn } = userdata.options

  const isMicrosoftSignIn = (microsoftSignIn === "false" || microsoftSignIn === false) ? false : true;

  return JSON.stringify({
    "subscription": 1,
    "subscription_expiry": null,
    "options": {
      "soade": false,
      "ooo": false,
      "wn": false,
      // "sfp": false,
      "cw": 40,
      "ch": 25,
      "pbb": false,
      "hh": false,
      "sl": false,
      "year": userdata.options.year,
      "orientation": "h",
      "abd_flag": false,
      // "sow": false,
      "hdn": false,
      "font_size": 11,
      "monday": true,
      "tuesday": true,
      "wednesday": true,
      "thursday": true,
      "friday": true,
      "saturday": false,
      "sunday": false,
      "start_month": 0,
      "monthNumber": 12,
      "microsoftSignIn": isMicrosoftSignIn,
      "plutioSignIn": plutioSignIn,
      "statusSignIn": statusSignIn,
      "today_color": "#3275b9",
      "off_day_color": "#18a7f5",
      "work_day_color": "#91c2d4",
      "weekday_header_color": "#444444",
      "week_no_header_color": "#444444",
      "month_header_color": "#444444",
      "monthNumber_monthly": 1,


      // "eventDayColor": false
    }
  })
}