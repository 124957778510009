/*
 * changes made :
 * import change - from material-ui to mui
 */
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { paymentActions } from '../../_actions';
import UpgradePopUp from './UpgradePopUp';
import { authHeader } from './../../_helpers';
import * as config from './../../constants/resource';
import i18next from "i18next";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with your real test publishable API key.

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  texttitle: {
    fontSize: 20 + "px",
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      loading: false,
      publish_key: '',
      Upgrade_Now: i18next.t('Pages.upgrade.Upgrade_Now'),
    };

    this.handleClickOpen = this.handleClickOpen.bind(this);
  }

  async handleClickOpen() {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };

    var user_data = JSON.parse(localStorage.user_data);
    var user_id = user_data.user.id;
    return fetch(`${config.URL}/items/user_setting?filter[user]=${user_id}&fields=*,subscription.*`, requestOptions)
      .then(res => res.json())
      .then(res => {
        if (res.data) {
          if (res.data[0]) {
            if (res.data[0].subscription.id === 1 || res.data[0].subscription.id === 5) {
              this.props.open2();
            } else {
              window.location.reload();
            }
          }
        }
      });
  }

  componentWillMount() {
    this.setState({ loading: true });
    fetch("/plugin/get_stripe_key.php", {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        // publish_key = data;
        this.setState({
          loading: false,
          publish_key: data
        });
      });
  }


  render() {
    return (

      <div>
        {(() => {


          if (JSON.parse(localStorage.getItem('user_data')).subscription.show_upgrade) {
            return (
              <div>
                <button className="btn btn-primary" onClick={this.handleClickOpen}><i className="glyphicon glyphicon-cloud-upload"></i> {this.state.Upgrade_Now}</button>
                <UpgradePopUp />
              </div>
            )
          } else {
            return (
              <div></div>
            )
          }
        })()}

      </div>
    )
  }
}

Checkout.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapState(state) {
  const { payment } = state;
  return { payment };
}

const actionCreators = {
  open2: paymentActions.openPayment,
  close: paymentActions.closePayment
};

const connectedCheckout = connect(mapState, actionCreators)(Checkout);
const Checkout2 = withStyles(styles)(connectedCheckout);

export default Checkout2;