import { dialogConstants } from '../_constants';

export function dialog(state = {}, action) {
  switch (action.type) {
    case dialogConstants.OPEN_PAYMENT:
      return {
        message: action.message
      };
    case dialogConstants.CLOSE_PAYMENT:
      return {
        message: action.message
      };
    case dialogConstants.OPEN_REFRESH_TOKEN:
      return {
        message: action.message
      };
    case dialogConstants.CLOSE_REFRESH_TOKEN:
      return {
        message: action.message
      };
    default:
      return state
  }
}