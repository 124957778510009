import { dialogConstants } from '../_constants';

export const dialogActions = {
  openRefreshToken,
  closeRefreshToken
};

function openRefreshToken() {
  return { type: dialogConstants.OPEN_REFRESH_TOKEN, message: true };
}

function closeRefreshToken() {
  return { type: dialogConstants.CLOSE_REFRESH_TOKEN, message: false };
}