/*
* changes made :
* import change - from material-ui to mui
* adjust the style changes in the mui component
* snackbar import changes (from notistack to mui)
*/
import React from 'react';
import { connect } from 'react-redux';
import { CopyrightPage } from '../Menu/footer';
import { userActions } from '../_actions';
import PropTypes from 'prop-types';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { loadingActions } from '../_actions/loading.actions';
import * as config from '../constants/resource';
import AccountCircleRounded from '@mui/icons-material/AccountCircleRounded';
import { withStyles } from '@mui/styles';
import { green } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
	CircularProgress,
	Button,
	CssBaseline,
	Typography,
	Container,
	Box,
	Link,
	Grid,
	Avatar,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  IconButton
} from '@mui/material';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: 35,
    fontSize: 15
  },
  inputRoot: {
    fontSize: 17
  },
  labelRoot: {
    fontSize: 17,

  },
  logo: {
    width: 25,
    height: 25,
  },
  formcontrol: {
    fontSize: 20
  },
  selectData: {
    '& .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu': {
      fontSize: "15px !important",
      lineHeight: "21px"
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      fontSize: "17px"
    },
    '& .MuiSvgIcon-root.MuiSelect-icon': {
      marginTop: "4px"
    }
  },
  textValidator: {
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      fontSize: "15px !important"
    }
  }

});

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const idUrl = urlParams.get('id');


class ProfileInfoPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        city: '',
        country: '',
        industry: '',
        role: '',
        organisation: '',
        website: ''
      },
      code: '',
      submitted: false,
      publish_key: process.env.REACT_APP_PUBLISH_KEY,
      packageData: null,
      error: '',
      industry_option: [],
      role_options: [],
      open: false
    };

		this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeLifetimeDealCode = this.handleChangeLifetimeDealCode.bind(this);
    this.skipProfile = this.skipProfile.bind(this);
    this.props.clearLoading();
    this.getIndustryOptions();
    this.getRoleOptions();
  }

  getIndustryOptions() {
    var yearglance = JSON.parse(localStorage.getItem('yearglance'));

    const options = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + yearglance.data.token, 'Content-Type': 'application/json'
      }
    }

    fetch(`${config.URL}/fields/user_setting/industry`, options)
      .then(res => res.json())
      .then(res => {
        const industry_option = Object.entries(res.data.options.choices);
        this.setState({
          ...this.state,
          industry_option: industry_option
        })
      }
      )
  }

  getRoleOptions() {
    var yearglance = JSON.parse(localStorage.getItem('yearglance'));

    const options = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + yearglance.data.token, 'Content-Type': 'application/json'
      }
    }

    fetch(`${config.URL}/fields/user_setting/role`, options)
      .then(res => res.json())
      .then(res => {
        const role_options = Object.entries(res.data.options.choices);
        this.setState({
          ...this.state,
          role_options: role_options
        })
      }
      )
  }

  skipProfile() {
    var yearglance = JSON.parse(localStorage.getItem('yearglance'));

    // if (this.state.code) {
    const options = {
      method: 'PATCH',
      headers: {
        'Authorization': 'Bearer ' + yearglance.data.token, 'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        profile_filled: true
      })
    }

    fetch(`${config.URL}/items/user_setting/${JSON.parse(localStorage.getItem('user_data')).id}`, options)
      .then(res => res.json())
      .then(res => {
        localStorage.setItem('user_data', JSON.stringify(res.data));
        this.props.load(false);

        window.setTimeout(() => {
          window.location.replace('/');
        }, 2000)

      }
      )
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value
      }
    });
  }

  handleChangeLifetimeDealCode(event) {
    const value = event.target.value;
    this.setState({ code: value })

  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    this.props.load(true);

    var yearglance = JSON.parse(localStorage.getItem('yearglance'));

    var user = this.state.user;
    var body = {
      city: user.city,
      country: user.country,
      industry: user.industry,
      role: user.role,
      organisation: user.organisation,
      website: user.website,
      profile_filled: true
    }

    // if (this.state.code) {
    const options = {
      method: 'PATCH',
      headers: {
        'Authorization': 'Bearer ' + yearglance.data.token, 'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }

    fetch(`${config.URL}/items/user_setting/${JSON.parse(localStorage.getItem('user_data')).id}`, options)
      .then(res => res.json())
      .then(res => {
        localStorage.setItem('user_data', JSON.stringify(res.data));
        this.props.load(false);
        this.setState({ open: true });

        window.setTimeout(() => {
          window.location.replace('/');
        }, 2000)

      }
      )
  }

  componentDidMount() {
    if (idUrl) {
      const requestOptions = {
        method: 'GET',
      };

      return fetch(`${config.URL}/items/subscriptions?filter[id][eq]=${idUrl}&filter[status][eq]=published`, requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(data => {
          if (data.data.length !== 0) {
            this.setState({ packageData: data.data[0] });
          }
          else {
            window.location.replace('/register');
          }
        })
        .then(error => error ? window.location.replace('/register') : '');
    }
  }

  handleClose() {
		this.setState({ open: false });
	}

  render() {
    const { classes, loading } = this.props;
    const { user } = this.state;
    const { open } = this.state;

    return (
      <div>
        <Snackbar
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					open={open}
					autoHideDuration={3000}
					onClose={this.handleClose}
					message={
						<span id="client-snackbar" style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
							<CheckCircleIcon style={{ opacity: '0.9', marginRight: '4px' }} />
							Profile successfully updated
						</span>
					}
					sx={{
						'.MuiSnackbarContent-root': {
							backgroundColor: green[600]
						}
					}}
					action={[
						<React.Fragment>
							<IconButton
								size="small"
								aria-label="close"
								color="inherit"
								onClick={this.handleClose}
							>
								<CloseIcon fontSize="small" />
							</IconButton>
						</React.Fragment>
					]}
				/>
        <Container component="main" maxWidth="sm" style={{ height: "fit-content" }}>
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar} sx={{ bgcolor: '#f50057' }} >
              <AccountCircleRounded sx={{ width: '25px', height: '25px'}} />
            </Avatar>
            <Typography component="h6" variant="h4">
              <b>Profile Information (optional)</b>
            </Typography>
          </div>
          <div>
            <div style={{ fontSize: "14px", marginTop: "10px" }}>
              Please share some information about yourself to help us improve our product.
            </div>
            <ValidatorForm className={classes.form} ref="form" onSubmit={this.handleSubmit} >
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ paddingBottom: "0px", paddingTop: "15px" }}>
                  <Typography component="h6" variant="h4">
                    <b>Where do you located?</b>
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingBottom: "0px", paddingTop: "0px" }}>
                  <TextValidator
                    className={classes.textValidator}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="city"
                    label="City"
                    id="city"
                    autoComplete="city"
                    onChange={this.handleChange}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused
                      }
                    }}
                    value={user.city}
                  />
                </Grid>
                <Grid item xs={12} style={{ paddingBottom: "0px", paddingTop: "0px" }}>
                  <TextValidator
                    className={classes.textValidator}
                    style={{ marginTop: "10px" }}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="country"
                    label="Country"
                    id="country"
                    autoComplete="country"
                    onChange={this.handleChange}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused
                      }
                    }}
                    value={user.country}
                  />
                </Grid>
                <Grid item xs={12} style={{ paddingBottom: "0px", paddingTop: "20px" }}>
                  <Typography component="h6" variant="h4">
                    <b>What type of industry do you work in?</b>
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingBottom: "0px", paddingTop: "15px" }}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel style={{ fontSize: "17px", background: "#fafafa" }} id="demo-simple-select-helper-label">Industry</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      className={classes.selectData}
                      label="Industry"
                      onChange={this.handleChange}
                      inputProps={{
                        name: 'industry',
                        id: 'outlined-age-native-simple',
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {this.state.industry_option.map((value, index) => (
                        <MenuItem key={index} value={value[0]}>{value[1]}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} style={{ paddingBottom: "0px", paddingTop: "20px" }}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel style={{ fontSize: "17px", background: "#fafafa" }} id="demo-simple-select-helper-label">Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      className={classes.selectData}
                      label="Role"
                      onChange={this.handleChange}
                      inputProps={{
                        name: 'role',
                        id: 'outlined-age-native-simple',
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {this.state.role_options.map((value, index) => (
                        <MenuItem key={index} value={value[0]}>{value[1]}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} style={{ paddingBottom: "0px", paddingTop: "25px" }}>
                  <Typography component="h6" variant="h4">
                    <b>Will you use this calendar for an organisation?</b>
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingBottom: "0px", paddingTop: "0px" }}>
                  <TextValidator
                    className={classes.textValidator}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="organisation"
                    label="Organisation Name"
                    id="organisation"
                    autoComplete="organisation"
                    onChange={this.handleChange}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused
                      }
                    }}
                    value={user.organisation}
                  />
                </Grid>
                <Grid item xs={12} style={{ paddingBottom: "0px", paddingTop: "0px" }}>
                  <TextValidator
                    className={classes.textValidator}
                    style={{ marginTop: "10px" }}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="website"
                    label="Organisation Website"
                    id="website"
                    autoComplete="website"
                    onChange={this.handleChange}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused
                      }
                    }}
                    value={user.website}
                  />
                </Grid>
              </Grid>

              <div
                style={{
                  display: "flex", justifyContent: "center", alignItems: "center"
                }}
              >

                <div style={{ display: "flex", width: "100%", marginTop: "20px" }}>
                  <div style={{ width: "50%", lineHeight: "35px" }}>
                  {loading.message != "true" ? <Link onClick={this.skipProfile} variant="h5" style={{ cursor: "pointer" }}>Skip</Link> : ''}
                  </div>
                  <div style={{ width: "50%", textAlign: "right" }}>
                    {loading.message === "true" ? <CircularProgress /> :
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ margin: "0" }}
                        className={classes.submit}
                        sx={{ margin: '6% 0% 3%', height: '35px', fontSize: '15px' }}
                      >
                        Next
                      </Button>
                    }
                  </div>
                </div>
              </div>
              <Grid container>
                <Grid item>
                </Grid>
              </Grid>
            </ValidatorForm>
          </div>

          <Box mt={2}>
            <CopyrightPage />
          </Box>
        </Container>
      </div>
    );
  }
}

ProfileInfoPage.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
};

function mapState(state) {
  const { registering } = state.registration;
  const { loading } = state;
  return { registering, loading };
}

const actionCreators = {
  registerAndPay: userActions.registerAndPay,
  register: userActions.register,
  registerWithVoucher: userActions.registerWithVoucher,
  clearLoading: loadingActions.clearLoading,
  load: loadingActions.load
};

const connectedProfileInfoPage = connect(mapState, actionCreators)(ProfileInfoPage);
const connectAll = withStyles(styles, { withTheme: true })(connectedProfileInfoPage)
export { connectAll as ProfileInfoPage };