export const DASHBOARD_HORIZONTAL = '/horizontal';
export const DASHBOARD_VERTICAL = '/vertical';
export const LOG_IN = '/login';
export const RAW = '/';
export const EVENTS = '/events';
export const REGISTER = '/register';
export const FORGOT_PASSWORD = '/forgotpassword';
export const RESET_PASSWORD = '/resetpassword';
export const VERIFICATION_EMAIL = '/verification';
export const AUTO_LOGIN = '/autologin'
export const CALLBACK = '/callback';
export const BROADCAST = '/broadcast';
export const LIFETIME_DEAL = '/appsumo';
export const PROFILE_INFO = '/profile';
export const PUBLIC_INFO = '/Public';