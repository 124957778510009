/*
 * changes made :
 * import change - from material-ui to mui
 * adjust the style changes in the mui component
 * snackbar import changes (from notistack to mui)
 */
import React from "react";
import { Router, Route } from "react-router-dom";
import { connect } from "react-redux";
import { history } from "../_helpers";
import { alertActions } from "../_actions";
import { PrivateRoute, PrivateRouteLifetime } from "../_components";
import {
  LoginPage,
  SignUpPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  VerificationEmail,
  Callback,
  AutoLogin,
  ProfileInfoPage,
} from "../LoginPage";
import * as ROUTES from "../constants/routes";
import { Raw, Public } from "../Raw";
import { RawSkeleton } from "../RawSkeleton";
import NetworkDetector from "../Hoc/NetworkDetector";
import PropTypes from "prop-types";
import CheckToken from "../Hoc/CheckToken";
import * as config from "../constants/resource";
import { save_log } from "../constants/saveLog";
import { authHeader, localStorageFunction, defaultValue } from "../_helpers";
import moment from "moment";
import { i18n } from "../constants/i18n";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";
import { green } from "@mui/material/colors";
import { withStyles } from "@mui/styles";
import { IconButton, Snackbar } from "@mui/material";

const styles = (theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
  margin: {
    margin: theme.spacing(1),
  },
});

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const utm_content = urlParams.get("utm_content");

class App extends React.Component {
  state = {
    open: false,
    loading: false,
    onBoardingData: {},
  };

  constructor(props) {
    super(props);
    history.listen((location, action) => {
      // clear alert on location change
      this.props.clearAlerts();
    });
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false });
    this.props.clearAlerts();
  };

  refreshData = () => {
    const token = JSON.parse(localStorage.getItem("yearglance")).data.token;
    const userId = JSON.parse(localStorage.getItem("yearglance")).data.user.id;

    //update local storage year glance
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    return (
      fetch(`${config.URL}/users?filter[id]=${userId}`, requestOptions)
        // return fetch(`${config.URL}/items/user_setting`, requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then((userAuth) => {
          if (!userAuth.error) {
            localStorage.setItem(
              "yearglance",
              JSON.stringify({ data: { token: token, user: userAuth.data[0] } })
            );

            //update local storage user_data
            const requestOptions2 = {
              method: "GET",
              headers: authHeader(),
            };

            return fetch(
              `${config.URL}/items/user_setting?filter[user]=${userAuth.data[0].id}&fields=*,subscription.*`,
              requestOptions2
            )
              .then(function (response) {
                return response.json();
              })
              .then((userdata) => {
                const lang = userdata.data[0] ? userdata.data[0].lang : "en";
                i18n(lang);

                localStorageFunction(userdata.data[0]);

                if (userdata.data[0].microsoft != null) {
                  if (userdata.data[0].microsoft.length === 0) {
                    for (var item in localStorage) {
                      if (item.indexOf("msal") === 0) {
                        localStorage.removeItem(item);
                      }
                    }
                  } else {
                    for (var item2 in userdata.data[0].microsoft) {
                      localStorage.setItem(
                        item2,
                        userdata.data[0].microsoft[item2]
                      );
                    }
                  }
                } else {
                  for (var i in localStorage) {
                    if (i.indexOf("msal") === 0) {
                      localStorage.removeItem(i);
                    }
                  }
                }

                var link;
                if (userdata.data[0].onboarding === null) {
                  link = `${config.URL}/items/onboarding?filter[status][eq]=published&[order][neq]=0&sort=order`;
                } else {
                  link = `${config.URL}/items/onboarding?filter[id][nin]=${userdata.data[0].onboarding}&[status][eq]=published&[order][neq]=0&sort=order`;
                }
                return fetch(link, requestOptions2)
                  .then(function (response) {
                    return response.json();
                  })
                  .then((dataOnboarding) => {
                    window.firstOnboard = 1;
                    if (dataOnboarding.data.length > 0) {
                      dataOnboarding.data[0].disableBeacon = true;
                      dataOnboarding.data.map((data, index) => {
                        if (data.target === ".day-today") {
                          if (userdata.data[0].options.orientation === "h") {
                            dataOnboarding.data[index].target =
                              ".horizontal-today";
                          } else if (
                            userdata.data[0].options.orientation === "v"
                          ) {
                            dataOnboarding.data[index].target =
                              ".vertical-today";
                          }
                        }
                        return [];
                      });
                    }
                    window.dataOnboarding = dataOnboarding.data;
                    this.setState({ loading: false });
                    return userdata;
                  });
                // this.setState({loading:false});
              });
          } else {
            localStorage.clear();
            window.location.replace("/login");
          }
        })
        .catch((error) => {
          localStorage.clear();
          window.location.replace("/login");
        })
    );
  };

  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "none";
  };

  referral_process = (userSettingId, referrer_cookie) => {
    const requestOptions = {
      method: "PATCH",
      headers: authHeader(),
      body: JSON.stringify({
        referrer_code: referrer_cookie,
      }),
    };

    return fetch(
      `${config.URL}/items/user_setting/` + userSettingId,
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        this.refreshData();
      })
      .catch((error) => {
        localStorage.clear();
        window.location.replace("/login");
      });
  };

  componentWillMount() {
    if (utm_content) {
      document.cookie = `referrer_code=${utm_content}`;
    }

    if (
      JSON.parse(localStorage.getItem("user_data")) !== null &&
      JSON.parse(localStorage.getItem("yearglance")) !== null
    ) {
      this.setState({ loading: true });

      let user_data = JSON.parse(localStorage.getItem("user_data"));

      let subscription_expiry = user_data.subscription_expiry;

      let userSettingId = user_data.id;

      let referrer_code = user_data.referrer_code;

      let referrer_cookie = this.getCookie("referrer_code");

      if (referrer_cookie !== "none") {
        if (referrer_code !== referrer_cookie) {
          this.referral_process(userSettingId, referrer_cookie);
        }
      }
      let acc_time = localStorage.getItem("acc_time");

      if (acc_time !== null) {
        if (
          moment().format("YYYY-MM-DD") !==
          moment(acc_time).format("YYYY-MM-DD")
        ) {
          localStorage.setItem("acc_time", moment());
          save_log(10);
        }
      } else {
        localStorage.setItem("acc_time", moment());
        save_log(10);
      }

      if (subscription_expiry) {
        let date = new Date(subscription_expiry);
        // let date = new Date(`${new Date().getFullYear()}-${`${new Date().getMonth() + 1}`.padStart(2, 0)}-${`${new Date().getDate()+1}`.padStart(2,0)}`);
        let expiry = new Date(
          `${new Date().getFullYear()}-${`${
            new Date().getMonth() + 1
          }`.padStart(2, 0)}-${`${new Date().getDate()}`.padStart(2, 0)}`
        );
        let timeDiff = date.getTime() - expiry.getTime();
        let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (diffDays <= 0) {
          const requestOptions = {
            method: "PATCH",
            headers: authHeader(),
            body: defaultValue(user_data),
          };

          return fetch(
            `${config.URL}/items/user_setting/` + userSettingId,
            requestOptions
          )
            .then(function (response) {
              return response.json();
            })
            .then((data) => {
              this.refreshData();
            })
            .catch((error) => {
              localStorage.clear();
              window.location.replace("/login");
            });
        } else {
          this.refreshData();
        }
      } else {
        this.refreshData();
      }

      if (user_data.profile_filled && window.location.pathname === "/profile") {
        history.push("/");
      }
    } else {
      localStorage.clear();
    }
  }

  render() {
    const { alert } = this.props;
    const alertType = alert.type;
    const statusOpen = alert.message ? true : this.state.open;

    if (this.state.loading) {
      return (
        <div>
          <RawSkeleton />
        </div>
      );
    } else {
      return (
        <div className="root-main">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {alert.message && (
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={statusOpen}
                autoHideDuration={5000}
                sx={{
                  ".MuiSnackbarContent-root": {
                    backgroundColor:
                      alertType === "error" ? "#cc0000 !important" : green[600],
                  },
                }}
                aria-describedby="client-snackbar"
                message={
                  <span
                    id="client-snackbar"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                    }}
                  >
                    {alertType === "error" ? (
                      <ErrorIcon
                        style={{
                          opacity: "0.9",
                          marginRight: "4px",
                          width: "18px",
                          height: "18px",
                        }}
                      />
                    ) : (
                      <CheckCircleIcon
                        style={{ opacity: "0.9", marginRight: "4px" }}
                      />
                    )}
                    {alert.message}
                  </span>
                }
                action={[
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={this.handleClose}
                  >
                    <CloseIcon
                      sx={{ padding: "4px", width: "25px", height: "25px" }}
                    />
                  </IconButton>,
                ]}
              />
            )}
          </div>
          <Router history={history}>
            <PrivateRoute exact path={ROUTES.RAW} component={Raw} />
            <Route path={ROUTES.CALLBACK} component={Callback} />
            <Route path={ROUTES.LOG_IN} component={LoginPage} />
            <Route path={ROUTES.REGISTER} component={SignUpPage} />
            <Route path={ROUTES.PROFILE_INFO} component={ProfileInfoPage} />
            <Route
              path={ROUTES.FORGOT_PASSWORD}
              component={ForgotPasswordPage}
            />
            <Route path={ROUTES.RESET_PASSWORD} component={ResetPasswordPage} />
            <Route
              path={ROUTES.VERIFICATION_EMAIL}
              component={VerificationEmail}
            />
            <Route path={ROUTES.AUTO_LOGIN} component={AutoLogin} />
            <Route path={ROUTES.PUBLIC_INFO} component={Public} />
            <PrivateRouteLifetime path={ROUTES.LIFETIME_DEAL} component={Raw} />
          </Router>
        </div>
      );
    }
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapState(state) {
  const { alert, authentication } = state;
  const { user } = authentication;
  const { dialog } = state;
  return { user, alert, dialog };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
};

const connectedApp = connect(mapState, actionCreators)(App);
const networkApp = NetworkDetector(connectedApp);
const tokenApp = CheckToken(networkApp);
const connectAll = withStyles(styles)(tokenApp);
export { connectAll as App };
