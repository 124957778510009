import React from "react";
import { Sidebar } from "./Sidebar";
import { Header } from "./Header";
import Skeleton from "@mui/material/Skeleton";

export class RawSkeleton extends React.Component {
  render() {
    return (
      <div>
        {/* <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.4/js/bootstrap.min.js"></script> */}
        <div
          id="page-container"
          className="sidebar-l sidebar-o side-scroll header-navbar-fixed"
        >
          {/* call sidebar */}
          <Sidebar />
          {/* <!-- Header --> */}
          <Header />
          {/* <!-- Main Container --> */}
          <main id="main-container" className="all-day out-of-office">
            {/* <!-- Page Header --> */}
            <div className="content content-title bg-gray-lighter">
              <div className="row items-push">
                <div
                  className="col-lg-10 col-md-10 col-sm-8"
                  style={{ marginTop: 5 + "px" }}
                >
                  <div className="" id="planner-title">
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      height={5 + "vh"}
                      width={100 + "vh"}
                    />
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-4 text-right hidden-xs">
                  <div className="form-group">
                    <div
                      className="input-group"
                      style={{ marginTop: 8 + "px" }}
                    >
                      <span className="input-group-btn">
                        <button
                          className="btn btn-default"
                          type="button"
                          id="prevYr"
                        >
                          <i className="fa fa-angle-left"></i>
                        </button>
                      </span>
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        height={5 + "vh"}
                      />
                      <span className="input-group-btn">
                        <button
                          className="btn btn-default"
                          type="button"
                          id="nextYr"
                        >
                          <i className="fa fa-angle-right"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- END Page Header --> */}

            {/* <!-- Page Content --> */}
            <div className="content content-calendar bg-white">
              {/* <!-- Calendar and Events functionality (initialized in js/pages/base_comp_calendar.js), for more info and examples you can check out http://fullcalendar.io/ --> */}
              <div className="row items-push">
                <div className="col-md-12 col-lg-12">
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    height={75 + "vh"}
                  />
                  {/* <!-- END Add Event Form --> */}
                </div>
              </div>
              {/* <!-- END Calendar --> */}
            </div>
          </main>
          {/* <!-- END Main Container --> */}
        </div>
      </div>
    );
  }
}
