/*
 * changes made :
 * import change - from material-ui to mui
 * adjust the style changes in the mui component
 */
import React from "react";
import { connect } from "react-redux";
import { userActions } from "../_actions";
import { CopyrightPage } from "../Menu/footer";
import PropTypes from "prop-types";
import { loadingActions } from "../_actions/loading.actions";
import * as config from "../constants/resource";
import { withStyles } from "@mui/styles";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  CircularProgress,
  CssBaseline,
  Typography,
  Container,
  Box,
  Avatar,
} from "@mui/material";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: 35,
    fontSize: 15,
  },
  inputRoot: {
    fontSize: 17,
  },
  labelRoot: {
    fontSize: 17,
  },
  logo: {
    width: 25,
    height: 25,
  },
  formcontrol: {
    fontSize: 20,
  },
});

const urlParams = new URLSearchParams(window.location.search);
const request_token = urlParams.get("request_token") || "";
const email = urlParams.get("attributes[email]") || "";
const purchaseId = urlParams.get("idUrl") || "";

class Callback extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      publish_key: process.env.REACT_APP_PUBLISH_KEY,
      packageData: null,
    };
  }

  componentDidMount() {
    if (email === "") {
      const body = {
        request_token: request_token,
      };

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      };

      return fetch(`${config.URL}/auth/sso/access_token`, requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then((result) => {
          if (!result.error) {
            if (purchaseId) {
              const requestOptions = {
                method: "GET",
                headers: {
                  Authorization: "Bearer " + result.data.token,
                  "Content-Type": "application/json",
                },
              };

              return fetch(`${config.URL}/users/me`, requestOptions)
                .then((res) => {
                  return res.json();
                })
                .then((userAuth) => {
                  const requestOptions = {
                    method: "GET",
                  };

                  return fetch(
                    `${config.URL}/items/subscriptions?filter[id][eq]=${purchaseId}`,
                    requestOptions
                  )
                    .then(function (response) {
                      return response.json();
                    })
                    .then((data) => {
                      this.props.googleLoginAndPay(
                        userAuth.data.email,
                        result.data.token,
                        data.data[0].stripe_price_id,
                        purchaseId,
                        data.data[0].length === 0
                          ? "payment"
                          : "subscription"
                      );
                    }
                  );
              });
            } else {
              this.props.loginGoogle(result);
            }
          } else {
            this.props.registerGoogle(email);
          }
        });
    } else {
      this.props.registerGoogle(email);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Container
          component="main"
          maxWidth="xs"
          style={{ height: "fit-content" }}
        >
          <CssBaseline />

          <div className={classes.paper}>
            <Avatar className={classes.avatar} sx={{ bgcolor: "#f50057" }}>
              <LockOutlinedIcon
                className={classes.logo}
                sx={{ width: "25px", height: "25px" }}
              />
            </Avatar>
            <Typography component="h6" variant="h4">
              {purchaseId ? "Redirecting... Please Wait" : "Signing In"}
            </Typography>
            <CircularProgress />
          </div>

          <Box mt={2}>
            <CopyrightPage />
          </Box>
        </Container>
      </div>
    );
  }
}

Callback.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
};

function mapState(state) {
  const { loggingIn } = state.authentication;
  const { loading } = state;
  return { loggingIn, loading };
}

const actionCreators = {
  clearLoading: loadingActions.clearLoading,
  load: loadingActions.load,
  registerGoogle: userActions.registerGoogle,
  loginGoogle: userActions.loginGoogle,
  googleLoginAndPay: userActions.googleLoginAndPay,
};

const connectedForgetPasswordPage = connect(mapState, actionCreators)(Callback);
const connectAll = withStyles(styles, { withTheme: true })(
  connectedForgetPasswordPage
);
export { connectAll as Callback };
