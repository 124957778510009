export const eventConstants = {
  GETALL_REQUEST: 'EVENT_GETALL_REQUEST',
  GETALL_SUCCESS: 'EVENT_GETALL_SUCCESS',
  GETALL_FAILURE: 'EVENT_GETALL_FAILURE',

  INSERT_REQUEST: 'EVENT_INSERT_REQUEST',
  INSERT_SUCCESS: 'EVENT_INSERT_SUCCESS',
  INSERT_FAILURE: 'EVENT_INSERT_FAILURE',

  EDIT_REQUEST: 'EVENT_EDIT_REQUEST',
  EDIT_SUCCESS: 'EVENT_EDIT_SUCCESS',
  EDIT_FAILURE: 'EVENT_EDIT_FAILURE',

  DELETE_REQUEST: 'EVENT_DELETE_REQUEST',
  DELETE_SUCCESS: 'EVENT_DELETE_SUCCESS',
  DELETE_FAILURE: 'EVENT_DELETE_FAILURE',
};
