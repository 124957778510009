import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { ValidatorComponent } from 'react-material-ui-form-validator';

const style = {
  fontSize: '14px',
  color: "#d32f2f",
  position: 'absolute',
};

const checkboxStyle = {
  transform: 'scale(2)', // You can adjust the scale factor as needed
};

class CustomCheckbox extends ValidatorComponent {
  renderValidatorComponent() {
    const { errorMessages, validators, requiredError, value, ...rest } = this.props;

    return (
      <div>
        <Checkbox
          {...rest}
          ref={(r) => {
            this.input = r;
          }}
          style={checkboxStyle} // Apply the custom style to enlarge the checkbox
        />
        {this.errorText()}
      </div>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <div style={style}>
        {this.getErrorMessage()}
      </div>
    );
  }
}

export default CustomCheckbox;
