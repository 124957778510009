/*
 * changes made :
 * import change - from material-ui to mui
 * adjust the style changes in the mui component
 * snackbar import changes (from notistack to mui)
 */
import React from "react";
import { connect } from "react-redux";
import Script from "../_helpers/Script";
import "../original/assets/css/oneui.css";
import "../original/assets/css/style.css";
import "../original/assets/css/print.css";
import "../original/assets/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import * as config from "../constants/resource";
import { authHeader } from "../_helpers";
import { CopyrightRaw } from "../Menu/footer";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { dialogActions } from "../_actions";
import { Sidebar } from "./Sidebar";
import { Header } from "./Header";
import { Options } from "./Options";
// import { Onboarding } from "./Onboarding";
import i18next from "i18next";
import EvEmitter from "../_helpers/EvEmitter";
import { Convert } from "./GoogleTranslate";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { green } from "@mui/material/colors";
import { Snackbar, Alert, IconButton, Collapse } from "@mui/material";

const useStyles = (theme) => ({
  root: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    fontSize: 14,
    paddingTop: 7,
  },
});

let start = 0;

class Raw extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      timeout: false,
      run: false,
      steps: window.dataOnboarding,
      hasNotConnectedCalendars: ((localStorage.getItem("statusSignIn") === false || localStorage.getItem("statusSignIn") === "false") && (localStorage.getItem("microsoftSignIn") === false || localStorage.getItem("microsoftSignIn") === "false")),
      You_need_to_connect_a_calendar_to_get_started: i18next.t(
        "Pages.menu.You_need_to_connect_a_calendar_to_get_started"
      ),
      Connect_Calendar: i18next.t("Pages.sideMenu.Connect_Calendar"),
      mode: localStorage.orientation === "m" ? "other" : "yearly",
      currentView: "Month",
      public_calendar_key: "",
      Click_for_Public_Calendar_Page: i18next.t(
        "Pages.homepage.Click_for_Public_Calendar_Page"
      ),
      isAnnoucementBannerOpen:
        localStorage.getItem("isAnnoucementBannerOpen") === null
          ? true
          : JSON.parse(localStorage.getItem("isAnnoucementBannerOpen")),
    };
    this.handleClose = this.handleClose.bind(this);
    this.changeCurrentView = this.changeCurrentView.bind(this);
    this.props.close();
    this.callPopup = React.createRef();
    this.handleCloseAnnouncementBanner =
      this.handleCloseAnnouncementBanner.bind(this);
  }

  handleCloseAnnouncementBanner() {
    localStorage.setItem("isAnnoucementBannerOpen", false);
    this.setState({
      isAnnoucementBannerOpen: false,
    });
  }

  handleClose() {
    this.setState({ open: false });
    this.props.close();
  }

  submitTitle = (event) => {
    let user_data = JSON.parse(localStorage.getItem("user_data")) || [];

    event.preventDefault();

    const requestOptions = {
      method: "PATCH",
      headers: authHeader(),
      body: JSON.stringify({ title: this.input.value }),
    };

    return fetch(
      `${config.URL}/items/user_setting/` + user_data.id,
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        this.setState({ open: true });
      });
  };

  sidebarPopup = () => {
    this.callPopup.current.parentCallPopup();
  };

  componentDidMount() {
    if (window.dataOnboarding !== []) {
      this.setState({ run: true });
    }
    let user_data = JSON.parse(localStorage.getItem("user_data"));
    if (user_data) {
      if (user_data.public_calendar_key) {
        var public_calendar_link =
          window.location.origin +
          "/Public?public_calendar_key=" +
          user_data.public_calendar_key;
        this.setState({ public_calendar_key: public_calendar_link });
      }
    }
  }

  translating = async (dataText) => {
    if (start < dataText.length) {
      Convert(dataText[start].content).then((data) => {
        dataText[start]["content"] = data;
        if (start + 1 !== dataText.length) {
          start = start + 1;
          this.translating(dataText);
        } else {
          start = 0;
          var x = document.getElementById("tutorial-loader");
          x.style.display = "none";
          this.setState({ steps: dataText, run: true });
        }
      });
    }
  };

  replyTutorial = async () => {
    this.setState({ run: false });
    let user_data = JSON.parse(localStorage.getItem("user_data")) || [];

    const requestOptions2 = {
      method: "GET",
      headers: authHeader(),
    };
    return fetch(
      `${config.URL}/items/onboarding?filter[status][eq]=published&[order][neq]=0&sort=order`,
      requestOptions2
    )
      .then(function (response) {
        return response.json();
      })
      .then((dataOnboard) => {
        if (dataOnboard.data.length > 0) {
          window.firstOnboard = 0;
          dataOnboard.data[0].disableBeacon = true;
          dataOnboard.data.map((inside, index) => {
            if (inside.target === ".day-today") {
              if (localStorage.getItem("orientation") === "h") {
                dataOnboard.data[index].target = ".horizontal-today";
              } else if (localStorage.getItem("orientation") === "v") {
                dataOnboard.data[index].target = ".vertical-today";
                dataOnboard.data[index].disableScrolling = true;
              }
            }
            return null;
          });
          if (user_data.lang === "en") {
            this.setState({ steps: dataOnboard.data, run: true });
          } else {
            this.translating(dataOnboard.data);
          }
        }
      });
  };

  refreshPage = () => {
    EvEmitter.emit("OtherRefresh", { value: false });
    window.refreshCalendarPage();
  };

  changeCurrentView = (props) => {
    this.setState({ currentView: props });
    EvEmitter.emit("changeCurrentView", { value: props });
  };

  render() {
    const { open, hasNotConnectedCalendars } = this.state;
    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={open}
          autoHideDuration={3000}
          onClose={this.handleClose}
          message={
            <span
              id="client-snackbar"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
              }}
            >
              <CheckCircleIcon style={{ opacity: "0.9", marginRight: "4px" }} />
              Your Title is Changed
            </span>
          }
          sx={{
            ".MuiSnackbarContent-root": {
              backgroundColor: green[600],
            },
          }}
        />
        {/* <Onboarding
          turnOffTutorial={this.turnOffTutorial}
          run={this.state.run}
          steps={this.state.steps}
        /> */}
        <div
          id="page-container"
          className="sidebar-l sidebar-o side-scroll header-navbar-fixed"
        >
          {/* call sidebar */}
          <Sidebar replyTutorial={this.replyTutorial} ref={this.callPopup} />
          {/* <!-- Header --> */}
          <Header replyTutorial={this.replyTutorial} ref={this.callPopup} />
          {/* <!-- Main Container --> */}
          <main id="main-container" className="all-day">
            {/* Announcement Banner - just comment out to disable, dont delete as this would be a banner that can come and go often */}
            <Collapse in={this.state.isAnnoucementBannerOpen}>
              <Alert
                severity="info"
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "4px",
                }}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      this.handleCloseAnnouncementBanner();
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <div style={{ fontSize: "14px" }}>
                  Apologies if you are experiencing connection issues. Please follows these <a href="https://yearglance.tawk.help/article/how-to-reset-your-calendar-connection" target="_blank" rel="noreferrer">instructions to reset your connection</a>.
                </div>
              </Alert>
            </Collapse>
            {/* Announcement Banner */}
            {hasNotConnectedCalendars && <div
              className="alert alert-warning"
              id="warning-calendar-connect"
              role="alert"
              style={{ marginBottom: 0 }}
            >
              <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
              &nbsp;{" "}
              <b>{this.state.You_need_to_connect_a_calendar_to_get_started}.</b>
              &nbsp;{" "}
              <button
                type="button"
                className="btn btn-warning"
                onClick={this.sidebarPopup}
              >
                {this.state.Connect_Calendar}
              </button>
            </div>}
            {/* <!-- Page Header --> */}
            <div className="content content-title bg-gray-lighter">
              <div className="row items-push">
                <div
                  className={
                    this.state.mode === "yearly"
                      ? "col-lg-6 col-md-4 col-sm-4 col-xs-4 year-planner-content-title"
                      : "col-lg-6 col-md-6 col-sm-6 col-xs-6 year-planner-content-title"
                  }
                  style={{ marginTop: 5 + "px" }}
                >
                  <div className="yg-title" id="planner-title">
                    <span className="page-heading" id="year-planner-title">
                      My Year Planner
                    </span>
                    &nbsp;
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `
											<a class="fa fa-pencil" href="javascript:void(0);" onclick="editYearPlannerTitle()" style="font-size: 20px;cursor:pointer" data-toggle="popover" data-placement="right" data-content="Edit planner header"></a>                      
										`,
                      }}
                    ></span>
                  </div>
                  <br />
                  <div
                    className="col-lg-6 col-md-6 form-material input-group"
                    id="edit-planner-title"
                    style={{ display: "none" }}
                  >
                    <input
                      className="form-control"
                      type="text"
                      id="input-title"
                      name="input-title"
                      placeholder="Enter your title"
                      ref={(input) => (this.input = input)}
                    />
                    <label htmlFor="input-title">Year Planner Title</label>
                    <span
                      className="input-group-addon"
                      onClick={this.submitTitle}
                      dangerouslySetInnerHTML={{
                        __html: `<i class="fa fa-save" data-toggle="popover" data-placement="top" data-content="Save New Title" style="cursor:pointer" onclick="saveYearPlannerTitle()" ></i>`,
                      }}
                    ></span>
                    <span
                      className="input-group-addon"
                      dangerouslySetInnerHTML={{
                        __html: `<i class="fa fa-times" data-toggle="popover" data-placement="top" data-content="Cancel Editting" style="cursor:pointer;color: #bf0000"  onclick="cancelYearPlannerTitle()"></i>
										`,
                      }}
                    ></span>
                  </div>
                </div>
                <div>
                  <div className="col-lg-3 col-md-3 col-sm-4 col-xs-4 text-right choose-years change-mode"></div>
                </div>

                <div>
                  <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-right choose-years">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<button style="margin-top: 8px" onClick="refreshCalendarPage()" class="btn btn-default yg-refresh" type="button"><i class="fa fa-refresh"></i></button>
									`,
                      }}
                    ></div>
                  </div>
                  <div className="col-lg-2 col-md-4 col-sm-3 col-xs-4 text-right choose-years">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <i
                              id="year-loader"
                              style={{ display: "none" }}
                              className="fa fa-spinner fa-pulse fa-2x fa-fw"
                            ></i>
                          </td>
                          <td>
                            <div className="form-group">
                              <div
                                className="input-group yg-years"
                                style={{ marginTop: 8 + "px" }}
                              >
                                <span className="input-group-btn">
                                  <button
                                    className="btn btn-default"
                                    type="button"
                                    id="prevYr"
                                  >
                                    <i className="fa fa-angle-left"></i>
                                  </button>
                                </span>
                                <input
                                  className="form-control"
                                  type="text"
                                  id="currYearHolder"
                                  style={{ textAlign: "center" }}
                                  disabled
                                />
                                <span className="input-group-btn">
                                  <button
                                    className="btn btn-default"
                                    type="button"
                                    id="nextYr"
                                  >
                                    <i className="fa fa-angle-right"></i>
                                  </button>
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* )
									}
								})()} */}
              </div>
            </div>
            {/* <!-- END Page Header --> */}

            {/* <!-- Page Content --> */}
            <div className="content content-calendar bg-white">
              {/* <!-- Calendar and Events functionality (initialized in js/pages/base_comp_calendar.js), for more info and examples you can check out http://fullcalendar.io/ --> */}
              <div className="row items-push">
                <div className="col-md-12 col-lg-12">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: ` <button class="btn btn-default btn-closefullscreen hidden" onclick="closeFullScreen()" type="button"  title="Click to close fullscreen" data-placement="bottom" style="margin-top:1.4%">
																<i class="fa fa-compress"></i>
														</button>`,
                    }}
                  ></div>
                  <div
                    className="yg-horizontal"
                    id="horizontalDisplay"
                    style={{ display: "none" }}
                  ></div>
                  <div
                    className="yg-horizontal"
                    id="abdHorizontalDisplay"
                    style={{ display: "none" }}
                  ></div>
                  <div
                    className="yg-vertical currentView"
                    id="verticalDisplay"
                    style={{ tableLayout: "auto" }}
                  ></div>
                  <div
                    className="yg-vertical currentView"
                    id="abdVerticalDisplay"
                    style={{ tableLayout: "auto" }}
                  ></div>
                  <div
                    className="yg-monthly"
                    id="monthlyDisplay"
                    style={{ display: "none" }}
                  ></div>
                </div>
              </div>
              {/* <!-- END Calendar --> */}
            </div>
            {/* <!-- END Page Content --> */}
            {/* <!-- Footer --> */}
            <CopyrightRaw />
            {/* <!-- END Footer --> */}
          </main>
          {/* <!-- END Main Container --> */}
        </div>
        {/* <!-- Apps Modal --> */}
        {/* <!-- Opens from the button in the header --> */}
        <div
          className="modal fade"
          id="apps-modal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-sm modal-dialog modal-dialog-top">
            <div className="modal-content">
              {/* <!-- Apps Block --> */}
              <div className="block block-themed block-transparent">
                <div className="block-header bg-primary-dark">
                  <ul className="block-options">
                    <li>
                      <button
                        data-dismiss="modal"
                        type="button"
                        style={{ paddingTop: 14 + "px" }}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </li>
                  </ul>
                  <h3 className="block-title">App Video Tutorial</h3>
                </div>
                <div className="block-content">
                  <div className="row text-center">
                    <div className="col-xs-12 col-md-12 col-lg-12">
                      <div className="col-xs-12">
                        {/* <!--<iframe id="videoTutorial" width="100%" height="100%" src="https://www.youtube.com/embed/Amj36sD1fEQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>--> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- END Apps Block --> */}
            </div>
          </div>
        </div>
        {/* <!--Modal for search result--> */}
        <div
          className="modal fade"
          id="modal-search-result"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" style={{ width: 45 + "%" }}>
            <div className="modal-content">
              <div className="block block-themed block-transparent remove-margin-b">
                <div
                  className="block-header bg-success"
                  style={{ backgroundColor: "green" }}
                >
                  <ul className="block-options">
                    <li>
                      <button
                        data-dismiss="modal"
                        type="button"
                        style={{ paddingTop: 20 + "px" }}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </li>
                  </ul>
                  <h3
                    className="block-title"
                    style={{
                      color: "white",
                      fontSize: 20 + "px",
                      paddingBottom: 10 + "px",
                    }}
                  >
                    <span id="count-search-results"></span> Event/s Found
                  </h3>
                </div>
                <div className="block-content">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="block">
                        <div className="block-header">
                          <h3
                            className="block-title text-center"
                            style={{ fontSize: 30 }}
                          >
                            List of Events
                          </h3>
                        </div>
                        <div className="block-content">
                          <table className="table" id="table-search-result">
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    width: 50 + "px",
                                    textAlign: "left",
                                    color: "white",
                                  }}
                                >
                                  #
                                </th>
                                <th
                                  className=""
                                  style={{ textAlign: "left", color: "white" }}
                                >
                                  Event Name
                                </th>
                                <th
                                  style={{ textAlign: "left", color: "white" }}
                                >
                                  Event Description
                                </th>
                                <th
                                  className="hidden-xs"
                                  style={{
                                    width: 15 + "%",
                                    textAlign: "left",
                                    color: "white",
                                  }}
                                >
                                  Event Date
                                </th>
                                <th
                                  className="text-center"
                                  style={{ width: 100 + "px", color: "white" }}
                                >
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-sm btn-default"
                  type="button"
                  data-dismiss="modal"
                  style={{ fontSize: 15 }}
                >
                  Close
                </button>
                <button
                  className="btn btn-sm btn-primary"
                  type="button"
                  data-dismiss="modal"
                  style={{ fontSize: 15 }}
                >
                  <i className="fa fa-check"></i> Ok
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!--End modal for search result--> */}
        {/* <!--Modal for error searching--> */}
        <div
          className="modal fade"
          id="error-search-modal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-md modal-dialog modal-dialog-top">
            <div className="modal-content">
              {/* <!-- Apps Block --> */}
              <div className="block block-themed block-transparent">
                <div className="block-header bg-city">
                  <ul className="block-options">
                    <li>
                      <button
                        data-dismiss="modal"
                        type="button"
                        style={{ paddingTop: 20 + "px" }}
                      >
                        <i className="si si-close"></i>
                      </button>
                    </li>
                  </ul>
                  <h3
                    className="block-title"
                    style={{
                      color: "white",
                      fontSize: 20 + "px",
                      paddingBottom: 10 + "px",
                    }}
                  >
                    Oops!
                  </h3>
                </div>
                <div className="block-content">
                  <ul className="list list-simple list-li-clearfix">
                    <li
                      dangerouslySetInnerHTML={{
                        __html: `
											<a class="item item-rounded pull-left push-10-r bg-warning" href="javascript:void(0)">
												<i class="fa fa-warning text-white-op"></i></a>
											<div>Please enter event that you want to search and make sure to check events in sidebar under google calendar before searching.</div>
										`,
                      }}
                    ></li>
                  </ul>
                </div>
              </div>
              {/* <!-- END Apps Block --> */}
            </div>
          </div>
        </div>
        <div id="invtrflfloatbtn"></div>
        {/* <!--End modal for error searching--> */}
        <Options mode={this.state.mode} />
        {/* <!-- END Page Container --> */}
        <Helmet>
          <script>
            {`
						var authorizeButton = document.getElementById('authorize-button');
						var signoutButton = document.getElementById('signout-button');
					`}
          </script>
          <script
            type="text/javascript"
            src={
              "assets/" +
              process.env.REACT_APP_JS_DIR +
              "/app.js?v=" +
              process.env.REACT_APP_GIT_HASH
            }
          ></script>
          <script
            type="text/javascript"
            src={
              "assets/" +
              process.env.REACT_APP_JS_DIR +
              "/tawkto.js?v=" +
              process.env.REACT_APP_GIT_HASH
            }
          ></script>
        </Helmet>

        <Script
          type="text/javascript"
          src={
            "assets/" +
            process.env.REACT_APP_JS_DIR +
            "/plutioapi.js?v=" +
            process.env.REACT_APP_GIT_HASH
          }
          async={false}
        />
        <Script
          type="text/javascript"
          src={
            "assets/" +
            process.env.REACT_APP_JS_DIR +
            "/yearplanner.js?v=" +
            process.env.REACT_APP_GIT_HASH
          }
          async={false}
        />
      </div>
    );
  }
}

Raw.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapState(state) {
  const { authentication } = state;
  const { user } = authentication;
  const { dialog } = state;
  return { user, dialog };
}

const actionCreators = {
  open: dialogActions.openRefreshToken,
  close: dialogActions.closeRefreshToken,
};

const connectedRaw = connect(mapState, actionCreators)(Raw);
const connectAll = withStyles(useStyles, { withTheme: true })(connectedRaw);
export { connectAll as Raw };
