import { loadingConstants } from "../_constants";

export const loadingActions = {
  load,
  clearLoading,
};

function load() {
  return { type: loadingConstants.LOADING, message: "true" };
}

function clearLoading() {
  return { type: loadingConstants.CLEAR, message: "false" };
}
