import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { loading } from './loading.reducer';
import { event } from './event.reducer';
import { dialog } from './dialog.reducer';
import { payment } from './payment.reducer';

const rootReducer = combineReducers({
  authentication,
  registration,
  users,
  alert,
  event,
  loading,
  dialog,
  payment
});

export default rootReducer;