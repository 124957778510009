/*
 * changes made :
 * import change - from material-ui to mui
 * adjust the style changes in the mui component
 */
import React from 'react';
import Subscribed from './AdminPage/Subscribed';
import Activity from './AdminPage/Activity';
import AmountActivity from './AdminPage/AmountActivity';
import { save_log } from '../constants/saveLog';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import {
  Dialog,
  Button,
  AppBar,
  Toolbar,
  Fab,
  Slide
} from '@mui/material';

const useStylesCustomButton = makeStyles({
  button: {
    '&:hover': {
      backgroundColor: '#fff !important',
      color: 'black',
    },
  }
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AdminPage() {
  const classes = useStylesCustomButton();
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState('subscribed');

  const handleClickOpen = () => {
    save_log(16);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changePage = (data) => {
    setPage(data);
  };
  
  return (
    <div style={{ display: 'flex'}}>
      <Button
        sx={{ fontSize: '14px' }}
        size="medium"
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
      >
        admin Page
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative'}}>
          <Toolbar>
            <Fab size="small" color="primary" aria-label="add"  onClick={handleClose}>
              <CloseIcon />
            </Fab>
            <Button
              sx={{ color: page === 'subscribed' ? "black" : "white", fontSize: '14px', background: page === 'subscribed'? "white" : "", paddingX: '16px', marginLeft: '16px' }}
              className={classes.button}
              onClick={()=>changePage('subscribed')}
            >
              Subscribed
            </Button>
            <Button 
              sx={{ color: page === 'activity' ? "black" : "white", fontSize: '14px', background: page === 'activity'? "white" : "", paddingX: '16px', marginLeft: '16px' }}
              className={classes.button}
              onClick={()=>changePage('activity')}
              >
              User Activity
            </Button>
            <Button 
              sx={{ color: page === 'AmountActivity' ? "black" : "white", fontSize: '14px', background: page === 'AmountActivity' ? "white" : "", paddingX: '16px', marginLeft: '16px' }}
              className={classes.button}
              onClick={()=>changePage('AmountActivity')}
            >
              Amount Activity
            </Button>
          </Toolbar>
        </AppBar>
        {page === 'subscribed'?
          <Subscribed />
        :''}
        {page === 'activity'?
          <Activity />
        :''}
        {page === 'AmountActivity'?
          <AmountActivity />
        :''}
      </Dialog>
    </div>
  );

}
