import { paymentConstants } from '../_constants';

export function payment(state = {}, action) {
  switch (action.type) {
    case paymentConstants.OPEN_PAYMENT:
      return {
        message: action.message
      };
    case paymentConstants.CLOSE_PAYMENT:
      return {
        message: action.message
      };
    default:
      return state
  }
}