export function Convert(dataText) {
  return new Promise((resolve,reject)=>{
    let fromLang = 'en';
    let toLang = JSON.parse(localStorage.getItem('user_data')).lang;
    let text = dataText;

    let url = `https://translate.googleapis.com/translate_a/single?client=gtx`;
    url += `&sl=${fromLang}`;
    url += `&tl=${toLang}`;
    url += `&hl=en-US&dt=t&dt=bd&dj=1&source=icon&tk=28098.28098`;
    url += '&q=' + encodeURI(text);

    fetch(url, { 
      method: 'GET',
    })
    .then(res => res.json())
    .then(response => {
      resolve(response.sentences[0].trans)
    })
    .catch(error => {
      reject(null);
    });
  })
}