/*
 * changes made :
 * import change - from material-ui to mui
 */
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { paymentActions } from '../_actions';
import { save_log } from '../constants/saveLog';
import i18next from "i18next";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with your real test publishable API key.

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  texttitle: {
    fontSize: 20 + "px",
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

class SidebarTop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Customize_My_Planner: i18next.t('Pages.sideMenu.Customize_My_Planner'),
      Orientation: i18next.t('Pages.sideMenu.Orientation'),
      Horizontal_View: i18next.t('Pages.sideMenu.Horizontal_View'),
      Vertical_View: i18next.t('Pages.sideMenu.Vertical_View'),
      Monthly_View: i18next.t('Pages.sideMenu.Monthly_View'),
    }

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleHorizontalVierticalView = this.handleHorizontalVierticalView.bind(this);
    this.handleMonthlyView = this.handleMonthlyView.bind(this);
  }

  handleClickOpen() {
    this.props.open2();
  }

  Options = () => {
    save_log(5);
    window.openOptions();
  }

  handleHorizontalVierticalView(view) {
    if (view === "Horizontal") {
      window.horizontalView();
    } else {
      window.verticalView();
    }
  }

  handleMonthlyView() {
    window.monthlyView();
  }

  render() {
    return (

      <div>
        {(() => {
          if (JSON.parse(localStorage.getItem("user_data")).subscription.allow_customise === true) {
            return (
              <div>
                <li className="yg-customize" id="customizeText">
                  <a href="#/" style={{ cursor: 'pointer' }} onClick={() => this.Options()}>
                    <i className="si si-speedometer" id="customizeIcon"></i><span className="sidebar-mini-hide" >{this.state.Customize_My_Planner}</span>
                  </a>
                </li>
                <li className="nav-main-heading" id="orientationText">
                  <span className="sidebar-mini-hide">{this.state.Orientation}</span>
                </li>
                <li className="yg-horizontal" id="horizontalText">
                  <a href="#/" style={{ cursor: 'pointer' }} onClick={() => this.handleHorizontalVierticalView("Horizontal")}>
                    <i className="fa fa-arrow-right"></i><span className="sidebar-mini-hide">{this.state.Horizontal_View}</span>
                  </a>
                </li>
                <li className="yg-vertical" id="verticalText">
                  <a href="#/" style={{ cursor: 'pointer' }} onClick={() => this.handleHorizontalVierticalView("Vertical")}>
                    <i className="fa fa-arrow-down"></i><span className="sidebar-mini-hide">{this.state.Vertical_View}</span>
                  </a>
                </li>
                <li className="yg-monthly" id="monthlyText">
                  <a href="#/" style={{ cursor: 'pointer' }} onClick={() => this.handleMonthlyView()}>
                    <i className="fa fa-calendar"></i><span className="sidebar-mini-hide">{this.state.Monthly_View}</span>
                  </a>
                </li>
              </div>
            )
          } else {
            return (
              <div>
                <li>
                  <div id="customizeTextFree" >
                    <a href="#/" style={{ cursor: 'pointer' }} onClick={this.handleClickOpen}><i className="si si-speedometer" id="customizeIcon"></i><span className="sidebar-mini-hide" >{this.state.Customize_My_Planner}</span></a>
                  </div>
                </li>
                <li className="nav-main-heading" id="orientationTextFree">
                  <div>
                    <span className="sidebar-mini-hide">{this.state.Orientation}</span>
                  </div>
                </li>
                <li>
                  <div id="horizontalTextFree">
                    <a href="#/" style={{ cursor: 'pointer' }} onClick={this.handleClickOpen}><i className="fa fa-arrow-right"></i><span className="sidebar-mini-hide">{this.state.Horizontal_View}</span></a>
                  </div>
                </li>
                <li>
                  <div id="verticalTextFree">
                    <a href="#/" style={{ cursor: 'pointer' }} onClick={this.handleClickOpen}><i className="fa fa-arrow-down"></i><span className="sidebar-mini-hide">{this.state.Vertical_View}</span></a>
                  </div>
                </li>
                <li>
                  <div id="monthlyTextFree">
                    <a href="#/" style={{ cursor: 'pointer' }} onClick={this.handleClickOpen}><i className="fa fa-calendar"></i><span className="sidebar-mini-hide">{this.state.Monthly_View}</span></a>
                  </div>
                </li>
              </div>
            )
          }
        })()}

      </div>
    )
  }
}

SidebarTop.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapState(state) {
  const { payment } = state;
  return { payment };
}

const actionCreators = {
  open2: paymentActions.openPayment,
  close: paymentActions.closePayment
};

const connectedCheckout = connect(mapState, actionCreators)(SidebarTop);
const SidebarTop2 = withStyles(styles)(connectedCheckout);

export default SidebarTop2;