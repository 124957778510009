/*
 * changes made :
 * import change - from material-ui to mui
 * DatePicker changes to mui/x-date
 */
import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { authHeader } from "../../_helpers";
import * as config from "../../constants/resource";
import moment from "moment";
import CustomGroupRow from "../../_helpers/CustomComponent";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import {
  Skeleton,
  Grid,
  List,
  ListItem,
  ListItemText,
  Fab,
  ListSubheader,
  ThemeProvider,
  createTheme
} from "@mui/material";

export default function Activity() {
  const [loading, setLoading] = useState(false);
  const [activityData, setActivityData] = useState([]);

  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      loadActivity();
    }
  }, []);

  const loadActivity = async () => {
    setLoading(true);

    const start_date = moment(new Date(startDate)).format("YYYY-MM-DD 00:00:00");
    const end_date = moment(new Date(endDate)).format("YYYY-MM-DD 23:59:59");

    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    return fetch(`${config.URL}/items/history_log?filter[created_on][between]=${start_date},${end_date}&limit=-1&fields=location.*, activity.*,id,user.*.*,created_on`, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then((result) => {
        result.data.map((item) => {
          if (item.created_on) {
            item.created_on = moment(item.created_on).format("DD/MM/YYYY");
          }
          return item;
        });
        setActivityData(result.data);

        setLoading(false);
      });
  };

  const customRow = (rowData) => {
    return <CustomGroupRow {...rowData} />;
  };

  function ActivityTable() {
    const defaultMaterialTheme = createTheme();

    return (
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          title=""
          columns={[
            { title: "Name", field: "user.user.first_name" },
            { title: "Email", field: "user.user.email" },
            { title: "Activity", field: "activity.name" },
            { title: "Date", field: "created_on" },
          ]}
          data={activityData}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 50, 100],
            filtering: true,
            grouping: true,
            exportButton: true,
          }}
          localization={{
            pagination: {
              labelRowsPerPage: "",
            },
          }}
          components={{
            GroupRow: (rowData) => customRow(rowData),
          }}
          detailPanel={(rowData) => {
            return (
              <List
                dense={true}
                subheader={
                  <ListSubheader>
                    <h4>Location</h4>
                  </ListSubheader>
                }
              >
                <ListItem>
                  <ListItemText
                    primary="IP"
                    secondary={rowData.location.IPv4}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="City"
                    secondary={rowData.location.city}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="State"
                    secondary={rowData.location.state}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Country"
                    secondary={rowData.location.country_name}
                  />
                </ListItem>
              </List>
            );
          }}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
        />
      </ThemeProvider>
    );
  }

  if (loading === true) {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>

          <Grid item xs={12}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return (
      <div>
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker", "DatePicker"]}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                maxDate={endDate}
                slotProps={{
                  textField: {
                    helperText: "mm/dd/yyyy",
                  },
                }}
                inputFormat="dd-MM-yyyy"
              />
              <DatePicker
                label="End Date"
                value={endDate}
                minDate={startDate}
                onChange={(newValue) => setEndDate(newValue)}
                slotProps={{
                  textField: {
                    helperText: "mm/dd/yyyy",
                  },
                }}
              />
              <Fab
                size="small"
                color="primary"
                aria-label="add"
                onClick={() => loadActivity()}
              >
                <SearchIcon />
              </Fab>
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <ActivityTable />
      </div>
    );
  }
}
