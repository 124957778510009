export function localStorageFunction(userdata) {
  localStorage.setItem('user_data', JSON.stringify(userdata));
  localStorage.setItem('title', userdata.title);
  localStorage.setItem('cw', userdata.options.cw);
  localStorage.setItem('hh', userdata.options.hh);
  localStorage.setItem('pbb', userdata.options.pbb);
  localStorage.setItem('soade', userdata.options.soade);
  localStorage.setItem('ooo', userdata.options.ooo);
  localStorage.setItem('wn', (userdata.options.wn === "true" ? userdata.options.wn : "false"));
  localStorage.setItem('year', userdata.options.year);
  localStorage.setItem('orientation', userdata.options.orientation);
  localStorage.setItem('abd_flag', userdata.options.abd_flag);
  localStorage.setItem('hdn', (userdata.options.hdn === "true" ? userdata.options.hdn : "false"));
  localStorage.setItem('calendars', JSON.stringify(userdata.calendars));

  if (userdata.options.monthNumber_monthly) {
    localStorage.setItem('monthNumber_monthly', userdata.options.monthNumber_monthly);
  }
  else {
    localStorage.setItem('monthNumber_monthly', 1);
  }

  if (userdata.options.monthNumber) {
    localStorage.setItem('monthNumber', userdata.options.monthNumber);
  }
  else {
    localStorage.setItem('monthNumber', "12");
  }

  if (userdata.options.plutioSignIn) {
    if (JSON.parse(localStorage.getItem("user_data")).subscription.allow_integrations === true) {
      localStorage.setItem('plutioSignIn', userdata.options.plutioSignIn);
    }
    else {
      localStorage.setItem('plutioSignIn', "false");
    }
  }
  else {
    localStorage.setItem('plutioSignIn', "false");
  }

  // if (userdata.options.statusSignIn) {
  //   localStorage.setItem('statusSignIn', userdata.options.statusSignIn);
  // }
  // else {
  //   localStorage.setItem('statusSignIn', "true");
  // }

  // if (userdata.options.microsoftSignIn) {
  //   const { microsoftSignIn } = userdata.options
  //   const isMicrosoftSignIn = (microsoftSignIn === "false" || microsoftSignIn === false) ? false : true;

  //   localStorage.setItem('microsoftSignIn', isMicrosoftSignIn);
  // }
  // else {
  //   localStorage.setItem('microsoftSignIn', true);
  // }

  if (userdata.options.sl) {
    localStorage.setItem('sl', userdata.options.sl);
  }
  else {
    localStorage.setItem('sl', "false");
  }

  if (userdata.options.tawkto) {
    localStorage.setItem('tawkto', userdata.options.tawkto);
  }
  else {
    localStorage.setItem('tawkto', "false");
  }

  if (userdata.options.ch) {
    localStorage.setItem('ch', userdata.options.ch);
  }
  else {
    localStorage.setItem('ch', "25");
  }

  if (userdata.options.today_color) {
    localStorage.setItem('today_color', userdata.options.today_color);
  }
  else {
    localStorage.setItem('today_color', "#183c64");
  }

  if (userdata.options.font_size) {
    localStorage.setItem('font_size', userdata.options.font_size);
  }
  else {
    localStorage.setItem('font_size', "11");
  }

  if (userdata.options.work_day_color) {
    localStorage.setItem('work_day_color', userdata.options.work_day_color);
  }
  else {
    localStorage.setItem('work_day_color', "#91c2d4");
  }

  if (userdata.options.off_day_color) {
    localStorage.setItem('off_day_color', userdata.options.off_day_color);
  }
  else {
    localStorage.setItem('off_day_color', "#18a7f5");
  }

  if (userdata.options.monday != null) {
    localStorage.setItem('monday', userdata.options.monday);
  }
  else {
    localStorage.setItem('monday', "true");
  }
  if (userdata.options.tuesday != null) {
    localStorage.setItem('tuesday', userdata.options.tuesday);
  }
  else {
    localStorage.setItem('tuesday', "true");
  }
  if (userdata.options.wednesday != null) {
    localStorage.setItem('wednesday', userdata.options.wednesday);
  }
  else {
    localStorage.setItem('wednesday', "true");
  }
  if (userdata.options.thursday != null) {
    localStorage.setItem('thursday', userdata.options.thursday);
  }
  else {
    localStorage.setItem('thursday', "true");
  }
  if (userdata.options.friday != null) {
    localStorage.setItem('friday', userdata.options.friday);
  }
  else {
    localStorage.setItem('friday', "true");
  }
  if (userdata.options.saturday != null) {
    localStorage.setItem('saturday', userdata.options.saturday);
  }
  else {
    localStorage.setItem('saturday', "false");
  }
  if (userdata.options.sunday != null) {
    localStorage.setItem('sunday', userdata.options.sunday);
  }
  else {
    localStorage.setItem('sunday', "false");
  }

  if (userdata.options.start_month != null) {
    if (userdata.options.start_month.length > 3) {
      localStorage.setItem('start_month', 0);
    }
    else {
      localStorage.setItem('start_month', userdata.options.start_month);
    }
  }
  else {
    localStorage.setItem('start_month', 0);
  }

  if (userdata.options.weekday_header_color) {
    localStorage.setItem('weekday_header_color', userdata.options.weekday_header_color);
  }
  else {
    localStorage.setItem('weekday_header_color', "#444444");
  }

  if (userdata.options.week_no_header_color) {
    localStorage.setItem('week_no_header_color', userdata.options.week_no_header_color);
  }
  else {
    localStorage.setItem('week_no_header_color', "#444444");
  }

  if (userdata.options.month_header_color) {
    localStorage.setItem('month_header_color', userdata.options.month_header_color);
  }
  else {
    localStorage.setItem('month_header_color', "#444444");
  }

  if (userdata.options.month_header_monthly_color) {
    localStorage.setItem('month_header_monthly_color', userdata.options.month_header_monthly_color);
  }
  else {
    localStorage.setItem('month_header_monthly_color', "#334f71");
  }
}