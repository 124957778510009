/*
 * changes made :
 * import change - from material-ui to mui
 * adjust the style changes in the mui component
 */
import React from "react";
import { connect } from "react-redux";
import { userActions } from "../_actions";
import { CopyrightPage } from "../Menu/footer";
import PropTypes from "prop-types";
import { ValidatorForm } from "react-material-ui-form-validator";
import { loadingActions } from "../_actions/loading.actions";
import { withStyles } from "@mui/styles";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  CircularProgress,
  CssBaseline,
  Typography,
  Container,
  Box,
  Avatar,
} from "@mui/material";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: 35,
    fontSize: 15,
  },
  inputRoot: {
    fontSize: 17,
  },
  labelRoot: {
    fontSize: 17,
  },
  logo: {
    width: 25,
    height: 25,
  },
  formcontrol: {
    fontSize: 20,
  },
});

class AutoLogin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        token: "",
        id_user: "",
        email: "",
        password: "",
        status: false,
      },
      submitted: false,
    };
  }

  componentWillMount() {
		
		const query = new URLSearchParams(this.props.location.search);
		const token = query.get('token');

		this.props.autoLogin(token);
	}

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Container
          component="main"
          maxWidth="xs"
          style={{ height: "fit-content" }}
        >
          <CssBaseline />

          <div className={classes.paper}>
            <Avatar className={classes.avatar} sx={{ bgcolor: "#f50057" }}>
              <LockOutlinedIcon
                className={classes.logo}
                sx={{ width: "25px", height: "25px" }}
              />
            </Avatar>
            <Typography component="h6" variant="h4">
              Welcome to Year Glance
            </Typography>
            <br />
            <Typography color="error" component="h6" variant="h5">
              We will log you in, please wait...
            </Typography>
            <ValidatorForm
              className={classes.form}
              onSubmit={this.handleSubmit}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            </ValidatorForm>
          </div>

          <Box mt={2}>
            <CopyrightPage />
          </Box>
        </Container>
      </div>
    );
  }
}

AutoLogin.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
};

function mapState(state) {
  const { loggingIn } = state.authentication;
  const { loading } = state;
  return { loggingIn, loading };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
  confirmVerification: userActions.confirmVerification,
  clearLoading: loadingActions.clearLoading,
  load: loadingActions.load,
  isExist: userActions.isExist,
  autoLogin: userActions.autoLogin,
};

const connectedForgetPasswordPage = connect(
  mapState,
  actionCreators
)(AutoLogin);
const connectAll = withStyles(styles, { withTheme: true })(
  connectedForgetPasswordPage
);
export { connectAll as AutoLogin };
