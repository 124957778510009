import { loadingConstants } from '../_constants';

export function loading(state = {}, action) {
  switch (action.type) {
    case loadingConstants.LOADING:
      return {
        message: action.message
      };
    case loadingConstants.CLEAR:
      return {
        message: action.message
      };
    default:
      return state
  }
}