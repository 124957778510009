import React from 'react';

const currentYear = new Date().getFullYear();
const lastTwoDigits = currentYear % 100;

export function CopyrightRaw() {
  return (
    <footer id="page-footer" className="content-mini content-mini-full font-s12 bg-gray-lighter clearfix">
      <div className="pull-left">
        <a className="font-600" rel="noopener noreferrer" href="https://yearglance.com/" target="_blank">yearglance.com</a> &copy; <span className="js-year-copy">2015-{lastTwoDigits}</span> &nbsp;
      </div>
      <div className="pull-left">
        Crafted with <i className="fa fa-heart text-city"></i> by <a className="font-w600" rel="noopener noreferrer" href="https://yearglance.com/" target="_blank">Year Glance Team</a>
      </div>
    </footer>
  );
}
export function CopyrightPage() {
  return (
    <footer id="page-footer" className="content-mini content-mini-full font-s12 clearfix">
      <div style={{
        display: "flex", justifyContent: "center", alignItems: "center"
      }}>
        <a className="font-600" rel="noopener noreferrer" href="https://yearglance.com/" target="_blank"> yearglance.com </a> &copy; <span className="js-year-copy"> 2015-{lastTwoDigits}</span>
      </div>
    </footer>
  );
}
