/*
 * changes made :
 * import change - from material-ui to mui
 * adjust the style changes in the mui component
 */
import React from "react";
import { connect } from "react-redux";
import { userActions } from "../_actions";
import { CopyrightPage } from "../Menu/footer";
import PropTypes from "prop-types";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { loadingActions } from "../_actions/loading.actions";
import GoogleButton from "react-google-button";
import * as config from "../constants/resource";
import { withStyles } from "@mui/styles";
import {
  CircularProgress,
  Button,
  CssBaseline,
  Typography,
  Container,
  Box,
  Link,
  Grid,
} from "@mui/material";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    marginTop: theme.spacing(1),
    width: 350 + "px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: 35,
    fontSize: 15,
  },
  inputRoot: {
    fontSize: 17,
  },
  labelRoot: {
    fontSize: 17,
  },
  logo: {
    width: 25,
    height: 25,
  },
  formcontrol: {
    fontSize: 20,
  },
});

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const idUrl = urlParams.get("id");

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      submitted: false,
      open: false,
      publish_key: process.env.REACT_APP_PUBLISH_KEY,
      packageData: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    this.props.load(true);

    const { email, password } = this.state;
    if (email && password) {
      if (idUrl) {
        this.props.loginAndPay(
          email,
          password,
          this.state.packageData.stripe_price_id,
          idUrl,
          this.state.packageData.length === 0 ? "payment" : "subscription"
        );
      } else {
        this.props.login(email, password);
      }
    }
  }

  handleClickOpen = (event) => {
    this.setState({ open: true });
  };

  handleClose = (event) => {
    this.setState({ open: false });
  };

  componentDidMount() {
    if (idUrl) {
      const requestOptions = {
        method: "GET",
      };

      return fetch(
        `${config.URL}/items/subscriptions?filter[id][eq]=${idUrl}&filter[status][eq]=published`,
        requestOptions
      )
        .then(function (response) {
          return response.json();
        })
        .then((data) => {
          if (data.data.length !== 0) {
            this.setState({ packageData: data.data[0] });
          } else {
            window.location.replace("/login");
          }
        })
        .then((error) => (error ? window.location.replace("/register") : ""));
    }
  }

  render() {
    const { classes } = this.props;
    const { email, password } = this.state;
    const { loading } = this.props;
    const registerUrl = `/register${queryString}`;
    const googleUrl =
      process.env.REACT_APP_ADMIN_URL +
      `/year-glance/auth/sso/google?redirect_url=` +
      process.env.REACT_APP_BASE_URL +
      `/callback?${idUrl ? `idUrl=${idUrl}&` : ``}mode=jwt`;
    
    return (
      <div>
        <Container
          component="main"
          maxWidth="xs"
          style={{ height: "fit-content" }}
        >
          <CssBaseline />

          <div className={classes.paper}>
            {process.env.REACT_APP_CUSTOM_NODE_ENV === "staging" ? (
              <Typography component="h6" variant="h4">
                Year Glance Staging Server
              </Typography>
            ) : process.env.NODE_ENV === "production" ? (
              <img
                src="../assets/img/YG-Logo.png"
                alt="yg_logo"
                height="80px"
              />
            ) : (
              <Typography component="h6" variant="h4">
                Year Glance Development Server
              </Typography>
            )}

            <Typography component="h6" variant="h4">
              {idUrl ? "Login & Pay" : "Login"}
            </Typography>
            <ValidatorForm
              className={classes.form}
              onSubmit={this.handleSubmit}
            >
              <TextValidator
                className={classes.input1}
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={this.handleChange}
                type="email"
                autoFocus
                InputProps={{ classes: { root: classes.inputRoot } }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused,
                  },
                }}
                value={email}
                validators={["required", "isEmail"]}
                errorMessages={[
                  "Email Field is Required",
                  "Email is Not Valid",
                ]}
              />

              <TextValidator
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={this.handleChange}
                InputProps={{ classes: { root: classes.inputRoot } }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused,
                  },
                }}
                value={password}
                validators={["required"]}
                errorMessages={["Password Field is Required"]}
              />
              <Grid container>
                <Grid item></Grid>
                <Grid item xs>
                  <Link
                    href="/forgotpassword"
                    onClick={this.forgetPassword}
                    variant="h5"
                  >
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href={registerUrl} variant="h5">
                    {"Don't have an account? Register"}
                  </Link>
                  {process.env.NODE_ENV === "production" ? (
                    <span></span>
                  ) : (
                    <div>
                      <Link href="/register?id=2" variant="h5">
                        {"Register & Pay"}
                      </Link>
                    </div>
                  )}
                </Grid>
                {/* Tawk_API */}
                <Grid item style={{ marginTop: "20px" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<a href="javascript:void(window.showTawk('verify'))" style="color:#3f51b5;"><h5 style="font-weight:400; line-height:1.334">Have not received your verification email?</h5></a>
									`,
                    }}
                  ></div>
                </Grid>
              </Grid>
              <h5 style={{ display: "flex", justifyContent: "center" }}>
                {idUrl
                  ? `You are choosing ${
                      this.state.packageData ? this.state.packageData.name : ""
                    } plan`
                  : ""}
              </h5>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {loading.message === "true" ? (
                  <CircularProgress />
                ) : (
                  <Button
                    type="submit"
                    style={{ width: "240px", height: "50px" }}
                    sx={{
                      height: "35px",
                      fontSize: "15px",
                      margin: "6% 0px 3%",
                    }}
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Login
                  </Button>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 12 + "px",
                  marginBottom: 3 + "%",
                }}
              >
                OR
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 12 + "px",
                  marginBottom: 3 + "%",
                }}
              >
                <a href={googleUrl}>
                  <GoogleButton type="light" />
                </a>
              </div>
            </ValidatorForm>
          </div>
          <Box mt={2}>
            <CopyrightPage />
          </Box>
        </Container>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
};

function mapState(state) {
  const { loggingIn } = state.authentication;
  const { loading } = state;
  return { loggingIn, loading };
}

const actionCreators = {
  login: userActions.login,
  loginAndPay: userActions.loginAndPay,
  logout: userActions.logout,
  clearLoading: loadingActions.clearLoading,
  load: loadingActions.load,
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
const connectAll = withStyles(styles, { withTheme: true })(connectedLoginPage);
export { connectAll as LoginPage };
