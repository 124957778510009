/*
 * changes made :
 * import change - from material-ui to mui
 * adjust the style changes in the mui component
 */
import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../_actions';
import { CopyrightPage } from '../Menu/footer';
import PropTypes from 'prop-types';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { loadingActions } from '../_actions/loading.actions';
import * as config from '../constants/resource';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { withStyles } from '@mui/styles';
import {
	CircularProgress,
	Button,
	CssBaseline,
	Typography,
	Container,
	Box,
	Link,
	Grid,
	Avatar,
} from '@mui/material';
import CheckboxWithValidation from '../_components/Checkbox';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: 35,
    fontSize: 15
  },
  inputRoot: {
    fontSize: 17
  },
  labelRoot: {
    fontSize: 17,

  },
  logo: {
    width: 25,
    height: 25,
  },
  formcontrol: {
    fontSize: 20
  }

});

// let idUrl = window.location.search.substring(window.location.search.length-1, window.location.search.length);
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const idUrl = urlParams.get('id');

class SignUpPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        city: '',
        country: '',
        industry: '',
        role: '',
        organisation: '',
        website: '',
        tac: false,
      },
      code: '',
      submitted: false,
      publish_key: process.env.REACT_APP_PUBLISH_KEY,
      packageData: null,
      error: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeLifetimeDealCode = this.handleChangeLifetimeDealCode.bind(this);

    this.props.clearLoading();

  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value
      }
    });
  }

  handleChangeCheckbox = (name, checked) => {
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: checked,
      },
    });
  };

  handleChangeLifetimeDealCode(event) {
    const value = event.target.value;
    this.setState({ code: value })

  }

  async handleSubmit(event) {
    event.preventDefault();

    this.setState({ submitted: true });
    this.props.load(true);
    var checkCode = false;
    var voucher = null;

    if (this.state.code) {
      const requestOptions = {
        method: 'GET',
      };

      await fetch(`${config.URL}/items/vouchers?filter[code][eq]=${this.state.code}`, requestOptions).then(function (response) {
        return response.json();
      })
        .then(data => {
          if (data.data.length > 0) {
            if (data.data[0].used === false) {
              checkCode = true;
              voucher = data.data[0];
              this.setState({ error: "" })
            } else {
              this.setState({ error: "This code has already been redeemed." })
              this.props.clearLoading();
            }
          } else {
            this.setState({ error: "There is a problem with that code. Try a different one." })
            this.props.clearLoading();
          }
        })
    }

    if (window.location.pathname !== "/appsumo") {
      checkCode = true;
    }

    const { user } = this.state;
    if (user.first_name && user.last_name && user.email && user.password && checkCode && user.tac) {
      if (idUrl) {
        this.props.registerAndPay(user, this.state.packageData.stripe_price_id, idUrl, this.state.packageData.length === 0 ? 'payment' : 'subscription');
      }
      else {
        if (window.location.pathname === "/appsumo") {
          this.props.registerWithVoucher(user, voucher);
        } else {
          this.props.register(user);
        }

      }
    }
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('isTruthy', value => value);

    if (idUrl) {
      const requestOptions = {
        method: 'GET',
      };

      return fetch(`${config.URL}/items/subscriptions?filter[id][eq]=${idUrl}&filter[status][eq]=published`, requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(data => {
          if (data.data.length !== 0) {
            this.setState({ packageData: data.data[0] });
          }
          else {
            window.location.replace('/register');
          }
        })
        .then(error => error ? window.location.replace('/register') : '');
    }
  }

  render() {
    const { classes, loading } = this.props;
    const { user, submitted } = this.state;
    const loginUrl = `/login${queryString}`

    return (
      <div>

        <Container component="main" maxWidth="sm" style={{ height: "fit-content" }}>
          <CssBaseline />

          <div className={classes.paper}>
            <Avatar className={classes.avatar} sx={{ bgcolor: '#f50057' }}>
              <LockOutlinedIcon className={classes.logo} sx={{ width: '25px', height: '25px'}} />
            </Avatar>
            <Typography component="h6" variant="h4">
              {idUrl ? "Register & Pay" : "Register"}
            </Typography>
            <ValidatorForm className={classes.form} ref="form" onSubmit={this.handleSubmit} >
                  <TextValidator className={classes.input1}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="first_name"
                    label="*First Name"
                    name="first_name"
                    autoComplete="first_name"
                    onChange={this.handleChange}
                    autoFocus
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused
                      }
                    }}
                    value={user.first_name}
                    validators={
                      ['required']
                    }
                    errorMessages={['First Name Field is Required']}
                  />
                  <TextValidator className={classes.input1}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="last_name"
                    label="*Last Name"
                    name="last_name"
                    autoComplete="last_name"
                    onChange={this.handleChange}
                    autoFocus
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused
                      }
                    }}
                    value={user.last_name}
                    validators={
                      ['required']
                    }
                    errorMessages={['Last Name Field is Required']}
                  />
                  <TextValidator className={classes.input1}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="email"
                    label="*Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={this.handleChange}
                    type="email"
                    autoFocus
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused
                      }
                    }}
                    value={user.email}
                    validators={
                      ['required', 'isEmail']
                    }
                    errorMessages={['Email Field is Required', 'Email is Not Valid ']}
                  />
                  <TextValidator
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="password"
                    label="*Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={this.handleChange}
                    InputProps={{ classes: { root: classes.inputRoot } }}
                    InputLabelProps={{
                      classes: {
                        root: classes.labelRoot,
                        focused: classes.labelFocused
                      }
                    }}
                    value={user.password}
                    validators={
                      ['required']
                    }
                    errorMessages={['Password Field is Required']}
                  />
              {window.location.pathname != "/appsumo" ? <h5 style={{ display: "flex", justifyContent: "center" }}>{idUrl ? `You are choosing ${this.state.packageData ? this.state.packageData.name : ''} plan` : "Premium Trial will be active for 14 days"}</h5>
                : ''}
              {window.location.pathname === "/appsumo"
                ?
                <div style={{ marginTop: "20px" }}>
                  <h5>Add Voucher Code</h5>
                  <input className="form-control" type="text" name="code" placeholder="Appsumo code" onChange={(e) => this.handleChangeLifetimeDealCode(e)} />
                  {this.state.error ? <div style={{ color: "red", fontSize: "12px" }}>{this.state.error}</div> : ''}
                </div>
                : ''
              }


              <div style={{ display: 'flex', alignItems: 'center', marginTop: "30px" }}>
                <CheckboxWithValidation
                  name="tac"
                  checked={user.tac}
                  onChange={() => this.handleChangeCheckbox("tac", !user.tac)}
                  value={user.tac}
                  validators={['isTruthy']}
                  errorMessages={['Please accept the Privacy Policy and Terms and Conditions']}
                />
                <span style={{ fontSize: '14px' }}>
                  I agree to the <a href="https://yearglance.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a href="https://yearglance.com/terms-of-service" target="_blank" rel="noopener noreferrer">
                    Terms and Conditions
                  </a>
                </span>
              </div>

              <div
                style={{
                  display: "flex", justifyContent: "center", alignItems: "center"
                }}
              >

                {loading.message === "true" ? <CircularProgress /> :
                  idUrl ?
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      sx={{ margin: '8% 0% 6%', fontSize: '15px', height: '35px' }}
                    >
                      Register & Pay
                    </Button>
                    :
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      sx={{ margin: '8% 0% 6%', fontSize: '15px', height: '35px' }}
                    >
                      Register
                    </Button>
                }
              </div>
              <Grid container>
                <Grid item>
                  <Link href={loginUrl} variant="h5">
                    {"Already have an account? Login"}
                  </Link>
                </Grid>
              </Grid>
            </ValidatorForm>
          </div>

          <Box mt={2}>
            <CopyrightPage />
          </Box>
        </Container>
      </div>
    );
  }
}

SignUpPage.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
};

function mapState(state) {
  const { registering } = state.registration;
  const { loading } = state;
  return { registering, loading };
}

const actionCreators = {
  registerAndPay: userActions.registerAndPay,
  register: userActions.register,
  registerWithVoucher: userActions.registerWithVoucher,
  clearLoading: loadingActions.clearLoading,
  load: loadingActions.load
};

const connectedSignUpPage = connect(mapState, actionCreators)(SignUpPage);
const connectAll = withStyles(styles, { withTheme: true })(connectedSignUpPage)
export { connectAll as SignUpPage };