// import config from 'config';
import { authHeader } from '../_helpers';
import { i18n } from '../constants/i18n';
import * as config from '../constants/resource';
import { localStorageFunction, defaultValue } from '../_helpers';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
export const userService = {
	login,
	loginToken,
	loginCollection,
	logout,
	getAll,
	getById,
	register,
	registerWithVoucher,
	registerCollection,
	setupUserSetting,
	registerCollectionWithVoucher,
	registerGoogle,
	registerGoogleCollection,
	getTokenAdmin,
	getToken,
	changePermission,
	confirmVerification,
	forgotPassword,
	resetPassword,
	update,
	delete: _delete,
	loginGoogle,
	resendToken,
	checkActive,
	checkTokenExpired,
	changeLastLogin,
	refreshToken,
	broadcast_one,
	broadcast_all,
	HelpingCreateAccount,
	checkExpiredLeft,
	CollectionData
};

let currentURL = window.location.href;
let isRunningLocally = false;

if(currentURL.includes("localhost")) {
  isRunningLocally = true;
}

var token, id_user;

function login(email, password) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ email, password })
	};

	return fetch(`${config.URL}/auth/authenticate`, requestOptions)

		.then(handleResponse)
		.then(userdata => {
			localStorage.clear();
			localStorage.setItem('yearglance', JSON.stringify(userdata));
			return userdata;
		});
}

function loginToken(token) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
	};

	return fetch(`${config.URL}/users`, requestOptions)

		.then(handleResponse)
		.then(userdata => {
			localStorage.clear();
			localStorage.setItem('yearglance', JSON.stringify({ data: { token: token, user: userdata.data[0] } }));
			return { data: { token: token, user: userdata.data[0] } };
		});
}

function changeLastLogin() {
	let user = JSON.parse(localStorage.getItem('user_data'));

	const id = user.id;
	const body = {
		"last_login": null
	}

	const requestOptions = {
		method: 'PATCH',
		headers: authHeader(),
		body: JSON.stringify(body)
	};

	const expiredDate = new Date();
	expiredDate.setMinutes(expiredDate.getMinutes() + 20);


	//filter document: https://docs.directus.io/api/query/filter.html
	return fetch(`${config.URL}/items/user_setting/${id}`, requestOptions)
		.then(handleResponse)
		.then(userdata => {
			localStorage.setItem('token_expired', expiredDate);
			return userdata;
		});

}

function CollectionData(userdata) {
	const lang = userdata ? userdata.lang : 'en';
	i18n(lang);

	localStorageFunction(userdata);
	
	if (userdata.microsoft != null) {
		if (userdata.microsoft.length !== 0) {
			for (var i in userdata.microsoft) {
				localStorage.setItem(i, userdata.microsoft[i]);
			}
		}
	}

	const requestOptions2 = {
		method: 'GET',
		headers: authHeader()
	};

	var link;
	if (userdata.onboarding === null) {
		link = `${config.URL}/items/onboarding?filter[status][eq]=published&[order][neq]=0&sort=order`;
	}
	else {
		link = `${config.URL}/items/onboarding?filter[id][nin]=${userdata.onboarding}&[status][eq]=published&[order][neq]=0&sort=order`
	}
	return fetch(link, requestOptions2)
		.then(function (response) {
			return response.json();
		})
		.then(dataOnboarding => {
			window.firstOnboard = 1;
			if (dataOnboarding.data.length > 0) {
				dataOnboarding.data[0].disableBeacon = true;
				dataOnboarding.data.map((data, index) => {
					if (data.target === '.day-today') {
						if (userdata.options.orientation === 'h') {
							dataOnboarding.data[index].target = '.horizontal-today';
						}
						else if (userdata.options.orientation === 'v') {
							dataOnboarding.data[index].target = '.vertical-today';
						}
					}

					return null;
				})
			}
			window.dataOnboarding = dataOnboarding.data;
		});
}

function HelpingCreateAccount(user) {
	const options = {
		"soade": false,
		"cw": 40,
		"pbb": false,
		"hh": false,
		"year": new Date().getFullYear(),
		"orientation": "h",
		"abd_flag": false,
		"hdn": false,
	}


	const subscriptionExpiry = new Date();
	subscriptionExpiry.setDate(subscriptionExpiry.getDate() + 14);
	let date = subscriptionExpiry.getDate();
	let month = subscriptionExpiry.getMonth() + 1;
	let year = subscriptionExpiry.getFullYear();

	if (date < 10) {
		date = "0" + date;
	}
	if (month < 10) {
		month = "0" + month;
	}

	let hours = subscriptionExpiry.getHours();
	let minutes = subscriptionExpiry.getMinutes();
	let seconds = subscriptionExpiry.getSeconds();

	if (hours < 10) {
		hours = "0" + hours;
	}

	if (minutes < 10) {
		minutes = "0" + minutes;
	}
	if (seconds < 10) {
		seconds = "0" + seconds;
	}

	const subExpiry = year + "-" + month + "-" + date + "T" + hours + ":" + minutes + ":" + seconds + "+00:00";

	const body = {
		"status": "published",
		"title": "My Year Planner",
		"user": user.data.user.id,
		"options": options,
		"subscription": 5,
		"subscription_expiry": subExpiry
	}

	const requestOptions = {
		method: 'POST',
		headers: { 'Authorization': 'Bearer ' + user.data.token, 'Content-Type': 'application/json' },
		body: JSON.stringify(body)
	};

	return fetch(`${config.URL}/items/user_setting`, requestOptions)
		.then(handleResponse)
		.then(userdata => {
			CollectionData(userdata.data);
		});
}

function loginCollection(user) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};


	//filter document: https://docs.directus.io/api/query/filter.html
	return fetch(`${config.URL}/items/user_setting?filter[user]=${user.data.user.id}&fields=*,subscription.*`, requestOptions)
		.then(handleResponse)
		.then(userdata => {
			return userdata;
		});
}

function checkExpiredLeft(userdata) {
	return new Promise((resolve, reject) => {
		let subscription_expiry = userdata.data[0].subscription_expiry;
		const userSettingId = userdata.data[0].id;

		let date = new Date(subscription_expiry);
		let expiry = new Date(`${new Date().getFullYear()}-${`${new Date().getMonth() + 1}`.padStart(2, 0)}-${`${new Date().getDate()}`.padStart(2, 0)}`);
		let timeDiff = (date.getTime() - expiry.getTime());
		let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

		if (diffDays <= 0) {
			const requestOptions = {
				method: 'PATCH',
				headers: authHeader(),
				body: defaultValue(userdata.data[0])
			};

			fetch(`${config.URL}/items/user_setting/` + userSettingId + `?fields=*,subscription.*`, requestOptions)

				.then(handleResponse)
				.then(data => {
					CollectionData(data.data);
					resolve(data);
				})
		}
		else {
			CollectionData(userdata.data[0]);
			resolve(userdata);
		}
	})
}

function logout() {
	// remove user from local storage to log user out
	localStorage.clear();
}

function getAll() {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(`${config.URL}/users`, requestOptions).then(handleResponse);
}

function getById(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	};

	return fetch(`${config.URL}/users/${id}`, requestOptions).then(handleResponse);
}


function register(user) {
	const body = {
		"params": "registration",
	}

	var email = user.email;
	var password = user.password;
	var first_name = user.first_name;
	var last_name = user.last_name;

	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body)
	};

	// `/plugin/check_user.php?email=${email}&password=${password}&first_name=${first_name}&last_name=${last_name}&status=active&register=google`

	return fetch(`/plugin/check_user.php?email=${email}&password=${password}&first_name=${first_name}&last_name=${last_name}&status=draft`, requestOptions)
		.then(handleResponse)
		.then(data => {

		});
}

async function registerWithVoucher(user) {
	var email = user.email;
	var encodedEmail = encodeURIComponent(email); 
	var password = user.password;
	var first_name = user.first_name;
	var last_name = user.last_name;

	const requestOptions = {
	  method: 'POST',
	  redirect: 'follow'
	};

	let baseUrl = '';

	if(isRunningLocally) {
	  baseUrl = 'http://localhost:8080';
	}

	return await fetch(`${baseUrl}/plugin/check_user.php?email=${encodedEmail}&password=${password}&first_name=${first_name}&last_name=${last_name}&status=active&register=google`, requestOptions)
		.then(handleResponse)
		.then(userdata => {
			return userdata;
		});

}

async function registerCollectionWithVoucher(voucher, data) {
	const options = {
		"soade": false,
		"cw": 40,
		"pbb": false,
		"hh": false,
		"year": new Date().getFullYear(),
		"orientation": "h",
		"abd_flag": false,
		"hdn": false
	}

	const subscriptionExpiry = new Date();
	subscriptionExpiry.setDate(subscriptionExpiry.getDate() + 14);
	let date = subscriptionExpiry.getDate();
	let month = subscriptionExpiry.getMonth() + 1;
	let year = subscriptionExpiry.getFullYear();

	if (date < 10) {
		date = "0" + date;
	}
	if (month < 10) {
		month = "0" + month;
	}

	let hours = subscriptionExpiry.getHours();
	let minutes = subscriptionExpiry.getMinutes();
	let seconds = subscriptionExpiry.getSeconds();

	if (hours < 10) {
		hours = "0" + hours;
	}

	if (minutes < 10) {
		minutes = "0" + minutes;
	}
	if (seconds < 10) {
		seconds = "0" + seconds;
	}

	const subExpiry = year + "-" + month + "-" + date + "T" + hours + ":" + minutes + ":" + seconds + "+00:00";

	const body = {
		"status": "published",
		"user": id_user,
		"title": "My Year Planner",
		"options": options,
		"subscription": voucher.subscriptions,
		"subscription_status": "succeeded",
		"city": data.city,
		"country": data.country,
		"industry": data.industry,
		"role": data.role,
		"organisation": data.organisation,
		"website": data.website
	}

	const requestOptions = {
		method: 'POST',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
		body: JSON.stringify(body)
	};

	await fetch(`${config.URL}/items/user_setting`, requestOptions)
		.then(handleResponse)
	const body2 = {
		"used": true,
		"used_date": moment().format("YYYY-MM-DD"),
		"user": id_user,
		"show_public": false,
		"public_calendar_key": uuidv4(),
	}

	const requestOptions2 = {
		method: 'PATCH',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
		body: JSON.stringify(body2)
	};

	return fetch(`${config.URL}/items/vouchers/${voucher.id}`, requestOptions2)
		.then(handleResponse)
		.then(userdata => {
			return userdata;
		});

}

async function setupUserSetting(data) {
	const options = {
		"soade": false,
		"cw": 40,
		"pbb": false,
		"hh": false,
		"year": new Date().getFullYear(),
		"orientation": "h",
		"abd_flag": false,
		"hdn": false
	}

	const subscriptionExpiry = new Date();
	subscriptionExpiry.setDate(subscriptionExpiry.getDate() + 14);
	let date = subscriptionExpiry.getDate();
	let month = subscriptionExpiry.getMonth() + 1;
	let year = subscriptionExpiry.getFullYear();

	if (date < 10) {
		date = "0" + date;
	}
	if (month < 10) {
		month = "0" + month;
	}

	let hours = subscriptionExpiry.getHours();
	let minutes = subscriptionExpiry.getMinutes();
	let seconds = subscriptionExpiry.getSeconds();

	if (hours < 10) {
		hours = "0" + hours;
	}

	if (minutes < 10) {
		minutes = "0" + minutes;
	}
	if (seconds < 10) {
		seconds = "0" + seconds;
	}

	const subExpiry = year + "-" + month + "-" + date + "T" + hours + ":" + minutes + ":" + seconds + "+00:00";

	const body = {
		"status": "published",
		"title": "My Year Planner",
		"user": id_user,
		"options": options,
		"subscription": 5,
		"subscription_expiry": subExpiry,
		"city": data.city,
		"country": data.country,
		"industry": data.industry,
		"role": data.role,
		"organisation": data.organisation,
		"website": data.website
	}

	const requestOptions = {
		method: 'POST',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
		body: JSON.stringify(body)
	};

	return fetch(`${config.URL}/items/user_setting`, requestOptions)
		.then(handleResponse)
		.then(userdata => {
			// id_user_register = userdata.data.id;
		});
}

function registerCollection(user) {
	const options = {
		"soade": false,
		"cw": 40,
		"pbb": false,
		"hh": false,
		"year": new Date().getFullYear(),
		"orientation": "h",
		"abd_flag": false,
		"hdn": false,
	}


	const subscriptionExpiry = new Date();
	subscriptionExpiry.setDate(subscriptionExpiry.getDate() + 14);
	let date = subscriptionExpiry.getDate();
	let month = subscriptionExpiry.getMonth() + 1;
	let year = subscriptionExpiry.getFullYear();

	if (date < 10) {
		date = "0" + date;
	}
	if (month < 10) {
		month = "0" + month;
	}

	let hours = subscriptionExpiry.getHours();
	let minutes = subscriptionExpiry.getMinutes();
	let seconds = subscriptionExpiry.getSeconds();

	if (hours < 10) {
		hours = "0" + hours;
	}

	if (minutes < 10) {
		minutes = "0" + minutes;
	}
	if (seconds < 10) {
		seconds = "0" + seconds;
	}

	const subExpiry = year + "-" + month + "-" + date + "T" + hours + ":" + minutes + ":" + seconds + "+00:00";

	const body = {
		"status": "published",
		"title": "My Year Planner",
		"user": user.id_user,
		"options": options,
		"subscription": 5,
		"subscription_expiry": subExpiry,
		"city": user.city,
		"country": user.country,
		"industry": user.industry,
		"role": user.role,
		"organisation": user.organisation,
		"website": user.website
	}

	const requestOptions = {
		method: 'POST',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
		body: JSON.stringify(body)
	};

	return fetch(`${config.URL}/items/user_setting`, requestOptions)
		.then(handleResponse)
		.then(userdata => {
			return userdata;
		});
}

function loginGoogle(result) {

	const requestOptions = {
		method: 'GET',
		headers: { 'Authorization': 'Bearer ' + result.data.token, 'Content-Type': 'application/json' }
	};

	return fetch(`${config.URL}/users/me`, requestOptions)
		// return fetch(`${config.URL}/items/user_setting`, requestOptions)
		.then(handleResponse)
		.then(userAuth => {
			localStorage.clear();
			localStorage.setItem('yearglance', JSON.stringify({ data: { token: result.data.token, user: userAuth.data } }));

			const requestOptions = {
				method: 'GET',
				headers: { 'Authorization': 'Bearer ' + result.data.token, 'Content-Type': 'application/json' }
			};

			return fetch(`${config.URL}/items/user_setting?filter[user]=${userAuth.data.id}&fields=*,subscription.*`, requestOptions)
				.then(handleResponse)
				.then(userdata => {
					// CollectionData(userdata.data[0]);
					return userdata;
				});
		});
}

async function registerGoogle(email, password) {

	var first_name = email;
	var last_name = email;

	const requestOptions = {
		method: 'POST',
		redirect: 'follow'
	};

	let baseUrl = '';

	if(isRunningLocally) {
		baseUrl = 'http://localhost:8080';
	}

	return await fetch(`${baseUrl}/plugin/check_user.php?email=${email}&password=${password}&first_name=${first_name}&last_name=${last_name}&status=active&register=google`, requestOptions)
		.then(handleResponse)
		.then(data => {
		});
}

function registerGoogleCollection() {
	const options = {
		"soade": false,
		"cw": 40,
		"pbb": false,
		"hh": false,
		"year": new Date().getFullYear(),
		"orientation": "h",
		"abd_flag": false,
		"hdn": false
	}

	const subscriptionExpiry = new Date();
	subscriptionExpiry.setDate(subscriptionExpiry.getDate() + 14);
	let date = subscriptionExpiry.getDate();
	let month = subscriptionExpiry.getMonth() + 1;
	let year = subscriptionExpiry.getFullYear();

	if (date < 10) {
		date = "0" + date;
	}
	if (month < 10) {
		month = "0" + month;
	}

	let hours = subscriptionExpiry.getHours();
	let minutes = subscriptionExpiry.getMinutes();
	let seconds = subscriptionExpiry.getSeconds();

	if (hours < 10) {
		hours = "0" + hours;
	}

	if (minutes < 10) {
		minutes = "0" + minutes;
	}
	if (seconds < 10) {
		seconds = "0" + seconds;
	}

	const subExpiry = year + "-" + month + "-" + date + "T" + hours + ":" + minutes + ":" + seconds + "+00:00";

	const body = {
		"status": "published",
		"user": id_user,
		"title": "My Year Planner",
		"options": options,
		"subscription": 5,
		"subscription_expiry": subExpiry
	}

	const requestOptions = {
		method: 'POST',
		headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' },
		body: JSON.stringify(body)
	};

	return fetch(`${config.URL}/items/user_setting`, requestOptions)
		.then(handleResponse)
		.then(userdata => {
		});
}

function getTokenAdmin() {

	const body = {
		"params": "verification",
	}

	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body)
	};

	return fetch(process.env.REACT_APP_BASE_URL + `/plugin/verification.php`, requestOptions)
		.then(handleResponse)
		.then(userdata => {
			token = userdata.data.token;
			return userdata;
		})

}


function getToken(email, password) {

	const body = {
		"email": email,
		"password": password
	}

	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body)
	};

	return fetch(`${config.URL}/auth/authenticate`, requestOptions)

		.then(handleResponse)
		.then(userdata => {
			token = userdata.data.token;
			id_user = userdata.data.user.id;
			return userdata;
		});
}

function changePermission(status) {
	const body = {
		"params": "changePermission",
	}

	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body)
	};

	let baseUrl = '';

	if(isRunningLocally) {
		baseUrl = 'http://localhost:8080';
	}	

	return fetch(`${baseUrl}/plugin/change_permission.php?status=${status}`, requestOptions);
}


function confirmVerification(user) {
	const body = {
		"status": "active"
	}

	const requestOptions = {
		method: 'PATCH',
		headers: { 'Authorization': 'Bearer ' + user.token, 'Content-Type': 'application/json' },
		body: JSON.stringify(body)
	};

	return fetch(`${config.URL}/users/${user.id_user}`, requestOptions)

		.then(handleResponse)
		.then(userdata => {
			return userdata;
		});
}

function forgotPassword(user) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ "email": user.email, "reset_url": process.env.REACT_APP_BASE_URL + "/resetpassword" })
	};

	return fetch(`${config.URL}/auth/password/request`, requestOptions).then(handleResponse);
}

function resetPassword(user) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ "password": user.password, token: user.token })
	};

	return fetch(`${config.URL}/auth/password/reset`, requestOptions).then(handleResponse);
}


function checkTokenExpired(user) {

	const body = {
		"params": "checkToken",
	}
	var email = user.email;
	var token = user.token;

	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body)
	};

	return fetch(`/plugin/check_token_expired.php?email=${email}&token=${token}`, requestOptions)
		.then(handleResponse)
		.then(userdata => {
			return userdata;
		});
}

function resendToken(user) {
	const body = {
		"params": "resendtoken",
	}

	var email = user.email;
	var password = user.password;

	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body)
	};

	return fetch(`/plugin/resend_token.php?email=${email}&password=${password}`, requestOptions)
		.then(handleResponse)
		.then(data => {

		});
}

function refreshToken(token) {

	const body = {
		"token": token
	}

	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body)
	};

	return fetch(`${config.URL}/auth/refresh`, requestOptions)

		.then(handleResponse)
		.then(userdata => {

			localStorage.setItem('yearglance', JSON.stringify(userdata));
		});
}


function checkActive(user) {
	const body = {
		"params": "checkUser",
	}
	var email = user.email;
	
	let baseUrl = '';
	if(isRunningLocally) {
		baseUrl = 'http://localhost:8080';
	}

	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body)
	};

	return fetch(`${baseUrl}/plugin/check_user_active.php?email=${email}`, requestOptions)
		.then(handleResponse)
		.then(userdata => {
			return userdata;
		});
}

function broadcast_one(user) {

	const body = {
		"params": "registration",
	}

	var email = user.email;

	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body)
	};

	return fetch(`/plugin/send_reset_password.php?email=${email}`, requestOptions)
		.then(handleResponse);

}

function broadcast_all() {

	const body = {
		"params": "registration",
	}


	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body)
	};

	return fetch(`/plugin/broadcast_reset_password.php`, requestOptions)
		.then(handleResponse);

}

function update(user) {
	const requestOptions = {
		method: 'PUT',
		headers: authHeader(),
		body: JSON.stringify(user)
	};

	return fetch(`${config.URL}/users/${user.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader()
	};

	return fetch(`${config.URL}/users/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				// auto logout if 401 response returned from api
				logout();
				window.location.reload(true);
			}

			let error = (data.error && data.error.message) || response.statusText;

			if (data.error.code === 100) {
				error = "Login Failed";

			} else if (data.error.code === 204) {
				error = "This email address has been taken by another account";
			}
			return Promise.reject(error);
		}

		if (data === "user-exist") {
			return Promise.reject("This email address has been taken by another account");
		}

		if (data === "active") {
			return Promise.reject("You have verified your account");
		}

		return data;
	});
}