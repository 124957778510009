/*
* changes made :
* import change - from material-ui to mui
* adjust the style changes in the mui component
*/
import React from 'react';
import * as config from '../constants/resource';
import { useSnackbar } from 'notistack';
import { authHeader } from '../_helpers';
import i18next from "i18next";
import { save_log } from '../constants/saveLog';
import LifetimeDealPage from './LifetimeDealPage';
import { makeStyles, withStyles } from '@mui/styles';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {
  IconButton,
  MenuItem,
  Menu,
  CircularProgress,
  Dialog,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  texttitle: {
    fontSize: 20 + "px",
  },
  textbody: {
    fontSize: 15 + "px",
  },
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  customButton: {
    fontSize: 15 + "px",
    backgroundColor: "#5c90d2",
    color: "white",
  },
  customTheme: {
    fontSize: 20 + "px"
  },
  inputLabel: {
    marginTop: "-11px",
    '&.MuiFormLabel-root.MuiInputLabel-shrink': {
      marginTop: "0px !important",
      display: "none"
    }
  },
  selectData: {
    '& legend': {
      display: "none"
    }
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    display: 'flex',
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Grid container spacing={0}>
        <Grid item xs={11}>
          <Typography variant="h6" style={{ flex: 1 }}>{children}</Typography>
        </Grid>
        <Grid item xs={1}>
          {onClose ? (
            <IconButton aria-label="close" sx={{ marginLeft: '26px', marginTop: '-3px'}} className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function MenuAppBar(props) {
  const localData = JSON.parse(localStorage.getItem('yearglance'));
  const userData = JSON.parse(localStorage.getItem('user_data'));
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [subloading, setsubLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openModal, setOpen] = React.useState(false);
  const [firstName, setFirstName] = React.useState(localData.data.user.first_name || '');
  const [lastName, setLastName] = React.useState(localData.data.user.last_name || '');
  const [email, setEmail] = React.useState(localData.data.user.email || '');
  const [password, setPassword] = React.useState(null);
  const [confirmPassword, setConfirmPassword] = React.useState(null);
  const [city, setCity] = React.useState(userData.city || '');
  const [country, setCountry] = React.useState(userData.country || '');
  const [industry, setIndustry] = React.useState(userData.industry || '');
  const [role, setRole] = React.useState(userData.role || '');
  const [organisation, setOrganisation] = React.useState(userData.organisation || '');
  const [website, setWebsite] = React.useState(userData.website || '');
  const [deleteWord, setDeleteWord] = React.useState(null);
  const [errorDeleteWord, setErrorDeleteWord] = React.useState(false);
  const token = localData.data.token;
  const id_user = localData.data.user.id;
  const [errorPassword, setErrorPassword] = React.useState(false);
  const [industryOptions, setIndustryOptions] = React.useState([]);
  const [roleOptions, setRoleOptions] = React.useState([]);

  const [subscriptionStatus, setsubscriptionStatus] = React.useState(JSON.parse(localStorage.getItem('user_data')).subscription_status);
  const [user_settingId] = React.useState(JSON.parse(localStorage.getItem('user_data')).id);

  const [Profile] = React.useState(i18next.t('Pages.menu.Profile'));
  const [Refer_Your_Friends] = React.useState(i18next.t('Pages.menu.Refer_Your_Friends'));
  const [Logout] = React.useState(i18next.t('Pages.menu.Logout'));
  const [Name] = React.useState(i18next.t('Pages.profile.Name'));
  const [Email] = React.useState(i18next.t('Pages.profile.Email'));
  const [Password] = React.useState(i18next.t('Pages.profile.Password'));
  const [Your_Package] = React.useState(i18next.t('Pages.profile.Your_Package'));
  const [Language] = React.useState(i18next.t('Pages.profile.Language'));
  const [English] = React.useState(i18next.t('Pages.profile.English'));
  const [valid_until] = React.useState(i18next.t('Pages.profile.valid_until'));
  const [Cancel_Subscription] = React.useState(i18next.t('Pages.profile.Cancel_Subscription'));
  const [Save] = React.useState(i18next.t('Pages.profile.Save'));
  const [Are_you_sure_you_want_to_cancel_the_subscription] = React.useState(i18next.t('Pages.profile.Are_you_sure_you_want_to_cancel_the_subscription'));
  const [I_am_sure] = React.useState(i18next.t('Pages.profile.I_am_sure'));
  const [The_new_password_and_confirmation_password_do_not_match] = React.useState(i18next.t('Pages.profile.The_new_password_and_confirmation_password_do_not_match'));
  const [Delete_Account] = React.useState(i18next.t('Pages.profile.Delete_Account'));
  const [Are_you_sure_you_want_to_permanently_delete_your_account_and_all_your_data] = React.useState(i18next.t('Pages.profile.Are_you_sure_you_want_to_permanently_delete_your_account_and_all_your_data'));
  const [Your_subscription_will_also_be_canceled] = React.useState(i18next.t('Pages.profile.Your_subscription_will_also_be_canceled'));
  const [No] = React.useState(i18next.t('Pages.profile.No'));
  const [Type] = React.useState(i18next.t('Pages.profile.Type'));
  const [Please_match_the_requested_format] = React.useState(i18next.t('Pages.profile.Please_match_the_requested_format'));

  const [City] = React.useState(i18next.t('Pages.profile.City'));
  const [Country] = React.useState(i18next.t('Pages.profile.Country'));
  const [Industry] = React.useState(i18next.t('Pages.profile.Industry'));
  const [Role] = React.useState(i18next.t('Pages.profile.Role'));
  const [Organisation] = React.useState(i18next.t('Pages.profile.Organisation'));
  const [Website] = React.useState(i18next.t('Pages.profile.Website'));
  const [Support] = React.useState(i18next.t('Pages.menu.Support'));
  const [Tutorial] = React.useState(i18next.t('Pages.sideMenu.Tutorial'));
  const [Feature_requests] = React.useState(i18next.t('Pages.menu.Feature_requests'));
  const [Roadmap] = React.useState(i18next.t('Pages.menu.Roadmap'));

  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    getIndustryOptions();
    getRoleOptions();
  }, []);

  function getIndustryOptions() {
    var yearglance = JSON.parse(localStorage.getItem('yearglance'));

    const options = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + yearglance.data.token, 'Content-Type': 'application/json'
      }
    }

    fetch(`${config.URL}/fields/user_setting/industry`, options)
      .then(res => res.json())
      .then(res => {
        const industry_option = Object.entries(res.data.options.choices);
        setIndustryOptions(industry_option);
      }
      )
  }

  function getRoleOptions() {
    var yearglance = JSON.parse(localStorage.getItem('yearglance'));

    const options = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + yearglance.data.token, 'Content-Type': 'application/json'
      }
    }

    fetch(`${config.URL}/fields/user_setting/role`, options)
      .then(res => res.json())
      .then(res => {
        const role_options = Object.entries(res.data.options.choices);
        setRoleOptions(role_options);
      }
      )
  }


  function onSubmit(e) {

    e.preventDefault();
    setLoading(true);

    //check if password and confirmation password match
    if (password !== confirmPassword) {
      setErrorPassword(true);
      setLoading(false);
    } else {
      setErrorPassword(false);

      let body = {
        "first_name": firstName,
        "last_name": lastName,
        "email": email
      }

      //check if user insert new password
      if (password) {
        body["password"] = password;
      }

      let message;
      let variant;

      window
        .fetch(`${config.URL}/users/${id_user}`, {
          method: "PATCH",
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        })
        .then(function (response) {
          return response.json();
        })
        .then(userdata => {

          //success  
          if (userdata.data) {
            const newData = JSON.parse(localStorage.getItem('yearglance'));
            newData.data.user.first_name = firstName;
            newData.data.user.last_name = lastName;
            newData.data.user.email = email;
            localStorage.setItem('yearglance', JSON.stringify(newData));

            setLoading(false);

            let body = {
              "city": city,
              "country": country,
              "industry": industry,
              "role": role,
              "organisation": organisation,
              "website": website
            }

            window
              .fetch(`${config.URL}/items/user_setting/${user_settingId}`, {
                method: "PATCH",
                headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
              })
              .then(function (response) {
                return response.json();
              })
              .then(userdata => {
                //success  
                if (userdata.data) {
                  localStorage.setItem('user_data', JSON.stringify(userdata.data));

                  window.location.reload();
                  message = 'Your Profile is Changed';
                  variant = 'success';

                  //error
                } else if (userdata.error.code === 9) {
                  message = 'This email address has been taken by another account.';
                  variant = 'error';

                } else {
                  message = 'Failed to update your profile.';
                  variant = 'error';
                }

                setLoading(false);

                enqueueSnackbar({ message: { message : message, variant : variant } });

                if (variant !== 'error') {
                  handleCloseModal();
                  window.location.reload();
                }

              });

            //error
          } else if (userdata.error.code === 9) {
            message = 'This email address has been taken by another account.';
            variant = 'error';

            enqueueSnackbar({ message: { message : message, variant : variant } });

          } else {
            message = 'Failed to update your profile.';
            variant = 'error';

            enqueueSnackbar({ message: { message : message, variant : variant } });
          }

          setLoading(false);
        }
        );
    }
  };

  function onChange(event) {
    if (event.target.name === "first_name") {
      setFirstName(event.target.value);

    } else if (event.target.name === "last_name") {
      setLastName(event.target.value);

    } else if (event.target.name === "email") {
      setEmail(event.target.value);
    } else if (event.target.name === "password") {
      if (event.target.value === '') {
        setPassword(null);
      } else {
        setPassword(event.target.value);
      }

    } else if (event.target.name === "confirm_password") {
      if (event.target.value === '') {
        setConfirmPassword(null);
      } else {
        setConfirmPassword(event.target.value);
      }

    } else if (event.target.name === "deleteWord") {
      setDeleteWord(event.target.value);
    }
    else if (event.target.name === "city") {
      setCity(event.target.value);
    } else if (event.target.name === "country") {
      setCountry(event.target.value);
    } else if (event.target.name === "industry") {
      setIndustry(event.target.value);
    } else if (event.target.name === "role") {
      setRole(event.target.value);
    } else if (event.target.name === "organisation") {
      setOrganisation(event.target.value);
    } else if (event.target.name === "website") {
      setWebsite(event.target.value);
    }
  };

  async function logout(event) {
    await save_log(14);
    localStorage.clear();
    window.location.href = "/login";
  };

  function invitereferrals_26985() {
    // setAnchorEl(null);

    // var invite_referrals = window.invite_referrals || {}; (function () {
    //   invite_referrals.auth = {
    //     bid_e: '8199AEEF25AB14AC2FEB4569018634CB',
    //     bid: '31583',
    //     t: '420',
    //     email: JSON.parse(localStorage.getItem('yearglance')).data.user.email, userParams: { 'fname': JSON.parse(localStorage.getItem('yearglance')).data.user.first_name }
    //   };
    //   invite_referrals.async = false;
    //   var script = document.createElement('script');
    //   script.src = "//cdn.invitereferrals.com/js/invite-referrals-1.0.js";
    //   var entry = document.getElementsByTagName('script')[0]; entry.parentNode.insertBefore(script, entry);
    // })();

    // var params = { bid: 31583, cid: 26985 };
    // invite_referrals.widget.inlineBtn(params);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpenModal = () => {
    setOpen(true);
    setAnchorEl(null);
    setErrorPassword(false);
    setPassword(null);
    setConfirmPassword(null);
    setDeleteWord(null);
    setErrorDeleteWord(false);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  if (errorPassword) {
    if (password === confirmPassword) {
      setErrorPassword(false);
    }

    if (!password && !confirmPassword) {
      setErrorPassword(false);
    }
  }

  const cancelSubscription = (e) => {
    e.preventDefault();
    setsubLoading(true);

    let message;
    let variant;
    const requestOptions = {
      method: 'DELETE',
      headers: authHeader()
    };

    return fetch(`${config.URL}/custom/stripe/subscriptions`, requestOptions)

      .then(function (response) {
        return response.json();
      })
      .then(data => {
        localStorage.setItem('user_data', JSON.stringify(data.data));
        setLoading(false);
        save_log(7);
        message = 'Your Subscription Canceled';
        variant = 'success';

        setsubLoading(false);
        setsubscriptionStatus('cancelled');

        enqueueSnackbar({ message: { message : message, variant : variant } });
      });
  }

  const openDeleteAccountDialog = (e) => {
    setDeleteWord('');
    setErrorDeleteWord(false);
  }

  const deleteAccount = (e) => {
    e.preventDefault();

    if (deleteWord === "DELETE") {
      setLoading(true);
      cancelSubscription(e);

      const body = {
        "status": "suspended"
      }

      const requestOptions = {
        method: 'PATCH',
        headers: authHeader(),
        body: JSON.stringify(body)
      };

      return fetch(`${config.URL}/users/${id_user}`, requestOptions)
        .then(userdata => {
          save_log(8);
          var message = 'Your Account Deleted ';
          var variant = 'success';
          setLoading(false);
          
          enqueueSnackbar({ message: { message : message, variant : variant } });

          logout();
        });

    } else {
      setErrorDeleteWord(true);
    }

  }

  let subscriptionButton;
  let subscriptionNum = JSON.parse(localStorage.getItem('user_data')).subscription.name;
  let subExpiry = JSON.parse(localStorage.getItem('user_data')).subscription_expiry || '';

  if (subscriptionStatus === 'succeeded' && subExpiry !== '') {
    subscriptionButton =
      <div>
        <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#exampleModalCenter">
          {Cancel_Subscription}
        </button>

        <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">{Cancel_Subscription}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{ marginTop: -20 + 'px' }}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                {Are_you_sure_you_want_to_cancel_the_subscription}?
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal">{No}</button>
                <button type="button" class="btn btn-danger" data-dismiss="modal" onClick={cancelSubscription}>{I_am_sure}!</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  }
  else {
    subscriptionButton = '';
  }

  let deleteAccountButton;


  deleteAccountButton =
    <div style={{ flex: '1 0 0', paddingLeft: "5px" }}>
      <a id="delete_account"
        data-toggle="modal" data-target="#deleteAccountDialog"
        onClick={openDeleteAccountDialog}
        style={{
          fontSize: 12 + "px",
          color: "red",
          cursor: "pointer"
        }}
      >
        <u>{Delete_Account}</u>
      </a>

      <div className="modal fade" id="deleteAccountDialog" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">{Delete_Account}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ marginTop: -20 + 'px' }}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {Are_you_sure_you_want_to_permanently_delete_your_account_and_all_your_data}?
              <br /><br />
              {Your_subscription_will_also_be_canceled}.
              <br /><br />
              {Type} "DELETE"
              <input className="form-control" type="text" id="deleteWord" name="deleteWord" onChange={onChange} value={deleteWord ? deleteWord : ''} />
              {errorDeleteWord ? <span style={{ color: 'red' }}>*{Please_match_the_requested_format}!</span> : <br />}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-dismiss="modal">{No}</button>
              <button type="button" className="btn btn-danger" onClick={deleteAccount}>{I_am_sure}!</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  let subscriptionInfo;
  let subExpiryRes = subExpiry.substring(0, 10);
  let splitExpiry = subExpiryRes.split("-");
  let showExpiry = splitExpiry[2] + " " + monthNames[parseInt(splitExpiry[1]) - 1] + " " + splitExpiry[0];
  let subStatus;

  if (subscriptionStatus === 'succeeded') {
    subStatus = "(Subscribed)";
  }
  else if (subscriptionStatus === 'cancelled') {
    subStatus = "(Cancelled)";
  }
  else {
    subStatus = '';
  }

  if (subExpiry) {
    subscriptionInfo =
      <div className="alert alert-info" role="alert">
        <span><b>{Your_Package}:</b> {subscriptionNum} {valid_until} <b>{showExpiry} {subStatus}</b></span>
      </div>
  }
  else {
    subscriptionInfo =
      <div className="alert alert-info" role="alert">
        <span><b>{Your_Package}:</b> {subscriptionNum} </span>
      </div>
  }

  const changeLanguage = (lang) => {
    setLoading(true);

    let message;
    let variant;

    let body = {
      "lang": lang,
    }

    window
      .fetch(`${config.URL}/items/user_setting/${user_settingId}`, {
        method: "PATCH",
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
      .then(function (response) {
        return response.json();
      })
      .then(userdata => {
        //success  
        if (userdata.data) {
          localStorage.setItem('user_data', JSON.stringify(userdata.data));

          window.location.reload();
          message = 'Translating...';
          variant = 'success';

          //error
        } else if (userdata.error.code === 9) {
          message = 'This email address has been taken by another account.';
          variant = 'error';

        } else {
          message = 'Failed to update your profile.';
          variant = 'error';
        }

        setLoading(false);
        enqueueSnackbar({ message: { message : message, variant : variant } });
      });
    // }
  }

  const supportPage = () => {
    save_log(12);
    const url = "https://support.yearglance.com";
    const rel = "noreferrer"
    window.open(url, '_blank', rel);
  };
  const featureRequest = () => {
    const url = "https://feedback.yearglance.com/feature-requests";
    const rel = "noreferrer"
    window.open(url, '_blank', rel);
  };
  const roadMap = () => {
    const url = "https://feedback.yearglance.com/";
    const rel = "noreferrer"
    window.open(url, '_blank', rel);
  };

  return (
    <div className={classes.root}>
      <div>
        <IconButton
          size="small"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <AccountCircle style={{ fontSize: 30 + "px" }} />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            className={classes.textbody}
            onClick={handleClickOpenModal}
          >
            &nbsp;<i className="fa fa-male"></i>
            &nbsp;&nbsp;{Profile}
          </MenuItem>
          <Dialog onClose={handleCloseModal} aria-labelledby="customized-dialog-title" open={openModal} fullWidth>
            <DialogTitle id="customized-dialog-title" onClose={handleCloseModal} className={classes.textbody}>
              <div className={classes.paper}>
                <Typography className={classes.texttitle}>{Profile}</Typography>
              </div>
            </DialogTitle>
            <form >
              <DialogContent dividers>
                <Grid container spacing={2}>
                  <Grid item xs={12} > <Typography style={{ fontSize: 15 + "px" }}>{Name}</Typography></Grid>
                  <Grid item xs={12} sm={6}>
                    <input className="form-control" type="text" name="first_name" placeholder="First Name" onChange={onChange} value={firstName} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <input className="form-control" type="text" name="last_name" placeholder="Last Name" onChange={onChange} value={lastName} />
                  </Grid>
                  <Grid item xs={12} > <Typography style={{ fontSize: 15 + "px" }}>{Email}</Typography></Grid>
                  <Grid item xs={12}>
                    <input className="form-control" type="text" name="email" placeholder="New Email" onChange={onChange} value={email} />
                  </Grid>
                  <Grid item xs={12} > <Typography style={{ fontSize: 15 + "px" }}>{Password}</Typography></Grid>
                  <Grid item xs={12} sm={6}>
                    <input className="form-control" type="password" name="password" placeholder="New Password" onChange={onChange} value={password ? password : ''} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <input className="form-control" type="password" name="confirm_password" placeholder="Confirm New Password" onChange={onChange} value={confirmPassword ? confirmPassword : ''} />
                  </Grid>
                  {errorPassword ? <Grid item xs={12} > <Typography style={{ fontSize: 11 + "px", color: "red" }}>{The_new_password_and_confirmation_password_do_not_match}.</Typography></Grid> : null}
                  <Grid item xs={12} sm={6}> <Typography style={{ fontSize: 15 + "px" }}>{City}</Typography></Grid>
                  <Grid item xs={12} sm={6}> <Typography style={{ fontSize: 15 + "px" }}>{Country}</Typography></Grid>
                  <Grid item xs={12} sm={6}>
                    <input className="form-control" type="text" name="city" placeholder="City" onChange={onChange} value={city} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <input className="form-control" type="text" name="country" placeholder="Country" onChange={onChange} value={country} />
                  </Grid>
                  <Grid item xs={12} sm={6}> <Typography style={{ fontSize: 15 + "px" }}>{Industry}</Typography></Grid>
                  <Grid item xs={12} sm={6}> <Typography style={{ fontSize: 15 + "px" }}>{Role}</Typography></Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined" hiddenLabel>
                      <InputLabel className={classes.inputLabel} style={{ fontSize: "14px", background: "#fafafa" }} id="demo-simple-select-helper-label">Industry</InputLabel>
                      <Select
                        style={{ height: "34px" }}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={industry}
                        className={classes.selectData}
                        label="Industry"
                        onChange={onChange}
                        inputProps={{
                          name: 'industry',
                          id: 'outlined-age-native-simple',
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {industryOptions.map((value, index) => (
                          <MenuItem key={index} value={value[0]}>{value[1]}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel className={classes.inputLabel} style={{ fontSize: "14px", background: "#fafafa" }} >Role</InputLabel>
                      <Select
                        style={{ height: "34px" }}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={role}
                        className={classes.selectData}
                        label="Role"
                        onChange={onChange}
                        inputProps={{
                          name: 'role',
                          id: 'outlined-age-native-simple',
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {roleOptions.map((value, index) => (
                          <MenuItem key={index} value={value[0]}>{value[1]}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}> <Typography style={{ fontSize: 15 + "px" }}>{Organisation}</Typography></Grid>
                  <Grid item xs={12} sm={6}> <Typography style={{ fontSize: 15 + "px" }}>{Website}</Typography></Grid>
                  <Grid item xs={12} sm={6}>
                    <input className="form-control" type="text" name="organisation" placeholder="Organisation" onChange={onChange} value={organisation} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <input className="form-control" type="text" name="website" placeholder="Website" onChange={onChange} value={website} />
                  </Grid>
                  <Grid item xs={12} sm={2} style={{ marginRight: -15 + "px" }}>
                    <Typography style={{ fontSize: 15 + "px", padding: 4 + 'px' }}>{Language}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={10} >
                    <div className="dropdown">
                      <button className="btn btn-primary dropdown-toggle " type="button" id="language" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                        {English} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span className="caret"></span>
                      </button>
                      <ul className="dropdown-menu language" aria-labelledby="language">
                        <li><a href="#/" onClick={() => changeLanguage('en')}>English</a></li>
                        <li><a href="#/" onClick={() => changeLanguage('de')}>Deutsch</a></li>
                        <li><a href="#/" onClick={() => changeLanguage('id')}>Indonesian</a></li>
                        <li><a href="#/" onClick={() => changeLanguage('pl')}>Polish</a></li>
                      </ul>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    {subscriptionInfo}
                    {subloading ? <CircularProgress /> : subscriptionButton}
                  </Grid>
                </Grid>

              </DialogContent>
              <DialogActions>
                {deleteAccountButton}

                <div
                  style={{
                    display: "flex", justifyContent: "center", alignItems: "center", height: "50px"
                  }}
                >
                  <DialogContent
                    style=
                    {{
                      fontSize: 15 + "px",
                      color: "grey",
                    }}>
                    v.2.2.9 (Event Update)
                  </DialogContent>
                  {loading ? (<CircularProgress />) :
                    (<button className="btn btn-primary  btn pull-right"
                      onClick={onSubmit}
                      id="submit"
                      style={{
                        fontSize: 15 + "px"
                      }}
                    >
                      {Save}
                    </button>
                    )}


                </div>
              </DialogActions>
            </form>
          </Dialog>
          <MenuItem
            className={classes.textbody}
            onClick={() => supportPage()}
          >
            <i className="fa fa-comments-o"></i>
            &nbsp;{Support}
          </MenuItem>
          <MenuItem
            className={classes.textbody}
            onClick={() => featureRequest()}
          >
            <i className="fa fa-envelope"></i>&nbsp;{Feature_requests}
          </MenuItem>
          <MenuItem
            className={classes.textbody}
            onClick={() => roadMap()}
          >
            <i className="fa fa-map"></i>&nbsp;{Roadmap}
          </MenuItem>
          <MenuItem
            className={classes.textbody}
            onClick={logout}
          >
            &nbsp;<i className="fa fa-sign-out"></i>
            &nbsp;{Logout}
          </MenuItem>
        </Menu>

      </div>
    </div>
  );

}
