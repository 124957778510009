/*
 * changes made :
 * import change - from material-ui to mui
 * adjust the style changes in the mui component
 */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { green, amber } from '@mui/material/colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Typography,
  Grid,
  Card,
  CardActions
} from '@mui/material';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      flexGrow: 'initial',
      minWidth: 330,
    },
    width: 360
  },
  typography: {
    fontSize: 50 + "px",
    color: '#ffffff'
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
  },
  expand: {
    padding: '70px 30px',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  checkIcon: {
    fontSize: 20,
    color: '#b3b3b3',
    paddingRight: 4,
  },
  button: {
    padding: 0,
    textTransform: 'none',
  },
}));

export const SnackMessage = React.forwardRef((props, ref) => {
  const classes = useStyles();

  var variant = props.message.variant;
  var backColor;
  var icon;

  if(variant === "success"){
    backColor = green[600];
    icon = <CheckCircleIcon style={{ color: "white", fontSize: 20 + "px" }} />;
  }else if (variant === "warning"){
    backColor = amber[700];
    icon = <WarningIcon style={{ color: "white", fontSize: 20 + "px" }} />
  }else if (variant === "error"){
    backColor = "red";
    icon = <ErrorIcon style={{ color: "white", fontSize: 20 + "px" }} />
  }

  return (
    <Card className={classes.card} ref={ref} style={{backgroundColor: backColor}}>
      <CardActions classes={{ root: classes.actionRoot }}>
        <Grid container spacing={0}>
          <Grid item xs={1}>
            {icon}
          </Grid>
          <Grid item xs={11}>
            <Typography variant="subtitle2" className={classes.typography}>{props.message.message}</Typography>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
});

SnackMessage.propTypes = {
  id: PropTypes.number.isRequired
};