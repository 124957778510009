/*
 * changes made :
 * import change - from material-ui to mui
 * DatePicker changes to mui/x-date
 */
import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { authHeader } from "../../_helpers";
import * as config from "../../constants/resource";
import moment from "moment";
import linq from "linq";
import CustomGroupRow from "../../_helpers/CustomComponent";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker,  } from '@mui/x-date-pickers/DatePicker';
import {
  List,
  ListItem,
  ListItemText,
  Fab,
  ListSubheader,
  MenuItem,
  FormControl,
  Select,
  Skeleton,
  Grid,
  ThemeProvider,
  createTheme
} from "@mui/material";

export default function AmountActivity() {
  const [loading, setLoading] = useState(false);
  const [selectList, setSelectList] = useState(null);
  const [select, setSelect] = useState(10);
  const [calculateData, setCalculateData] = useState([]);
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      loadAmountActivity(true);
    }
  }, []);

  const handleChange = (event) => {
    setSelect(event.target.value);
  };

  const loadAmountActivity = async (reload) => {
    setLoading(true);

    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    const start_date = moment(new Date(startDate)).format("YYYY-MM-DD 00:00:00");
    const end_date = moment(new Date(endDate)).format("YYYY-MM-DD 23:59:59");

    let fetchVar;
    if (select === 0) {
      fetchVar = `${config.URL}/items/history_log?filter[created_on][between]=${start_date},${end_date}&limit=-1&fields=location.*,activity.*,id,created_on,user.*.*`;
    } else {
      fetchVar = `${config.URL}/items/history_log?filter[created_on][between]=${start_date},${end_date}&filter[activity][eq]=${select}&limit=-1&fields=location.*,activity.*,id,created_on,user.*.*`;
    }

    return fetch(fetchVar, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then((result) => {
        let count = 1;
        let resultData = [];
        result.data.map((data) => {
          var finding = linq
            .from(resultData)
            .where(
              (x) =>
                x.activityId === data.activity.id &&
                x.date === moment(data.created_on).format("DD-MMM-YYYY")
            )
            .toArray();

          if (finding.length > 0) {
            count = count + 1;
            resultData.map((inside, index) => {
              if (
                inside.activityId === finding[0].activityId &&
                inside.date === finding[0].date
              ) {
                resultData[index] = {
                  ...inside,
                  count: count,
                  child: [...inside.child, data],
                };
              }

              return null;
            });
          } else {
            count = 1;
            resultData.push({
              activityId: data.activity.id,
              name: data.activity.name,
              count: count,
              date: moment(data.created_on).format("DD-MMM-YYYY"),
              child: [data],
            });
          }
          return null;
        });

        resultData.map((item) => {
          if (item.date) {
            item.date = moment(item.date).format("DD/MM/YYYY");
          }
          return item;
        });

        setCalculateData(resultData);

        if (reload === true) {
          return fetch(`${config.URL}/items/log_status`, requestOptions)
            .then(function (response) {
              return response.json();
            })
            .then((statusResult) => {
              setSelectList(statusResult.data);
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
      });
  };

  const customRow = (rowData) => {
    return <CustomGroupRow {...rowData} />;
  };

  function AmountActivityTable() {
    const defaultMaterialTheme = createTheme();

    return (
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          title=""
          columns={[
            { title: "Activity", field: "name" },
            { title: "Date", field: "date" },
            { title: "Count", field: "count" },
          ]}
          data={calculateData}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 50, 100],
            filtering: true,
            grouping: true,
            exportButton: true,
          }}
          localization={{
            pagination: {
              labelRowsPerPage: "",
            },
          }}
          components={{
            GroupRow: (rowData) => customRow(rowData),
          }}
          detailPanel={(listData) => {
            return (
              <MaterialTable
                title=""
                columns={[
                  { title: "Name", field: "user.user.first_name" },
                  { title: "email", field: "user.user.email" },
                  { title: "Date", field: "created_on", type: "datetime" },
                ]}
                data={listData.child}
                options={{
                  pageSize: 5,
                  pageSizeOptions: [5, 10, 100],
                  filtering: true,
                  exportButton: true,
                  headerStyle: {
                    backgroundColor: "#01579b",
                    color: "#FFF",
                  },
                }}
                detailPanel={(rowData) => {
                  return (
                    <List
                      dense={true}
                      subheader={
                        <ListSubheader>
                          <h4>Location</h4>
                        </ListSubheader>
                      }
                    >
                      <ListItem>
                        <ListItemText
                          primary="IP"
                          secondary={rowData.location.IPv4}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="City"
                          secondary={rowData.location.city}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="State"
                          secondary={rowData.location.state}
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary="Country"
                          secondary={rowData.location.country_name}
                        />
                      </ListItem>
                    </List>
                  );
                }}
                onRowClick={(event, rowData, togglePanel) => togglePanel()}
              />
            );
          }}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
        />
      </ThemeProvider>
    );
  }

  if (loading) {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>

          <Grid item xs={12}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return (
      <div>
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker", "DatePicker"]}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                maxDate={endDate}
                slotProps={{
                  textField: {
                    helperText: "mm/dd/yyyy",
                  },
                }}
                inputFormat="dd-MM-yyyy"
              />
              <DatePicker
                label="End Date"
                value={endDate}
                minDate={startDate}
                onChange={(newValue) => setEndDate(newValue)}
                slotProps={{
                  textField: {
                    helperText: "mm/dd/yyyy",
                  },
                }}
              />
              {selectList ? (
                <FormControl variant="outlined">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={select}
                    onChange={handleChange}
                  >
                    {selectList.map((list, index) => {
                      return (
                        <MenuItem key={index + 1} value={list.id}>
                          {list.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : (
                ""
              )}
              <Fab
                size="small"
                color="primary"
                aria-label="add"
                onClick={() => loadAmountActivity()}
              >
                <SearchIcon />
              </Fab>
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <AmountActivityTable />
      </div>
    );
  }
}
