/*
 * changes made :
 * import change - from material-ui to mui
 * adjust the style changes in the mui component
 */
import React from 'react';
import moment from 'moment';
import i18next from "i18next";
import * as config from '../constants/resource';
import { useSnackbar } from 'notistack';
import { makeStyles, withStyles } from '@mui/styles';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import {
  IconButton,
  CircularProgress,
  Dialog,
  Typography,
  Grid,
} from '@mui/material';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  texttitle: {
    fontSize: 20 + "px",
  },
  textbody: {
    fontSize: 14 + "px",
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Grid container spacing={0}>
        <Grid item xs={11}>
          <Typography variant="h6">{children}</Typography>
        </Grid>
        <Grid item xs={1} sx={{ marginTop: '-5px' }}>
          {onClose ? (
            <IconButton aria-label="close" sx={{ marginLeft: '26px'}} className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

var open_2 = false;
if (window.location.pathname === "/appsumo") {
  open_2 = true;
}

export default function LifetimeDealPage() {
  const localData = JSON.parse(localStorage.getItem('yearglance'));
  const classes = useStyles();
  const [open, setOpen] = React.useState(open_2);
  const [loading, setLoading] = React.useState(false);
  const token = localData.data.token;
  const [code, setCode] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = React.useState('');
  const [user_settingId] = React.useState(JSON.parse(localStorage.getItem('user_data')).id);
  const [Redeem_code] = React.useState(i18next.t('Pages.menu.Redeem_code'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError("");
  };

  function onChange(e) {
    e.persist();
    if (e.target.name === "code") {
      setCode(e.target.value)
      if (!e.target.value) {
        setError("");
      }
    }
  }

  async function onSubmit(e) {
    var current_subscription = JSON.parse(localStorage.getItem('user_data')).subscription.id;
    e.preventDefault();
    var success = false;
    var subscription = null;
    if (code) {
      setLoading(true);
      await window
        .fetch(`${config.URL}/items/vouchers?filter[code][eq]=${code}`, {
          method: "GET",
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
          }
        })
        .then(function (response) {
          return response.json();
        })
        .then(async function (userdata) {
          if (userdata.data.length > 0) {
            if (userdata.data[0].used === false) {
              await window
                .fetch(`${config.URL}/items/vouchers/${userdata.data[0].id}`, {
                  method: "PATCH",
                  headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    used: true,
                    used_date: moment().format("YYYY-MM-DD"),
                    user: localData.data.user.id
                  })
                })
                .then(function (response) {
                  return response.json();
                })
                .then(updatedata => {
                  if (current_subscription === 12) {
                    subscription = updatedata.data.second_subscription;
                  } else {
                    subscription = updatedata.data.subscriptions;
                  }
                  success = true;
                })
            } else {
              setLoading(false);
              setError("This code has already been redeemed.");
            }
          } else {
            setLoading(false);
            setError("There is a problem with that code. Try a different one.")
          }
        })

      if (success && subscription) {
        await fetch(`${config.URL}/items/user_setting/${user_settingId}`, {
          method: "PATCH",
          headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            subscription: subscription,
            subscription_expiry: null
          })
        })
          .then(function (response) {
            return response.json();
          })
          .then(usesetting_data => {
            setLoading(false);
            if (usesetting_data.data) {
              localStorage.setItem('user_data', JSON.stringify(usesetting_data.data))

              enqueueSnackbar({ message: { message : 'Code successfully redeemed', variant : 'success' } });

              window.setTimeout(() => {
                window.location.href = "/";
              }, 2000)
            }
          })
      }
    } else {
      setError("Code cannot be empty.")
    }

  }

  return (
    <div className={classes.root}>
      <Typography className={classes.textbody} color="primary" style={{ color: "#333333", borderColor: "#333333" }} onClick={handleClickOpen}>
        {Redeem_code}
      </Typography>

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} className={classes.textbody}>
          <div className={classes.paper}>
            <Typography className={classes.texttitle} style={{ fontWeight: "700" }}>{Redeem_code}</Typography>
          </div>
        </DialogTitle>
        <form >
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12} > <Typography style={{ fontSize: 15 + "px" }}>Enter your Appsumo redemption code to get your lifetime subscription to Year Glance:</Typography></Grid>
              <Grid item xs={12}>
                <input className="form-control" type="text" name="code" placeholder="Appsumo code" onChange={(e) => onChange(e)} />
                {error ? <div style={{ color: "red" }}>{error}</div> : ''}
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions>
            <div
              style={{
                display: "flex", justifyContent: "center", alignItems: "center"
              }}
            >
              {loading ? (<CircularProgress />) :
                (<button className="btn btn-primary  btn pull-right"
                  onClick={onSubmit}
                  id="submit"
                  style={{
                    fontSize: 15 + "px"
                  }}
                >
                  Redeem
                </button>
                )}
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );

}
