/*
 * changes made :
 * import change - from material-ui to mui
 */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { authHeader } from "../../_helpers";
import * as config from "../../constants/resource";
import CustomGroupRow from "../../_helpers/CustomComponent";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme, Grid, Skeleton } from "@mui/material";

export default function Subscribed() {
  const [loading, setLoading] = useState(false);
  const [subscribeData, setSubscribeData] = useState([]);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      setLoading(true);
      loadSubscribed();
    }
  }, []);

  const loadSubscribed = async () => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    var result_data = [];
    await fetch(`${config.URL}/items/user_setting?filter[subscription][nin]=1,4,5&filter[status][eq]=published&filter[subscription_expiry][gte]=${moment().format("YYYY-MM-DD HH:mm:ss")}&limit=-1&fields=*.*`, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then((result) => {
        result_data = result.data;
      });

    await fetch(
      `${config.URL}/items/user_setting?filter[subscription][in]=12,13&filter[status][eq]=published&limit=-1&fields=*.*`,
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then((result) => {
        result_data.push(...result.data);
      });

    result_data.map(item => {
      if(item.subscription_expiry) {
        item.subscription_expiry = moment(item.subscription_expiry).format("DD/MM/YYYY")
      }
      return item;
    })
    
    setSubscribeData(result_data);
    setLoading(false);
  };

  const customRow = (rowData) => {
    return <CustomGroupRow {...rowData} />;
  };

  function Subscribed() {
    const defaultMaterialTheme = createTheme();
    return (
      <ThemeProvider theme={defaultMaterialTheme}>
          <MaterialTable
            title=""
            columns={[
              { title: "Name", field: "user.first_name" },
              { title: "Email", field: "user.email" },
              {
                title: "Subscription",
                field: "subscription.id",
                lookup: {
                  2: "Plan Me Enough (monthly)",
                  3: "Plan Me Enough (annually)",
                  8: "Plan Everyone Premium (monthly)",
                  9: "Plan Everyone Premium (annually)",
                  10: "Plan Me Enough (lifetime)",
                  11: "Plan Everyone Premium (lifetime)",
                  12: "Appsumo (lifetime)",
                  13: "Appsumo (lifetime premium)",
                },
              },
              { title: "Expired", field: "subscription_expiry" },
            ]}
            data={subscribeData}
            options={{
              pageSize: 10,
              pageSizeOptions: [10, 50, 100],
              filtering: true,
              grouping: true,
              exportButton: true,
            }}
            localization={{
              pagination: {
                labelRowsPerPage: ''
              }
            }}
            components={{
              GroupRow: (rowData) => customRow(rowData),
            }}
          />
      </ThemeProvider>
    );
  }

  if (loading === true) {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
          <Grid item xs={3}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>

          <Grid item xs={12}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" height={10 + "vh"} />
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return (
      <div>
        <Subscribed />
      </div>
    );
  }
}
