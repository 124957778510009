/*
 * changes made :
 * import change - from material-ui to mui
 * adjust the style changes in the mui component
 */
import React from 'react';
import { connect } from 'react-redux';
import AdminPage from './AdminPage';
import Profile from './Profile';
import * as config from '../constants/resource';
import { authHeader } from '../_helpers';
import Checkout from './Payment/Checkout';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { dialogActions } from '../_actions';
import moment from 'moment';

const useStyles = theme => ({
  root: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    fontSize: 14,
    paddingTop: 7
  },
});

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      timeout: false,
      premiumTrialDay: null,
      open: false,
    }

    this.handleClose = this.handleClose.bind(this);
    this.props.close();
  }

  UNSAFE_componentWillMount() {
    let user_data = JSON.parse(localStorage.getItem('user_data')) || [];

    let expired = moment(user_data.subscription_expiry);
    let result = expired.diff(moment(), 'days');

    if (result === 1) {
      this.setState({ premiumTrialDay: result + " day" })
    }
    else {
      this.setState({ premiumTrialDay: result + " days" })
    }
  }

  handleClose() {
    this.props.close();
  }

  modalClickOpen = () => {
    this.setState({ open: true });
  };

  modalClose = () => {
    this.setState({ open: false });
  };

  render() {
    // send variable data
    return (
      <div>
        {/* <!-- Header --> */}
        <header id="header-navbar" className="content-mini content-mini-full">
          {/* <!-- Header Navigation Right --> */}
          <ul className="nav-header pull-right" >
            <li style={{ float: "right" }} className="yg-profile">
              <Profile 
              replyTutorial={this.props.replyTutorial}
              />
            </li>
            <li style={{ float: "right" }}>
              <Checkout />
            </li>
            {JSON.parse(localStorage.getItem('yearglance')).data.user.role === 1 || JSON.parse(localStorage.getItem('yearglance')).data.user.role === "1" ?
              <li style={{ float: "right" }}>
                <AdminPage />
              </li>
              : ''}

            {JSON.parse(localStorage.getItem('user_data')).subscription.show_days_remaining ?
              <li style={{ float: "right" }}>
                <h5><span className="label label-primary" style={{ fontSize: 15 + 'px' }}>{JSON.parse(localStorage.getItem('user_data')).subscription.name} ends in {this.state.premiumTrialDay}</span></h5>
              </li>
              : ''
            }

          </ul>
          {/* <!-- END Header Navigation Right --> */}

          {/* <!-- Header Navigation Left --> */}
          <ul className="nav-header pull-left">
            <li className="hidden-md hidden-lg">
              {/* <LeftMenu /> */}
              <button className="btn btn-default" data-toggle="layout" data-action="sidebar_toggle" type="button">
                <i className="fa fa-navicon"></i>
              </button>
              {/* <!-- Layout API, functionality initialized in App() -> uiLayoutApi() --> */}
            </li>
            <li className="hidden-xs hidden-sm">
              <div dangerouslySetInnerHTML={{
                __html: `
									<button class="btn btn-default yg-collapse" data-action="sidebar_mini_toggle" type="button" data-toggle="layout" title="Click to show/hide sidebar" data-placement="bottom">
                    <i class="fa fa-ellipsis-v"></i>
                  </button>
								`}}>
              </div>
            </li>
            <li>
              <div dangerouslySetInnerHTML={{
                __html: `
									<button class="btn btn-default yg-fullscreen" onclick="fullScreen()" type="button" title="Click to go fullscreen" data-placement="bottom">
										<i class="fa fa-expand"></i>
									</button>
								`}}>
              </div>
            </li>
            {/* <!-- Toggle class helper (for .js-header-search below), functionality initialized in App() -> uiToggleClass() --> */}
            <li className="visible-xs" dangerouslySetInnerHTML={{
              __html: `
								<button class="btn btn-default" data-toggle="class-toggle" data-target=".js-header-search" data-class="header-search-xs-visible" type="button">
									<i class="fa fa-search"></i>
								</button>
							`}} >
            </li>
            <li className="js-header-search header-search">
              <div className="form-material form-material-primary input-group remove-margin-t remove-margin-b" dangerouslySetInnerHTML={{
                __html: `
                <input class="form-control" type="text" id="search-related-events" placeholder="Search.." />
                <a href="javascript:void(0);" class="si si-magnifier input-group-addon" style={{ cursor: "pointer" }} id="searchEvent"></a>
              `}}>
              </div>
            </li>
          </ul>
          {/* <!-- END Header Navigation Left --> */}

        </header>
        {/* <!-- END Header --> */}

      </div >
    );

  }
}
Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapState(state) {
  const { authentication } = state;
  const { user } = authentication;
  const { dialog } = state;
  return { user, dialog };
}

const actionCreators = {
  open: dialogActions.openRefreshToken,
  close: dialogActions.closeRefreshToken
};

const connectedHeader = connect(mapState, actionCreators)(Header);
const connectAll = withStyles(useStyles, { withTheme: true })(connectedHeader)
export { connectAll as Header };
