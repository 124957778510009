/*
 * changes made :
 * import change - from material-ui to mui
 * adjust the style changes in the mui component
 */
import React from "react";
import { connect } from "react-redux";
import { userActions } from "../_actions";
import { CopyrightPage } from "../Menu/footer";
import PropTypes from "prop-types";
import { ValidatorForm } from "react-material-ui-form-validator";
import { loadingActions } from "../_actions/loading.actions";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { withStyles } from "@mui/styles";
import {
  CircularProgress,
  Button,
  CssBaseline,
  Typography,
  Container,
  Box,
  Avatar,
} from "@mui/material";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: 35,
    fontSize: 15,
  },
  inputRoot: {
    fontSize: 17,
  },
  labelRoot: {
    fontSize: 17,
  },
  logo: {
    width: 25,
    height: 25,
  },
  formcontrol: {
    fontSize: 20,
  },
});

class VerificationEmail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        token: "",
        id_user: "",
        email: "",
        password: "",
        status: false,
      },
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.props.logout();
    this.props.load();
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    this.props.load(true);

    const query = new URLSearchParams(this.props.location.search);
    const token = query.get("token");
    const id_user = query.get("id_user");

    const { user } = this.state;
    user.token = token;
    user.id_user = id_user;

    this.props.confirmVerification(user);
  }

  componentWillMount() {
    const { user } = this.state;
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get("token");
    const id_user = query.get("id_user");
    const email = query.get("email");
    const password = query.get("password");

    user.token = token;
    user.id_user = id_user;
    user.email = email;
    user.password = password;

    this.props.checkUserActive(user);
  }

  render() {
    const { classes } = this.props;
    const { loading } = this.props;

    return (
      <div>
        <Container
          component="main"
          maxWidth="xs"
          style={{ height: "fit-content" }}
        >
          <CssBaseline />

          <div className={classes.paper}>
            <Avatar className={classes.avatar} sx={{ bgcolor: "#f50057" }}>
              <LockOutlinedIcon
                className={classes.logo}
                sx={{ width: "25px", height: "25px" }}
              />
            </Avatar>
            <Typography component="h6" variant="h4">
              Verify Your Email
            </Typography>
            <ValidatorForm
              className={classes.form}
              onSubmit={this.handleSubmit}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {loading.message === "true" ? (
                  <CircularProgress />
                ) : (
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={this.handleSubmit}
                    sx={{
                      margin: "10% 0% 6%",
                      fontSize: "15px",
                      height: "35px",
                    }}
                  >
                    Confirm Email
                  </Button>
                )}
              </div>
            </ValidatorForm>
          </div>

          <Box mt={2}>
            <CopyrightPage />
          </Box>
        </Container>
      </div>
    );
  }
}

VerificationEmail.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
};

function mapState(state) {
  const { loggingIn } = state.authentication;
  const { loading } = state;
  return { loggingIn, loading };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
  confirmVerification: userActions.confirmVerification,
  clearLoading: loadingActions.clearLoading,
  load: loadingActions.load,
  isExist: userActions.isExist,
  checkUserActive: userActions.checkUserActive,
};

const connectedForgetPasswordPage = connect(
  mapState,
  actionCreators
)(VerificationEmail);
const connectAll = withStyles(styles, { withTheme: true })(
  connectedForgetPasswordPage
);
export { connectAll as VerificationEmail };
