import React from "react";
import { Route, Redirect } from "react-router-dom";
import { SignUpPage } from "../LoginPage";

export const PrivateRouteLifetime = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("yearglance") ? (
        <Component {...props} />
      ) : (
        <SignUpPage {...props} />
      )
    }
  />
);
