/*
* changes made :
* import change - from material-ui to mui
*/
import React, { Component } from 'react';
import { history } from '../_helpers';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { green, amber } from '@mui/material/colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import { withStyles } from '@mui/styles';
import {
	SnackbarContent,
	Snackbar,
} from '@mui/material';
import * as config from '../constants/resource';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

export default function (ComposedComponent) {
  class CheckToken extends Component {
    constructor(props) {
      super(props);
      this.state = {
        expired: false,
        open: false,
        refresh: false
      };

      history.listen((location, action) => {
        this.handleConnectionChange();
      })
    }

    componentDidMount() {
      this.handleConnectionChange();
    }

    refreshtoken = () => {
      const token = JSON.parse(localStorage.getItem('yearglance')).data.token;
      const body = {
        "token": token
      }

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      };

      return fetch(`${config.URL}/auth/refresh`, requestOptions)

        .then(function (response) {
          return response.json();
        })
        .then(data => {

          if (data.data) {
            const newData = JSON.parse(localStorage.getItem('yearglance'));
            newData.data.token = data.data.token;
            localStorage.setItem('yearglance', JSON.stringify(newData));

            const expiredDate = new Date();
            expiredDate.setMinutes(expiredDate.getMinutes() + 20);

            localStorage.setItem('token_expired', expiredDate);
            console.log(expiredDate);

          } else {
            clearInterval(this.Interval)
            history.push('/login');
            this.setState({ expired: true });

          }
        });
    }
    
    handleConnectionChange = () => {
      if (localStorage.getItem('yearglance')) {

        //refresh token every 20 minutes
        const expiredDate = new Date(localStorage.token_expired);
        var currentDate = new Date();

        currentDate.setTime(expiredDate.getTime() - currentDate.getTime());
        var difference = currentDate.getTime();

        setTimeout(function(){
           this.refreshtoken();
           this.connectionInterval();
         }.bind(this), difference)
            

      } else {
        this.setState({ expired: false });
      }
    };

    connectionInterval = () =>{
      setInterval(function () {
        if (localStorage.getItem('token_expired')) {
          this.refreshtoken();
        } else {
          clearInterval(this.Interval)
        }
      }.bind(this), 1200000);
    }

    render() {
      const { expired } = this.state;
      var statusOpen = expired ? true : this.state.open;

      return (
        <div>
          {/* {refresh && this.handleConnectionChange()} */}
          {expired &&
            <div>
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={statusOpen}
              >
                <MySnackbarContentWrapper
                  variant="error"
                  message={<span style={{ fontSize: 15 + "px" }}>Your session is expired</span>}
                />
              </Snackbar>
              {setTimeout(function () { this.setState({ expired: false }) }.bind(this), 3000)}
            </div>
          }
          <ComposedComponent {...this.props} /></div>
      );
    }
  }

  return CheckToken;
}
