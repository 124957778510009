/*
 * changes made :
 * updated how to render for react version 18 using createRoot function
 * wrap it using the MUI's ThemeProvider - so it can be used withStyles, useStyles, or makeStyles on child components
 */
import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import { store } from "./_helpers";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import { SnackbarProvider } from "notistack";
import { SnackMessage } from "../src/Raw/SnackbarChil";
import { Helmet } from "react-helmet";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: "17px",
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          fontSize: "17px",
        },
      },
    },
  },
});

createRoot(document.getElementById("root")).render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={3000}
        maxSnack={3}
        content={(key, message) => <SnackMessage id={key} message={message} />}
      >
        <App />
      </SnackbarProvider>
      <Helmet>
        <script
          type="text/javascript"
          src={
            "assets/" +
            process.env.REACT_APP_JS_DIR +
            "/tawkto.js?v=" +
            process.env.REACT_APP_GIT_HASH
          }
        ></script>
      </Helmet>
    </Provider>
  </ThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
