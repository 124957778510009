import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { dialogActions } from '../_actions';
import { SketchPicker } from 'react-color';
import i18next from "i18next";
import EvEmitter from '../_helpers/EvEmitter';
import { withStyles } from '@mui/styles';

const useStyles = theme => ({
  root: {
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    fontSize: 14,
    paddingTop: 7
  },
  fieldset: {
    minWidth: 0,
    padding: "8px 10px 12px 10px",
    margin: 0,
    border: "1px solid lightgray",
    borderRadius: "5px"
  },
  legend: {
    display: "block",
    padding: "0px 5px",
    marginBottom: "0px",
    fontSize: "14px",
    lineHeight: "inherit",
    color: "#333",
    border: "0",
    borderBottom: "0px",
    width: "inherit"
  }
});


var tableWidthTimeout = null;

class Options extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      timeout: false,
      workDaysColor: '',
      offDaysColor: '',
      todayColor: '',
      borderColor: '',
      weekdayHeaderColor: '',
      weekNoHeaderColor: '',
      monthHeaderColor: '',
      monthHeaderMonthlyColor: '',
      numOfMonth: localStorage.monthNumber ? parseInt(localStorage.monthNumber) : 12,
      tableWidth: localStorage.tw ? parseInt(localStorage.tw) : 400,
      // tableWidth: 400,
      Customize_My_Planner: i18next.t('Pages.sideMenu.Customize_My_Planner'),
      Show_Only_All_Day_Events: i18next.t('Pages.options.Show_Only_All_Day_Events'),
      Hide_Header: i18next.t('Pages.options.Hide_Header'),
      Print_borders_only: i18next.t('Pages.options.Print_borders_only'),
      Single_Line: i18next.t('Pages.options.Single_Line'),
      Cell_Width: i18next.t('Pages.options.Cell_Width'),
      Border_Width: i18next.t('Pages.options.Border_Width'),
      Cell_Height: i18next.t('Pages.options.Cell_Height'),
      Text_Size: i18next.t('Pages.options.Text_Size'),
      Header_Size: i18next.t('Pages.options.Header_Size'),
      Table_Headers: i18next.t('Pages.options.Table_Headers'),
      Font_Size_MnD: i18next.t('Pages.options.Font_Size_MnD'),
      Number_of_Month: i18next.t('Pages.options.Number_of_Month'),
      Today_Color: i18next.t('Pages.options.Today_Color'),
      Border_Color: i18next.t('Pages.options.Border_Color'),
      Work_Day_Color: i18next.t('Pages.options.Work_Day_Color'),
      Off_Day_Color: i18next.t('Pages.options.Off_Day_Color'),
      Weekday_Header_Color: i18next.t('Pages.options.Weekday_Header_Color'),
      Week_No_Header_Color: i18next.t('Pages.options.Week_No_Header_Color'),
      Month_Header_Color: i18next.t('Pages.options.Month_Header_Color'),
      Month_Header_Color_Monthly: i18next.t('Pages.options.Month_Header_Color_Monthly'),
      Start_Month: i18next.t('Pages.options.Start_Month'),
      Work_Days: i18next.t('Pages.options.Work_Days'),
      Monday: i18next.t('Pages.days.Monday'),
      Tuesday: i18next.t('Pages.days.Tuesday'),
      Wednesday: i18next.t('Pages.days.Wednesday'),
      Thursday: i18next.t('Pages.days.Thursday'),
      Friday: i18next.t('Pages.days.Friday'),
      Saturday: i18next.t('Pages.days.Saturday'),
      Sunday: i18next.t('Pages.days.Sunday'),
      January: i18next.t('Pages.months.January'),
      February: i18next.t('Pages.months.February'),
      March: i18next.t('Pages.months.March'),
      April: i18next.t('Pages.months.April'),
      May: i18next.t('Pages.months.May'),
      June: i18next.t('Pages.months.June'),
      July: i18next.t('Pages.months.July'),
      August: i18next.t('Pages.months.August'),
      September: i18next.t('Pages.months.September'),
      October: i18next.t('Pages.months.October'),
      November: i18next.t('Pages.months.November'),
      December: i18next.t('Pages.months.December'),
      eventDayColor: i18next.t('Pages.options.eventDayColor'),
      Horizontal_Vertical_View: i18next.t('Pages.options.Horizontal_Vertical_View'),
      Font_Size_Box: i18next.t('Pages.options.Font_Size_Box'),
      Monthly_View: i18next.t('Pages.options.Monthly_View'),
      Table_Width: i18next.t('Pages.options.Table_Width'),
      Hide_Out_of_Office_Events: i18next.t('Pages.options.Hide_Out_of_Office_Events'),
      Hide_Live_Chat: i18next.t('Pages.options.Hide_Live_Chat'),
      Show_Week_Numbers: i18next.t('Pages.options.Show_Week_Numbers'),
      Align_by_Date: i18next.t('Pages.options.Align_by_Date'),
      Hide_day_Names: i18next.t('Pages.options.Hide_day_Names'),
      Show_For_Public: i18next.t('Pages.options.Show_For_Public'),
      Show_Multi_Day_Event: i18next.t('Pages.options.Show_Multi_Day_Event'),
      // Show_Only_Week: i18next.t('Pages.options.Show_Only_Week')
    }

    this.handleClose = this.handleClose.bind(this);
    this.props.close();
  }

  handleClose() {
    this.props.close();
  }

  componentDidMount() {
    this.setState({ workDaysColor: localStorage.getItem('work_day_color') });
    this.setState({ offDaysColor: localStorage.getItem('off_day_color') });
    this.setState({ todayColor: localStorage.getItem('today_color') });
    this.setState({ borderColor: localStorage.getItem('border_color') ? localStorage.getItem('border_color') : "#FFF" });
    this.setState({ weekdayHeaderColor: localStorage.getItem('weekday_header_color') });
    this.setState({ weekNoHeaderColor: localStorage.getItem('week_no_header_color') });
    this.setState({ monthHeaderColor: localStorage.getItem('month_header_color') });
    this.setState({ monthHeaderMonthlyColor: localStorage.getItem('month_header_monthly_color') });
  }

  colorTodayChange = (e) => {
    this.setState({ todayColor: e.hex })
    localStorage.setItem('today_color', e.hex);
    window.changeTodayColor();
    EvEmitter.emit('TodayColor', { value: e.hex })
  }

  colorBorderChange = (e) => {
    this.setState({ borderColor: e.hex })
    localStorage.setItem('border_color', e.hex);
    window.changeBorderColor();
    EvEmitter.emit('BorderColor', { value: e.hex })
  }

  colorWorkDaysChange = (e) => {
    this.setState({ workDaysColor: e.hex })
    localStorage.setItem('work_day_color', e.hex);
    window.changeColor();
    EvEmitter.emit('WorkDayColor', { value: e.hex })
  }

  colorOffDaysChange = (e) => {
    this.setState({ offDaysColor: e.hex })
    localStorage.setItem('off_day_color', e.hex);
    window.changeColor();
    EvEmitter.emit('OffDayColor', { value: e.hex })
  }

  colorWeekdayHeaderChange = (e) => {
    this.setState({ weekdayHeaderColor: e.hex })
    localStorage.setItem('weekday_header_color', e.hex);
    window.changeColor();
    EvEmitter.emit('WeekDayHeaderColor', { value: e.hex })
  }

  colorWeekNoHeaderChange = (e) => {
    this.setState({ weekNoHeaderColor: e.hex })
    localStorage.setItem('week_no_header_color', e.hex);
    window.changeColor();
    EvEmitter.emit('WeekNoHeaderColor', { value: e.hex })
  }

  colorMonthHeaderChange = (e) => {
    this.setState({ monthHeaderColor: e.hex })
    localStorage.setItem('month_header_color', e.hex);
    window.changeColor();
    EvEmitter.emit('MonthHeaderColor', { value: e.hex })
  }

  colorMonthHeaderMonthlyChange = (e) => {
    this.setState({ monthHeaderMonthlyColor: e.hex })
    localStorage.setItem('month_header_monthly_color', e.hex);
    window.changeColor();
    EvEmitter.emit('MonthHeaderMonthlyColor', { value: e.hex })
  }

  DayChange = (data) => {
    EvEmitter.emit('DayChange', { day: data, value: window.document.getElementById("monday").checked })

    switch (data) {
      case 0:
        window.Sunday();
        break;
      case 1:
        window.Monday();
        break;
      case 2:
        window.Tuesday();
        break;
      case 3:
        window.Wednesday();
        break;
      case 4:
        window.Thursday();
        break;
      case 5:
        window.Friday();
        break;
      case 6:
        window.Saturday();
        break;
      default:
        break
    }
  }

  changeNumberOfMonth = (e) => {
    this.setState({ numOfMonth: e.target.value })
    // window.sendOptions();
    window.changeNumMonth(e.target.value)

    clearTimeout(tableWidthTimeout);
    tableWidthTimeout = setTimeout(function () {
      EvEmitter.emit('OtherRefresh', { value: true });
    }, 1000);

  }

  changeTableWidth = (e) => {
    this.setState({ tableWidth: e.target.value });
    localStorage.tw = e.target.value;
    EvEmitter.emit('TableWidth_Monthly', { value: parseInt(e.target.value) })
    window.sendOptions();

    clearTimeout(tableWidthTimeout);
    tableWidthTimeout = setTimeout(function () {
      EvEmitter.emit('OtherRefresh', { value: true });
    }, 500);
  }

  changeStartMonth = (value) => {
    // console.log("value", value)
    // EvEmitter.emit('changeStartMonth', { value: value })
  }

  changeStartDay = (value) => {
    // EvEmitter.emit('changeStartMonth', { value: value })
    // console.log("day", value)
  }

  displayYearly = () => {
    return this.props.mode !== 'yearly' ? { display: 'none' } : {}
  }

  handleAlignByDate(view) {
    if (view === "Horizontal") {
      window.abdHorizontalView();
    } else {
      window.abdVerticalView();
    }
  }

  render() {
    // to send variable data
    const { classes } = this.props;
    // const { timeout } = this.state;
    return (
      <div className="modal fade" id="options-modal" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-md modal-dialog modal-dialog-top">
          <div className="modal-content">
            {/* <!-- Apps Block --> */}
            <div className="block block-themed block-transparent">
              <div className="block-header bg-primary">
                <ul className="block-options">
                  <li dangerouslySetInnerHTML={{
                    __html: `<button data-dismiss="modal" type="button" style="padding-top: 20px" onclick="saveData();"><i class="si si-close"></i></button>`
                  }} ></li>
                </ul>
                <h3 className="block-title">{this.state.Customize_My_Planner}</h3>
              </div>
              <div className="block-content">
                <div className="row">
                  <div className="col-md-6">
                    <ul className="list list-simple list-li-clearfix options">
                      <li>
                        <span dangerouslySetInnerHTML={{
                          __html: `<a href="javascript:void(0)" onclick="allDayEvent();"><input name="allDayOption" type="checkbox" id="allDayOption" checked/>&nbsp;<i class="fa fa-calendar"></i></a>`
                        }} >
                        </span>
                        <label htmlFor="allDayOption" className="sidebar-mini-hide">{this.state.Show_Only_All_Day_Events}</label>
                      </li>
                      <li>
                        <span dangerouslySetInnerHTML={{
                          __html: `<a href="javascript:void(0)" onclick="hideOutOfOffice();"><input name="outOfOfficeOption" type="checkbox" id="outOfOfficeOption" checked/>&nbsp;<i class="fa fa-calendar-times-o"></i></a>`
                        }} >
                        </span>
                        <label htmlFor="outOfOfficeOption" className="sidebar-mini-hide">{this.state.Hide_Out_of_Office_Events}</label>
                      </li>
                      <li id="show-weekNumbers">
                        <div>
                          <span style={{ cursor: 'pointer' }} onClick={() => window.show_WeekNumbers()}><input name="weekNumbersCheckbox" type="checkbox" id="weekNumbersCheckbox" />&nbsp;<i className="fa fa-info-circle weekNumbersOption"></i></span><label htmlFor="weekNumbersCheckbox" className="sidebar-mini-hide">{this.state.Show_Week_Numbers}</label>
                        </div>
                      </li>
                      {/* <li id="show-forPublic">
                        <div>
                          <span style={{ cursor: 'pointer' }} onClick={() => window.show_ForPublic()}>
                            <input name="showForPublicCheckbox" type="checkbox" id="showForPublicCheckbox" />&nbsp;<i className="fa fa-eye weekNumbersOption"></i>
                          </span>
                          <label htmlFor="showForPublicCheckbox" className="sidebar-mini-hide">{this.state.Show_For_Public}</label>
                        </div>
                      </li> */}
                      <li id="showHeader">
                        <div>
                          <span style={{ cursor: 'pointer' }} onClick={() => window.changeShowHeader()}><input name="showHeaderCheckbox" type="checkbox" id="showHeaderCheckbox" />&nbsp;<i className="si si-puzzle showHeaderCheckbox"></i></span><label htmlFor="showHeaderCheckbox" className="sidebar-mini-hide">{this.state.Hide_Header}</label>
                        </div>
                      </li>
                      <li id="hide-tawkto">
                        <div>
                          <span style={{ cursor: 'pointer' }} onClick={() => window.hideTawkto()}><input name="hideTawktoCheckbox" type="checkbox" id="hideTawktoCheckbox" />&nbsp;<i className="fa fa-comments"></i></span><label htmlFor="hideTawktoCheckbox" className="sidebar-mini-hide">{this.state.Hide_Live_Chat}</label>
                        </div>
                      </li>

                      <li id="single-line">
                        <div>
                          <span style={{ cursor: 'pointer' }} onClick={() => window.changeSingleLine()}><input name="singleLineCheckbox" type="checkbox" id="singleLineCheckbox" />&nbsp;<i className="fa fa-ellipsis-h"></i></span><label htmlFor="singleLineCheckbox" className="sidebar-mini-hide">{this.state.Single_Line}</label>
                        </div>
                      </li>

                      <li id="multiday-event">
                        <div>
                          <span style={{ cursor: 'pointer' }} onClick={() => window.showMultidayEvent()}><input name="multiDayEventCheckbox" type="checkbox" id="multiDayEventCheckbox" />&nbsp;<i className="fa fa-list-ol"></i></span><label htmlFor="multiDayEventCheckbox" className="sidebar-mini-hide">{this.state.Show_Multi_Day_Event}</label>
                        </div>
                      </li>
                      <li id="printBare">
                        <div>
                          <span style={{ cursor: 'pointer' }} onClick={() => window.Plain()}><input name="printBareCheckbox" type="checkbox" id="printBareCheckbox" />&nbsp;<i className="fa fa-print"></i></span><label htmlFor="printBareCheckbox" className="sidebar-mini-hide">{this.state.Print_borders_only}</label>
                        </div>
                      </li>
                      <li id="cellHeight">
                        <span dangerouslySetInnerHTML={{
                          __html: `<input id="cellHeightInput" type="number" min="25" max="300" value="25" step="5" onchange="changeCellHeight(); sendOptions();" />&nbsp;<i class="si si-equalizer cellHeight"></i>
                          `}} >
                        </span>
                        <span className="sidebar-mini-hide">{this.state.Cell_Height}</span>
                      </li>
                      <li id="borderWidth">
                        <span dangerouslySetInnerHTML={{
                          __html: `<input id="borderWidthInput" type="number" min="1" max="250" value="1" step="1" onchange="changeBorderWidth(); sendOptions();" />&nbsp;<i class="fa fa-sliders cellWidth"></i>
                          `}} >
                        </span>
                        <span className="sidebar-mini-hide">{this.state.Border_Width}</span>
                      </li>
                      <fieldset className={classes.fieldset}>
                        <legend className={classes.legend}>{this.state.Font_Size_Box}</legend>
                        <li id="headerSize">
                          <span dangerouslySetInnerHTML={{
                            __html: `<input id="headerSizeInput" type="number" min="1" max="250" value="14" step="1" onchange="changeHeaderSize(); sendOptions();" />&nbsp;Aa&nbsp;
                          `}} >
                          </span>
                          <span className="sidebar-mini-hide">&nbsp;{this.state.Header_Size}</span>
                        </li>
                        <p></p>
                        <li id="fontSizeMnD">
                          <span dangerouslySetInnerHTML={{
                            __html: `<input id="fontSizeInputMnD" type="number" min="1" max="250" value="14" step="1" onchange="changeFontSizeMnD(); sendOptions();" />&nbsp;Aa&nbsp;
                          `}} >
                          </span>
                          <span className="sidebar-mini-hide">&nbsp;{this.state.Font_Size_MnD}</span>
                        </li>
                        <p></p>
                        <li id="tableHeaders">
                          <span dangerouslySetInnerHTML={{
                            __html: `<input id="tableHeadersInput" type="number" min="11" max="250" value="11" step="1" onchange="changeTableHeaders(); sendOptions();" />&nbsp;Aa&nbsp;
                          `}} >
                          </span>
                          <span className="sidebar-mini-hide">&nbsp;{this.state.Table_Headers}</span>
                        </li>
                        <p></p>
                        <li id="fontSize">
                          <span dangerouslySetInnerHTML={{
                            __html: `<input id="fontSizeInput" type="number" min="11" max="250" value="11" step="1" onchange="changeFontSize(); sendOptions();" />&nbsp;Aa&nbsp;
                          `}} >
                          </span>
                          <span className="sidebar-mini-hide">&nbsp;{this.state.Text_Size}</span>
                        </li>
                      </fieldset>
                      <fieldset className={classes.fieldset} style={{ marginTop: "10px" }}>
                        <legend className={classes.legend}>{this.state.Horizontal_Vertical_View}</legend>
                        <li id="cellWidth" style={{ marginBottom: "10px" }}>
                          <span dangerouslySetInnerHTML={{
                            __html: `<input id="cellWidthInput" type="number" min="40" max="250" value="40" step="5" onchange="changeCellWidth(true);" />&nbsp;<i class="fa fa-sliders cellWidth"></i>
                          `}} >
                          </span>
                          <span className="sidebar-mini-hide">{this.state.Cell_Width}</span>
                        </li>
                        <p></p>
                        <li id="align-by-date">
                          <div>
                            <span style={{ cursor: 'pointer' }} onClick={() => window.alignByDateOption()}><input name="alignByDateCheckbox" type="checkbox" id="alignByDateCheckbox" />&nbsp;&nbsp;<i className="fa fa-calendar-check-o"></i></span><label htmlFor="alignByDateCheckbox" className="sidebar-mini-hide">{this.state.Align_by_Date}</label>
                          </div>
                        </li>
                        <li id="hide-day-names">
                          <div>
                            <span style={{ cursor: 'pointer' }} onClick={() => window.hideDayNames()}><input name="hideDayNamesCheckbox" type="checkbox" id="hideDayNamesCheckbox" />&nbsp;&nbsp;<i className="fa fa-calendar-minus-o"></i></span><label htmlFor="hideDayNamesCheckbox" className="sidebar-mini-hide">{this.state.Hide_day_Names}</label>
                          </div>
                        </li>
                        {/* <li id="show-only-week">
                          <div>
                            <span style={{ cursor: 'pointer' }} onClick={() => window.showOnlyWeek()}><input name="showOnlyWeekCheckbox" type="checkbox" id="showOnlyWeekCheckbox" />&nbsp;&nbsp;<i className="fa fa-calendar-minus-o"></i></span><label htmlFor="showOnlyWeekCheckbox" className="sidebar-mini-hide">{this.state.Show_Only_Week}</label>
                          </div>
                        </li> */}
                      </fieldset>

                      {/* <fieldset className={classes.fieldset} style={{ marginTop: "20px" }}>
                        <legend className={classes.legend}>{this.state.Monthly_View}</legend>
                        <li id="tableWidth" style={{ marginBottom: "10px" }}>
                          <a><input id="tableWidthInput" type="number" min="100" max="2000" step="10" value={this.state.tableWidth} step="10" onChange={(e) => this.changeTableWidth(e)} style={{ width: "70px" }} />&nbsp;<i className="fa fa-sliders cellWidth"></i></a>
                          <span className="sidebar-mini-hide">&nbsp;{this.state.Table_Width}</span>
                        </li>
                      </fieldset> */}
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="list list-simple list-li-clearfix options">
                      <li>
                        <button className="btn btn-primary" type="button" data-toggle="collapse" data-target="#today_color" aria-expanded="false" aria-controls="today_color" style={{ backgroundColor: this.state.todayColor }}>
                          <span className="caret"></span>
                        </button>
                        &nbsp; {this.state.Today_Color}
                        <div className="collapse" id="today_color">
                          <SketchPicker
                            presetColors={['#183c64']}
                            disableAlpha={true}
                            color={this.state.todayColor}
                            onChangeComplete={this.colorTodayChange}
                          />
                        </div>
                      </li>
                      <li>
                        <button className="btn btn-primary" type="button" data-toggle="collapse" data-target="#border_color" aria-expanded="false" aria-controls="border_color" style={{ backgroundColor: this.state.borderColor }}>
                          <span className="caret"></span>
                        </button>
                        &nbsp; {this.state.Border_Color}
                        <div className="collapse" id="border_color">
                          <SketchPicker
                            presetColors={['#FFFFFF']}
                            disableAlpha={true}
                            color={this.state.borderColor}
                            onChangeComplete={this.colorBorderChange}
                          />
                        </div>
                      </li>
                      <li>
                        <button className="btn btn-primary" type="button" data-toggle="collapse" data-target="#work_day_color" aria-expanded="false" aria-controls="work_day_color" style={{ backgroundColor: this.state.workDaysColor }}>
                          <span className="caret"></span>
                        </button>
                        &nbsp; {this.state.Work_Day_Color}
                        <div className="collapse" id="work_day_color">
                          <SketchPicker
                            presetColors={['#91c2d4']}
                            disableAlpha={true}
                            color={this.state.workDaysColor}
                            onChangeComplete={this.colorWorkDaysChange}
                          />
                        </div>
                      </li>
                      <li>
                        <button className="btn btn-primary" type="button" data-toggle="collapse" data-target="#off_day_color" aria-expanded="false" aria-controls="off_day_color" style={{ backgroundColor: this.state.offDaysColor }}>
                          <span className="caret"></span>
                        </button>
                        &nbsp; {this.state.Off_Day_Color}
                        <div className="collapse" id="off_day_color">
                          <SketchPicker
                            presetColors={['#18a7f5']}
                            disableAlpha={true}
                            color={this.state.offDaysColor}
                            onChangeComplete={this.colorOffDaysChange}
                          />
                        </div>
                      </li>
                      <li>
                        <button className="btn btn-primary" type="button" data-toggle="collapse" data-target="#weekday_header_color" aria-expanded="false" aria-controls="weekday_header_color" style={{ backgroundColor: this.state.weekdayHeaderColor }}>
                          <span className="caret"></span>
                        </button>
                        &nbsp; {this.state.Weekday_Header_Color}
                        <div className="collapse" id="weekday_header_color">
                          <SketchPicker
                            presetColors={['#444444']}
                            disableAlpha={true}
                            color={this.state.weekdayHeaderColor}
                            onChangeComplete={this.colorWeekdayHeaderChange}
                          />
                        </div>
                      </li>
                      <li>
                        <button className="btn btn-primary" type="button" data-toggle="collapse" data-target="#week_no_header_color" aria-expanded="false" aria-controls="week_no_header_color" style={{ backgroundColor: this.state.weekNoHeaderColor }}>
                          <span className="caret"></span>
                        </button>
                        &nbsp; {this.state.Week_No_Header_Color}
                        <div className="collapse" id="week_no_header_color">
                          <SketchPicker
                            presetColors={['#444444']}
                            disableAlpha={true}
                            color={this.state.weekNoHeaderColor}
                            onChangeComplete={this.colorWeekNoHeaderChange}
                          />
                        </div>
                      </li>
                      <li>
                        <button className="btn btn-primary" type="button" data-toggle="collapse" data-target="#month_header_color" aria-expanded="false" aria-controls="month_header_color" style={{ backgroundColor: this.state.monthHeaderColor }}>
                          <span className="caret"></span>
                        </button>
                        &nbsp; {this.state.Month_Header_Color}
                        <div className="collapse" id="month_header_color">
                          <SketchPicker
                            presetColors={['#444444']}
                            disableAlpha={true}
                            color={this.state.monthHeaderColor}
                            onChangeComplete={this.colorMonthHeaderChange}
                          />
                        </div>
                      </li>
                      <hr></hr>
                      <li>
                        <table broder="0">
                          <tbody>
                            <tr>
                              <td>
                                <div className="dropdown">
                                  <button className="btn btn-primary dropdown-toggle " type="button" id="start_month" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    {this.state.Start_Month} &nbsp;
                                    <span className="caret"></span>
                                  </button>
                                  <ul className="dropdown-menu start_month" aria-labelledby="start_month">
                                    <li><a href="#/" value="0" onClick={() => this.changeStartMonth(0)}>{this.state.January}</a></li>
                                    <li><a href="#/" value="1" onClick={() => this.changeStartMonth(1)}>{this.state.February}</a></li>
                                    <li><a href="#/" value="2" onClick={() => this.changeStartMonth(2)}>{this.state.March}</a></li>
                                    <li><a href="#/" value="3" onClick={() => this.changeStartMonth(3)}>{this.state.April}</a></li>
                                    <li><a href="#/" value="4" onClick={() => this.changeStartMonth(4)}>{this.state.May}</a></li>
                                    <li><a href="#/" value="5" onClick={() => this.changeStartMonth(5)}>{this.state.June}</a></li>
                                    <li><a href="#/" value="6" onClick={() => this.changeStartMonth(6)}>{this.state.July}</a></li>
                                    <li><a href="#/" value="7" onClick={() => this.changeStartMonth(7)}>{this.state.August}</a></li>
                                    <li><a href="#/" value="8" onClick={() => this.changeStartMonth(8)}>{this.state.September}</a></li>
                                    <li><a href="#/" value="9" onClick={() => this.changeStartMonth(9)}>{this.state.October}</a></li>
                                    <li><a href="#/" value="10" onClick={() => this.changeStartMonth(10)}>{this.state.November}</a></li>
                                    <li><a href="#/" value="11" onClick={() => this.changeStartMonth(11)}>{this.state.December}</a></li>
                                  </ul>
                                </div>
                              </td>
                              <td>
                                <i id="month-loader" style={{ display: 'none' }} className="fa fa-spinner fa-pulse fa-1x fa-fw"></i>
                                &nbsp;&nbsp;<span className="status"></span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                      <li>
                        <table broder="0">
                          <tbody>
                            <tr>
                              <td>
                                <div className="dropdown">
                                  <button className="btn btn-primary dropdown-toggle " type="button" id="start_day" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    Start Day &nbsp;
                                    <span className="caret"></span>
                                  </button>
                                  <ul className="dropdown-menu start_day" aria-labelledby="start_day">
                                    <li><a href="#/" value="0" onClick={() => this.changeStartDay(0)}>{this.state.Sunday}</a></li>
                                    <li><a href="#/" value="1" onClick={() => this.changeStartDay(1)}>{this.state.Monday}</a></li>
                                    <li><a href="#/" value="2" onClick={() => this.changeStartDay(2)}>{this.state.Tuesday}</a></li>
                                    <li><a href="#/" value="3" onClick={() => this.changeStartDay(3)}>{this.state.Wednesday}</a></li>
                                    <li><a href="#/" value="4" onClick={() => this.changeStartDay(4)}>{this.state.Thursday}</a></li>
                                    <li><a href="#/" value="5" onClick={() => this.changeStartDay(5)}>{this.state.Friday}</a></li>
                                    <li><a href="#/" value="6" onClick={() => this.changeStartDay(6)}>{this.state.Saturday}</a></li>
                                  </ul>
                                </div>
                              </td>
                              <td>
                                <i id="day-loader" style={{ display: 'none' }} className="fa fa-spinner fa-pulse fa-1x fa-fw"></i>
                                &nbsp;&nbsp;<span className="status_day" style={{ textTransform: "capitalize" }}></span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </li>
                      {/* : ''} */}
                      <li>
                        <button className="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                          {this.state.Work_Days} &nbsp;<span className="caret"></span>
                        </button>
                        <div className="collapse" id="collapseExample">
                          <div>
                            <a href="#/" style={{ cursor: 'pointer' }} onClick={() => this.DayChange(1)}><input name="monday" type="checkbox" id="monday" />&nbsp;<label htmlFor="monday" className="sidebar-mini-hide">{this.state.Monday}</label></a>
                          </div>
                          <div>
                            <a href="#/" style={{ cursor: 'pointer' }} onClick={() => this.DayChange(2)}><input name="tuesday" type="checkbox" id="tuesday" />&nbsp;<label htmlFor="tuesday" className="sidebar-mini-hide">{this.state.Tuesday}</label></a>
                          </div>
                          <div>
                            <a href="#/" style={{ cursor: 'pointer' }} onClick={() => this.DayChange(3)}><input name="wednesday" type="checkbox" id="wednesday" />&nbsp;<label htmlFor="wednesday" className="sidebar-mini-hide">{this.state.Wednesday}</label></a>
                          </div>
                          <div>
                            <a href="#/" style={{ cursor: 'pointer' }} onClick={() => this.DayChange(4)}><input name="thursday" type="checkbox" id="thursday" />&nbsp;<label htmlFor="thursday" className="sidebar-mini-hide">{this.state.Thursday}</label></a>
                          </div>
                          <div>
                            <a href="#/" style={{ cursor: 'pointer' }} onClick={() => this.DayChange(5)}><input name="friday" type="checkbox" id="friday" />&nbsp;<label htmlFor="friday" className="sidebar-mini-hide">{this.state.Friday}</label></a>
                          </div>
                          <div>
                            <a href="#/" style={{ cursor: 'pointer' }} onClick={() => this.DayChange(6)}><input name="saturday" type="checkbox" id="saturday" />&nbsp;<label htmlFor="saturday" className="sidebar-mini-hide">{this.state.Saturday}</label></a>
                          </div>
                          <div>
                            <a href="#/" style={{ cursor: 'pointer' }} onClick={() => this.DayChange(0)}><input name="sunday" type="checkbox" id="sunday" />&nbsp;<label htmlFor="sunday" className="sidebar-mini-hide">{this.state.Sunday}</label></a>
                          </div>
                        </div>
                      </li>

                      <li id="numMonth">
                        <a><input id="numMonthInput" type="number" min="1" max="24" value={this.state.numOfMonth} step="1" onChange={(e) => this.changeNumberOfMonth(e)} />&nbsp;<i className="fa fa-calendar-o"></i></a>
                        <span className="sidebar-mini-hide"><i id="number-month-loader" style={{ display: 'none' }} className="fa fa-spinner fa-pulse fa-1x fa-fw"></i> &nbsp; {this.state.Number_of_Month}</span>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- END Apps Block --> */}
          </div>
        </div>
      </div>
    );

  }
}
Options.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapState(state) {
  const { authentication } = state;
  const { user } = authentication;
  const { dialog } = state;
  return { user, dialog };
}

const actionCreators = {
  open: dialogActions.openRefreshToken,
  close: dialogActions.closeRefreshToken
};

const connectedHeader = connect(mapState, actionCreators)(Options);
const connectAll = withStyles(useStyles, { withTheme: true })(connectedHeader)
export { connectAll as Options };
