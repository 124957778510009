import { paymentConstants } from '../_constants';

export const paymentActions = {
  openPayment,
  closePayment
};

function openPayment() {
  return { type: paymentConstants.OPEN_PAYMENT, message: true };
}

function closePayment() {
  return { type: paymentConstants.CLOSE_PAYMENT, message: false };
}