import * as config from './resource';

export function save_log(data)
{
  let user_data = JSON.parse(localStorage.getItem('user_data'));
  var yearglance = JSON.parse(localStorage.getItem('yearglance'));

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  }  
}