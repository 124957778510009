import React from "react";
import * as config from "../constants/resource";
import { authHeader, microsoftConfig } from "../_helpers";
import SidebarTop from "./SidebarTop";
import i18next from "i18next";
import { save_log } from "../constants/saveLog";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  Typography,
  CircularProgress,
  Grid,
} from "@mui/material";

export class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openSupport: false,
      plutioOpen: false,
      plutioClientId: "",
      plutioClientSecret: "",
      plutioLoading: false,
      icsOpen: false,
      icsField: "",
      icsLoading: false,
      onBoarding: window.dataOnboarding,
      Actions: i18next.t("Pages.sideMenu.Actions"),
      Print: i18next.t("Pages.sideMenu.Print"),
      Tutorial: i18next.t("Pages.sideMenu.Tutorial"),
      Connect_Calendar: i18next.t("Pages.sideMenu.Connect_Calendar"),
      Calendar_List: i18next.t("Pages.sideMenu.Calendar_List"),
    };
  }

  async componentDidMount() {
    window.checkSignIn();
    //Google Start********************************************************************************************************

    localStorage.setItem("statusSignIn", false);
    localStorage.setItem("microsoftSignIn", false);

    window.handleClientLoad();

    //Microsoft Start*****************************************************************************************************
    var microsoft_username = localStorage.getItem("_microsoftAccount");

    var account;

    if (microsoft_username) {
      try {
        account = await window.checkMsal();
      } catch (err) {
        console.log("Microsoft Calendar Error", err);
      }

      if (account) {
        try {
          // var eventValues;

          // if(localStorage.getItem("microsoftEventValues")) {
          //   eventValues = JSON.parse(localStorage.getItem("microsoftEventValues") || "[]");
          // } else {
          //   var accessToken = await window.getAccessToken(microsoftConfig.scopes);

          //   if(!accessToken) {
          //     accessToken = await window.getMsalToken();
          //   }

          //   var events = await window.getEvents(accessToken);
          //   localStorage.setItem("microsoftEventValues", JSON.stringify(events.value));
          //   eventValues = events.value;
          // }

          var accessToken = await window.getAccessToken(microsoftConfig.scopes);

          if(!accessToken) {
            accessToken = await window.getMsalToken();
          }

          var events = await window.getEvents(accessToken);

          await window.getMicrosoftCalendar(events.value);
        } catch (err) {
          console.log("Microsoft Calendar Error", err);
        }
      }
    }

    //Plutio Start*****************************************************************************************************

    let user_data = JSON.parse(localStorage.getItem("user_data")) || [];

    if (
      user_data.plutio != null &&
      JSON.parse(localStorage.getItem("user_data")).subscription
        .allow_integrations === true
    ) {
      if (user_data.plutio.clientId !== "") {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("client_id", user_data.plutio.clientId);
        urlencoded.append("client_secret", user_data.plutio.clientSecret);
        urlencoded.append("grant_type", "client_credentials");

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow",
        };

        fetch("https://api.plutio.com/v1.7/oauth/token", requestOptions)
          .then((response) => response.json())
          .then((result) => {
            localStorage.setItem("plutioSignIn", true);
            window.getPlutioCalendar(result);
          })

          .catch((error) => {
            console.log("Plutio error", error);
          });
      }
    }
  }

  parentCallPopup = () => {
    this.setState({ open: true });
  };

  handleClick = () => {
    this.props.enqueueSnackbar({
      message: "Your Title is Succesfully Changed",
      options: {
        variant: "success",
      },
    });
  };

  submitTitle = (event) => {
    let user_data = JSON.parse(localStorage.getItem("user_data")) || [];

    event.preventDefault();

    const requestOptions = {
      method: "PATCH",
      headers: authHeader(),
      body: JSON.stringify({ title: this.input.value }),
    };

    return fetch(
      `${config.URL}/items/user_setting/` + user_data.id,
      requestOptions
    )
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        this.props.enqueueSnackbar({
          message: "Your Title is Succesfully Changed",
          options: {
            variant: "success",
          },
        });
      });
  };

  onChange = (event) => {
    event.persist();
    if (event.target.name === "clientId") {
      this.setState({ plutioClientId: event.target.value });
    } else if (event.target.name === "clientSecret") {
      this.setState({ plutioClientSecret: event.target.value });
    }
  };
  //Google==========================================================================================================================
  googleLogin = () => {
    this.setState({ open: false });
    window.handleAuthClick();
  };

  //Microsoft=======================================================================================================================
  microsoftLogin = async () => {
    this.setState({ open: false });
    //Delete all msal first===============================
    for (var i in localStorage) {
      if (i.indexOf("msal") === 0) {
        localStorage.removeItem(i);
      }
    }
    try {
      try {
        // Get the user's events
        const result = await window.MicrosoftLoginJS();
        const authResult = result.authResult;
        const events = result.events;

        var key = {};

        for (var x in localStorage) {
          if (
            x.indexOf("login.microsoftonline.com") > 0 ||
            x.indexOf("client.info") > 0 ||
            (x.indexOf(".idtoken") > 0 && x.indexOf(".nonce.idtoken") < 0) ||
            x.indexOf("login.windows.net") > 0
          ) {
            key[x] = localStorage[x];
          }
        }

        key["_microsoftAccount"] = authResult.account.username;

        const user_options = {
          microsoft: key,
        };

        let user_data = JSON.parse(localStorage.getItem("user_data")) || [];
        const requestOptions = {
          method: "PATCH",
          headers: authHeader(),
          body: JSON.stringify(user_options),
        };

        return fetch(
          `${config.URL}/items/user_setting/` + user_data.id,
          requestOptions
        )
          .then(function (response) {
            return response.json();
          })
          .then((data) => {
            localStorage.setItem("user_data", JSON.stringify(data.data));
            localStorage.setItem("microsoftSignIn", true);
            window.getMicrosoftCalendar(events.value);
          });
      } catch (err) {
        console.log("Microsoft Calendar Error", err);
      }
    } catch (err) {
      console.log("Microsoft Login Error", err);
    }
  };

  dialogClose = () => {
    this.setState({ open: false });
  };

  dialogOpen = () => {
    this.setState({ open: true });
  };
  //Plutio===========================================================================================================================
  dialogPlutioClose = (e) => {
    e.preventDefault();
    this.setState({ plutioOpen: false });
  };

  dialogPlutioOpen = () => {
    this.setState({ plutioOpen: true });
  };

  onPlutioSubmit = (e) => {
    e.preventDefault();
    this.setState({ plutioLoading: true });
    let user_data = JSON.parse(localStorage.getItem("user_data")) || [];
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("client_id", this.state.plutioClientId);
    urlencoded.append("client_secret", this.state.plutioClientSecret);
    urlencoded.append("grant_type", "client_credentials");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch("https://api.plutio.com/v1.9/oauth/token", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (!result.code) {
          const user_options = {
            plutio: {
              clientId: this.state.plutioClientId,
              clientSecret: this.state.plutioClientSecret,
              accessTokenExpiresAt: result.accessTokenExpiresAt,
              accessToken: result.access_token,
              business: result.client.businesses,
            },
          };

          const requestOptions = {
            method: "PATCH",
            headers: authHeader(),
            body: JSON.stringify(user_options),
          };

          return fetch(
            `${config.URL}/items/user_setting/` + user_data.id,
            requestOptions
          )
            .then(function (response) {
              return response.json();
            })
            .then((data) => {
              localStorage.setItem("user_data", JSON.stringify(data.data));
              this.setState({
                plutioLoading: false,
                plutioOpen: false,
                open: false,
              });
              localStorage.setItem("plutioSignIn", true);
              window.getPlutioCalendar(result);
            });
        } else {
          this.setState({
            plutioLoading: false,
          });
        }
      })
      .catch((error) => console.log("Plutio error"));
  };

  printPage = () => {
    save_log(11);
    window.hideTawk();
    setTimeout(function () {
      window.print();
    }, 500);
  };

  render() {
    return (
      <div>
        {/* <!-- Sidebar --> */}
        <nav id="sidebar">
          {/* <!-- Sidebar Scroll Container --> */}
          <div id="sidebar-scroll">
            {/* <!-- Sidebar Content --> */}
            {/* <!-- Adding .sidebar-mini-hide to an element will hide it when the sidebar is in mini mode --> */}
            <div className="sidebar-content">
              {/* <!-- Side Header --> */}
              <div
                className="side-header"
                style={{ backgroundColor: "#f5f4f4" }}
              >
                {/* <!-- Layout API, functionality initialized in App() -> uiLayoutApi() --> */}
                <button
                  className="btn btn-link text-gray pull-right hidden-md hidden-lg"
                  type="button"
                  data-toggle="layout"
                  data-action="sidebar_close"
                >
                  <i className="fa fa-times"></i>
                </button>
                {/* <!-- Themes functionality initialized in App() -> uiHandleTheme() --> */}
                <p className="h5 text-white"></p>
                <img
                  src="assets/img/favicons/year-glanceLogo.png"
                  style={{
                    width: 88 + "%",
                    marginLeft: 4 + "%",
                    marginBottom: 3 + "%",
                    marginTop: 1 + "%",
                  }}
                  alt="YearGlanceLogo"
                />
              </div>
              {/* <!-- END Side Header --> */}

              {/* <!-- Side Content --> */}
              <div className="side-content" style={{ overflowX: "" }}>
                <ul className="nav-main options">
                  <SidebarTop />
                  <li className="nav-main-heading">
                    <span className="sidebar-mini-hide">
                      {this.state.Actions}
                    </span>
                  </li>
                  <li>
                    <div className="yg-print">
                      <a
                        href="#/"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.printPage()}
                      >
                        <i className="fa fa-print"></i>
                        <span className="sidebar-mini-hide">
                          {this.state.Print}
                        </span>
                      </a>
                    </div>
                  </li>
                  <li className="yg-connect">
                    <a
                      href="#/"
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#334f71",
                        margin: "0 10px 0 10px",
                        borderRadius: "5px",
                      }}
                      onClick={this.dialogOpen}
                    >
                      <i className="fa fa-calendar"></i>
                      <span className="sidebar-mini-hide">
                        {this.state.Connect_Calendar}
                      </span>
                    </a>
                  </li>
                </ul>
                <ul
                  id="calendarEvents"
                  className="nav-main calendars"
                  style={{ display: "none" }}
                >
                  <li className="google-heading nav-main-heading">
                    <span className="sidebar-mini-hide">
                      {this.state.Calendar_List}
                    </span>
                  </li>
                </ul>
              </div>
              {/* <!-- END Side Content --> */}
            </div>
            {/* <!-- Sidebar Content --> */}
          </div>
          {/* <!-- END Sidebar Scroll Container --> */}
        </nav>
        {/* <!-- END Sidebar --> */}
        <Dialog
          id="signinDialog"
          onClose={this.dialogClose}
          aria-labelledby="simple-dialog-title"
          open={this.state.open}
        >
          <DialogTitle id="simple-dialog-title">
            <span style={{ fontSize: 15 + "px", lineHeight: 40 + "px" }}>
              {this.state.Connect_Calendar}
            </span>
            <button
              type="button"
              className="btn btn-default pull-right"
              onClick={this.dialogClose}
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </button>
          </DialogTitle>
          <DialogContent dividers>
            <List component="nav" aria-label="secondary mailbox folders">
              <ListItem>
                {(localStorage.getItem("statusSignIn") === false || localStorage.getItem("statusSignIn") === "false") ? (
                  <button
                    className="btn btn-light"
                    onClick={this.googleLogin}
                    id="authorize-button"
                    style={{
                      background: "white",
                      color: "#444",
                      border: "thin solid #888",
                      boxShadow: "1px 1px 1px grey",
                      marginTop: "10px",
                    }}
                  >
                    <img
                      src="../assets/img/google.png"
                      alt="google_icon"
                      style={{ paddingBottom: 3 + "px", width: 15 + "px" }}
                    />
                    <span style={{ fontFamily: "'Roboto', sans-serif" }}>
                      &nbsp;&nbsp;Sign in with Google
                    </span>
                  </button>
                ) : (
                  <button
                    className="btn btn-light"
                    disabled
                    id="authorize-button"
                    style={{
                      background: "white",
                      color: "#444",
                      border: "thin solid #888",
                      boxShadow: "1px 1px 1px grey",
                    }}
                  >
                    <img
                      src="../assets/img/google.png"
                      alt="google_icon"
                      style={{ paddingBottom: 3 + "px", width: 15 + "px" }}
                    />
                    <span style={{ fontFamily: "'Roboto', sans-serif" }}>
                      &nbsp;&nbsp;Sign in with Google
                    </span>
                  </button>
                )}
              </ListItem>
              <ListItem>
                {(localStorage.getItem("microsoftSignIn") === false || localStorage.getItem("microsoftSignIn") === "false") ? (
                  <button
                    className="btn btn-light"
                    onClick={this.microsoftLogin}
                    id="microsoft-login"
                    style={{
                      background: "white",
                      color: "#444",
                      border: "1px solid #8C8C8C",
                      borderRadius: "0px",
                    }}
                  >
                    <img
                      src="../assets/img/microsoft-logo.png"
                      alt="microsoft_icon"
                      style={{ paddingBottom: 3 + "px", width: 15 + "px" }}
                    />
                    <span
                      style={{
                        fontFamily: "'Segoe UI', Regular",
                        fontColor: "#5E5E5E",
                      }}
                    >
                      &nbsp;&nbsp;Sign in with Microsoft
                    </span>
                  </button>
                ) : (
                  <button
                    className="btn btn-light"
                    disabled
                    style={{
                      background: "white",
                      color: "#444",
                      border: "1px solid #8C8C8C",
                      borderRadius: "0px",
                    }}
                  >
                    <img
                      src="../assets/img/microsoft-logo.png"
                      alt="microsoft_icon"
                      style={{ paddingBottom: 3 + "px", width: 15 + "px" }}
                    />
                    <span
                      style={{
                        fontFamily: "'Segoe UI', Regular",
                        fontColor: "#5E5E5E",
                      }}
                    >
                      &nbsp;&nbsp;Sign in with Microsoft
                    </span>
                  </button>
                )}
              </ListItem>
              <ListItem>
                <div>
                  <Dialog
                    onClose={this.dialogPlutioClose}
                    aria-labelledby="simple-dialog-title"
                    open={this.state.plutioOpen}
                    fullWidth
                  >
                    <DialogTitle id="simple-dialog-title">
                      <span style={{ fontSize: 15 + "px" }}>Plutio</span>
                    </DialogTitle>
                    <form>
                      <DialogContent dividers>
                        <Grid item xs={12}>
                          {" "}
                          <Typography style={{ fontSize: 15 + "px" }}>
                            Client Id
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <input
                            className="form-control"
                            type="text"
                            name="clientId"
                            placeholder="Client Id"
                            onChange={this.onChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {" "}
                          <Typography style={{ fontSize: 15 + "px" }}>
                            Client Secret
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <input
                            className="form-control"
                            type="text"
                            name="clientSecret"
                            placeholder="Client Secret"
                            onChange={this.onChange}
                          />
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {this.state.plutioLoading ? (
                            <CircularProgress />
                          ) : (
                            <div>
                              <button
                                className="btn btn-primary  btn pull-right"
                                id="submit"
                                onClick={this.onPlutioSubmit}
                                style={{
                                  fontSize: 15 + "px",
                                  marginLeft: 10 + "px",
                                }}
                              >
                                Sign in
                              </button>
                              <button
                                className="btn btn-danger  btn pull-right"
                                onClick={this.dialogPlutioClose}
                                id="submit"
                                style={{
                                  fontSize: 15 + "px",
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          )}
                        </div>
                      </DialogActions>
                    </form>
                  </Dialog>
                </div>
              </ListItem>
            </List>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
