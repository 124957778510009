import React from "react";
import Skeleton from "@mui/material/Skeleton";

export class Sidebar extends React.Component {
  render() {
    return (
      <div>
        {/* <!-- Sidebar --> */}
        <nav id="sidebar">
          {/* <!-- Sidebar Scroll Container --> */}
          <div id="sidebar-scroll">
            {/* <!-- Sidebar Content --> */}
            <div className="sidebar-content">
              {/* <!-- Side Header --> */}
              <div
                className="side-header"
                style={{ backgroundColor: "#f5f4f4" }}
              >
                <p className="h5 text-white"></p>
                <img
                  alt="logo"
                  src="assets/img/favicons/year-glanceLogo.png"
                  style={{
                    width: 88 + "%",
                    marginLeft: 4 + "%",
                    marginBottom: 3 + "%",
                    marginTop: 1 + "%",
                  }}
                />
              </div>
              {/* <!-- END Side Header --> */}

              {/* <!-- Side Content --> */}
              <div className="side-content">
                <ul className="nav-main options">
                  <li className="nav-main-heading">
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      style={{ backgroundColor: "#445061" }}
                    />
                  </li>
                  <li className="nav-main-heading">
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      style={{ backgroundColor: "#445061" }}
                    />
                  </li>
                  <li className="nav-main-heading">
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      style={{ backgroundColor: "#445061" }}
                    />
                  </li>
                  <li className="nav-main-heading">
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      style={{ backgroundColor: "#445061" }}
                    />
                  </li>
                  <li className="nav-main-heading">
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      style={{ backgroundColor: "#445061" }}
                    />
                  </li>
                  <li className="nav-main-heading">
                    <Skeleton
                      animation="wave"
                      variant="rect"
                      style={{ backgroundColor: "#445061" }}
                    />
                  </li>
                </ul>
              </div>
              {/* <!-- END Side Content --> */}
            </div>
            {/* <!-- Sidebar Content --> */}
          </div>
          {/* <!-- END Sidebar Scroll Container --> */}
        </nav>
        {/* <!-- END Sidebar --> */}
      </div>
    );
  }
}
