import React from 'react';
import Skeleton from '@mui/material/Skeleton';

export class Header extends React.Component {

  render() {
    // send data variable
    return (
      <div>
        {/* <!-- Header --> */}
        <header id="header-navbar" className="content-mini content-mini-full">
          {/* <!-- Header Navigation Right --> */}
          <ul className="nav-header pull-right" >
            <li style={{ float: "right" }}>
              <Skeleton animation="wave" variant="circle" width={30} height={30} />
            </li>
          </ul>
          {/* <!-- END Header Navigation Right --> */}

          {/* <!-- Header Navigation Left --> */}
          <ul className="nav-header pull-left">
            <li className="hidden-md hidden-lg">
              {/* <LeftMenu /> */}
              <button className="btn btn-default" data-toggle="layout" data-action="sidebar_toggle" type="button">
                <i className="fa fa-navicon"></i>
              </button>
              {/* <!-- Layout API, functionality initialized in App() -> uiLayoutApi() --> */}
            </li>
            <li className="hidden-xs hidden-sm">
              <Skeleton animation="wave" variant="rect" height={5+"vh"} width={80+"vh"}/>
            </li>
          </ul>
          {/* <!-- END Header Navigation Left --> */}

        </header>
        {/* <!-- END Header --> */}

      </div >
    );

  }
}
