export const userConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",

  DELETE_REQUEST: "USERS_DELETE_REQUEST",
  DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
  DELETE_FAILURE: "USERS_DELETE_FAILURE",

  // forgotpassword, resetpassword
  FORGOTPASSWORD_REQUEST: "USERS_FORGOTPASSWORD_REQUEST",
  FORGOTPASSWORD_SUCCESS: "USERS_FORGOTPASSWORD_SUCCESS",
  FORGOTPASSWORD_FAILURE: "USERS_FORGOTPASSWORD_FAILURE",

  RESETPASSWORD_REQUEST: "USERS_RESETPASSWORD_REQUEST",
  RESETPASSWORD_SUCCESS: "USERS_RESETPASSWORD_SUCCESS",
  RESETPASSWORD_FAILURE: "USERS_RESETPASSWORD_FAILURE",

  REFRESHTOKEN_REQUEST: "USERS_REFRESHTOKEN_REQUEST",
  REFRESHTOKEN_SUCCESS: "USERS_REFRESHTOKEN_SUCCESS",
  REFRESHTOKEN_FAILURE: "USERS_REFRESHTOKEN_FAILURE",

  //broadcast
  BROADCAST_REQUEST: "USERS_BROADCAST_REQUEST",
  BROADCAST_SUCCESS: "USERS_BROADCAST_SUCCESS",
  BROADCAST_FAILURE: "USERS_BROADCAST_FAILURE",
};
