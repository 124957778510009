/*
 * changes made :
 * import change - from material-ui to mui
 * adjust the style changes in the mui component
 */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { paymentActions } from '../../_actions';
import CheckoutOptions from './CheckoutOptions';
import * as config from '../../constants/resource';
import { authHeader } from '../../_helpers';
import i18next from "i18next";
import { withStyles } from '@mui/styles';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import {
  IconButton,
  Dialog,
  Typography,
  LinearProgress,
  Grid
} from '@mui/material';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with your real test publishable API key.

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  texttitle: {
    fontSize: 20 + "px",
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} {...other}>
      <Grid container spacing={0}>
        <Grid item xs={11}>
          <Typography variant="h6">{children}</Typography>
        </Grid>
        <Grid item xs={1} sx={{ marginTop: '-13px' }}>
          {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

class UpgradePopUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      loading: false,
      publish_key:'',
      packageData: null,
      Upgrade: i18next.t('Pages.upgrade.Upgrade'),
    };

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);

  }

  handleClickOpen() {
    this.setState({ open: true });
    this.props.open2();
  }
  handleClose() {
    this.setState({ open: false });
    this.props.close();
  }

  UNSAFE_componentWillMount()
  {
    this.setState({loading:true});

    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };

    // for testing
    // return fetch(`${config.URL}/items/subscriptions?filter[price][gt]=0&filter[status][eq]=published`, requestOptions)
    //   .then(function (response) {
    //     return response.json();
    //   })
    //   .then(data => {
    //     this.setState({packageData:data});

    //     this.setState({
    //       loading:false,
    //       publish_key: 'pk_test_DLZeFvP0xGYByDste1JtHlvj00vBvZi5x6'
    //     });
    //   });

    return fetch(`${config.URL}/items/subscriptions?filter[price][gt]=0&filter[status][eq]=published`, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(data => {
        this.setState({packageData:data});

        fetch(`/plugin/get_stripe_key.php`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(res => {
          return res.json();
        })
        .then(res => {
          this.setState({
            loading:false,
            publish_key:res
          });
        });
      });
  }
  

  render() {

    const { classes } = this.props;
    const status = this.props.payment.message;

    return (
      <Dialog maxWidth="md" onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={status? status: false}>
        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
          <Typography className={classes.texttitle}>{this.state.Upgrade}</Typography>
        </DialogTitle>
        <DialogContent dividers>
          {!this.state.loading?
            <CheckoutOptions 
              packageData={this.state.packageData} 
              publish_key={this.state.publish_key}
            />
            :
            <LinearProgress />
          }
        </DialogContent>
      </Dialog>    
    )
  }
}

UpgradePopUp.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapState(state) {
  const { payment } = state;
  return { payment };
}

const actionCreators = {
  open2: paymentActions.openPayment,
  close: paymentActions.closePayment
};

const connectedCheckout = connect(mapState, actionCreators)(UpgradePopUp);
const UpgradePopUp2 = withStyles(styles)(connectedCheckout);

export default UpgradePopUp2;